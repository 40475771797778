<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema">

      <v-btn class="mt-5 mx-2" small color="success" @click="downloadTemplate()">Baixar Arquivos Modelos</v-btn>

      <div class="content-imports">
        <CardImportType v-for="(item, index) in types"
        :key="index"
        :item="item"
        @downloadTemplate="downloadTemplate"
        @openModal="openModal" />
      </div>
    </SimpleCard>

    <ModalUploadCSV 
      :dialog="dialog" 
      :loading-btn="loadingBtn" 
      :item="itemSelected"
      @actionImport="actionImport"
      @close="dialog = false"  />
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard';
import ImportSchema from '../schemas/ImportSchema';
import ImportService from '../services/ImportService';
import { ImportsCommands } from '../services/ImportService';
import CardImportType from '../components/CardImportType';
import ModalUploadCSV from '../components/ModalUploadCSV';
import { messageErrors } from '@/utils';

export default {
  name
    : 'Imports',
  components: {
    SimpleCard,
    CardImportType,
    ModalUploadCSV,
  },
  data () {
    return {
      schema: ImportSchema,
      service: ImportService,
      serviceCommands: ImportsCommands(),
      dialog: false,
      loadingBtn: false,
      itemSelected: {},
      types: [
        {
          domain: 'categories',
          label: 'Categorias',
        },
        {
          domain: 'sub-categories',
          label: 'Sub Categorias',
        },
        {
          domain: 'products',
          label: 'Produtos',
        },
        {
          domain: 'customers',
          label: 'Clientes',
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
    downloadTemplate() {
      this.serviceCommands.downloadModel().then((res) => {
        location.href = res.data.url_download;
      }).catch((err) => {
        this.$noty.error(messageErrors(err));
      });
    },
    openModal(item) {
      this.itemSelected = item;
      this.dialog = true;
    },
    actionImport(file) {
      this.loadingBtn = true;

      this.serviceCommands.uploadFileCSV({domain: this.itemSelected.domain, file_import: file}).then((res) => {
        this.dialog = false;
        this.loadingBtn = false;
        this.$noty.success(res.data.message);
      }).catch((err) => {
        this.dialog = false;
        this.loadingBtn = false;
        this.$noty.error(messageErrors(err));
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
