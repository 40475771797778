<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">

      <DynamicListPage
        no-initial-between
        :schema="schema"
        :service="service"
        @moreActions="moreActions">
      </DynamicListPage>
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import CreditLetterSchema from '../schemas/CreditLetterSchema';
import CreditLettersService from '../services/CreditLettersService';

export default {
  name: 'CreditLetters',
  components: {
    SimpleCard,
    DynamicListPage
  },
  data() {
    return {
      schema: CreditLetterSchema,
      service: CreditLettersService,
      itemsEquals: []
    }
  },
  methods: {
    moreActions() {

    }
  }
}
</script>

<style>

</style>