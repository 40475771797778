<template>
  <div>
    <SimpleCard
      no-back
      :schema="schema"
      :route-create="schema.routes.create.name">

      <DynamicListPage
        :schema="schema"
        :service="service" />
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import UserSchema from '../schemas/UserSchema';
import UserService from '../services/UserService';

export default {
  name: 'Users',
  components: {
    SimpleCard,
    DynamicListPage,
  },
  data () {
    return {
      schema: UserSchema,
      service: UserService,
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
