<template>
  <div>
    <SimpleCard 
      :title="schema.title" 
      :schema="schema">

      <DynamicDetailsPage
        :schema="schema"
        :service="service"
        :type-page="typePage"
        @save="save" />

    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicDetailsPage from '@/components/DynamicDetailsPage'
import PaymentLinkSchema from '../schemas/PaymentLinkSchema';
import PaymentLinkService from '../services/PaymentLinkService';

export default {
  name: 'PaymentLinkForm',
  components: {
    SimpleCard,
    DynamicDetailsPage,
  },
  data () {
    return {
      schema: PaymentLinkSchema,
      service: PaymentLinkService,
      typePage: this.$route.name === 'paymentlink-create' ? 'create' : 'update',
    }
  },
  mounted() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    save() {
      this.$noty.success('Registro salvo com sucesso!');
      this.$router.push({name: this.schema.routes.list.name});
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
