import MovementHistoriesBusiness from '@/business/MovementHistories';

const money_options = {
  locale: "pt-BR",
  prefix: "R$",
  suffix: "",
  length: 11,
  precision: 2
}

const money_options_tax = {
  locale: "pt-BR",
  prefix: "",
  suffix: "",
  length: 11,
  precision: 2
}

const dataResume = false;

export default {
  domain: 'movement_histories',
  business: MovementHistoriesBusiness,
  title: 'Histórico de Movimentações',
  description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
  singleSelect: true,
  disablePagination: true,
  noFooter: true,
  customSearch: false,
  noSearch: false,
  routes: {
    list: {
      name: 'movementhistories',
      path: '/historico-movimentacoes',
    },
    create: {
      name: 'movementhistory-create',
      path: '/historico-movimentacoes/novo',
    },
    show: {
      name: 'movementhistory-show',
      path: '/historico-movimentacoes/:id',
    },
  },
  create: true,
  show: true,
  destroy: true,
  destroyDisabled: false,
  noBetween: false,
  noEquals: false,
  searchCol: 3,
  betweenCol: 2,
  filtersParams: {
    ordering: {
      column: "movement_date",
      value: "asc"
    },
    between: { 
      type: 'between',
      column: 'movement_date' 
    },
    equals: { 
      type: 'equals',
      column: 'bank_id' 
    }
  },
  preferencesParams: [
    {
      name: 'dataResume',
      type: 'switch',
      model: dataResume,
      label: 'Agrupar Dados'
    }
  ],
  fields: [
    {
      type: 'date',
      id: 'movement_date',
      name: 'movement_date',
      formattedName: 'movement_date_formatted',
      label: 'Data',
      align: '',
      md: '2',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      id: 'bank_id',
      type: 'select',
      name: 'bank_id',
      formattedName: 'bank.description',
      label: 'Banco Padrão',
      item_id: 'id',
      item_text: 'description',
      align: '',
      md: '4',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'origin_type',
      formattedName: 'origin_type_formatted',
      label: 'Origem',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
      noForm: true,
    },
    {
      type: 'money',
      name: 'value',
      formattedName: 'value_formatted',
      label: 'Valor',
      align: '',
      md: '2',
      money_options,
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'money',
      name: 'tax',
      formattedName: 'tax_formatted',
      label: 'Taxa',
      align: '',
      md: '2',
      money_options_tax,
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'money',
      name: 'value_tax',
      formattedName: 'value_tax_formatted',
      label: 'Valor Taxa',
      align: '',
      md: '2',
      money_options,
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'money',
      name: 'value_final',
      formattedName: 'value_final_formatted',
      label: 'Valor Final',
      align: '',
      md: '2',
      money_options,
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'movement_history_status',
      formattedName: 'movement_history_status_formatted',
      label: 'Status',
      align: '',
      md: '2',
      required: true,
      noFilter: false,
      list: true,
      noForm: true,
    },
  ],
  footer: [
    {
      name: 'previous_balance_inputs_total',
      label: 'Saldo Anterior Entradas',
      class: 'custom-footer-value',
      md: 2,
    },
    {
      name: 'previous_balance_outputs_total',
      label: 'Saldo Anterior Saídas',
      class: 'custom-footer-value',
      md: 2,
    },
    {
      name: 'balance_inputs_period',
      label: 'Entradas no Período',
      class: 'custom-footer-value',
      md: 2,
    },
    {
      name: 'balance_outputs_period',
      label: 'Saídas no Período',
      class: 'custom-footer-value',
      md: 2,
    },
    // {
    //   name: 'value_total',
    //   label: 'Total Geral',
    //   class: 'custom-footer-value',
    //   md: 2,
    // },
    // {
    //   name: 'value_tax',
    //   label: 'Total Taxas',
    //   class: 'custom-footer-value',
    //   md: 2,
    // },
    // {
    //   name: 'value_final',
    //   label: 'Total Final',
    //   class: 'custom-footer-value',
    //   md: 2,
    // },
  ]
}