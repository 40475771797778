import Login from './Login';
import Register from './Register';

const baseURL = '';

export default [
  {
    path: `${baseURL}/login`,
    name: 'login',
    component: Login,
    meta:{
      title: 'Login',
      description: '',
    }
  },
  {
    path: `${baseURL}/cadastro`,
    name: 'register',
    component: Register,
    meta:{
      title: 'Cadastro',
      description: '',
    }
  },
]