import { formatCurrency } from '@/utils';

export default {
  beforeList: (formValue) => {
    let total_value = 0;
    formValue.data.forEach((item) => {
      total_value += parseFloat(item.amount);
    });
    formValue.total_value = formatCurrency(total_value);
  },
  afterShow: ({ formValue }) => {
    formValue.readOnlyGlobal = true;
  },
  beforeSave: (formValue) => {
    delete formValue.customerNameItems;
    delete formValue.customerLoading;
    delete formValue.customerSearch;

    formValue.register_id = formValue.customer_name.id;
  }
}