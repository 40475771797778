import { copy, refresh, dataRegisters, download, printer } from '@/constants/icons';
import Billets from '@/business/Billets';
import RegisterService from '@/pages/Registers/services/RegisterService';

const money_options = {
  locale: "pt-BR",
  prefix: "R$",
  suffix: "",
  length: 11,
  precision: 2
}

export default {
  domain: 'billets',
  business: Billets,
  title: 'Boletos',
  description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
  singleSelect: true,
  customSearch: true,
  routes: {
    list: {
      name: 'billets',
      path: '/boletos',
    },
    create: {
      name: 'billet-create',
      path: '/boletos/novo',
    },
    show: {
      name: 'billet-show',
      path: '/boletos/:id',
    }
  },
  show: true,
  destroy: true,
  create: true,
  noBetween: true,
  noEquals: true,
  searchCol: 4,
  moreActions: [
    {
      name: 'printBillet',
      label: 'Imprimir Boleto',
      icon: printer,
      action: 'printBillet',
    },
    {
      name: 'refreshStatus',
      label: 'Atualizar Situação',
      icon: refresh,
      action: 'refreshStatus',
    },
    {
      name: 'offsetBillet',
      label: 'Baixa no boleto',
      icon: download,
      action: 'offsetBillet',
    },
    {
      name: 'cloneBillet',
      label: 'Clonar no boleto',
      icon: copy,
      action: 'cloneBillet',
    },
  ],
  fields: [
    {
      icon: dataRegisters,
      title: 'Dados do Boleto',
      items: [
        {
          id: 'created_at',
          type: 'text',
          name: 'created_at',
          label: 'Criação',
          align: '',
          md: '4',
          list: true,
          noForm: true
        },
        {
          id: 'customer_name',
          type: 'autocomplete',
          name: 'customer_name',
          items: 'customerNameItems',
          loading: 'customerLoading',
          search: 'customerSearch',
          domain: 'registers',
          ordering: {
            "column": "name",
            "value": "asc"
          },
          q: [{ 
            type: 'equals',
            column: 'type_register',
            value: 'customer'
          }],
          label: 'Cliente',
          service: RegisterService,
          align: '',
          md: '4',
          list: true,
        },
        {
          id: 'description',
          type: 'text',
          name: 'description',
          label: 'Descrição',
          align: '',
          md: '4',
          list: true,
        },
        {
          id: 'amount',
          type: 'money',
          name: 'amount',
          formattedName: 'amount_formatted',
          label: 'Valor',
          money_options,
          md: '2',
          required: true,
          list: true,
        },
        {
          id: 'billet_barcode',
          type: 'text',
          name: 'billet_barcode',
          label: 'Cód. Barras',
          md: '2',
          required: true,
          list: true,
          noForm: true
        },
        {
          id: 'billet_expiration_date',
          type: 'date',
          name: 'billet_expiration_date',
          formattedName: 'billet_expiration_date_formatted',
          label: 'Vencimento',
          md: '2',
          list: true,
        },
        {
          id: 'status',
          type: 'text',
          name: 'status',
          formattedName: 'status_formatted',
          label: 'Situação',
          md: '2',
          required: true,
          list: true,
          noForm: true
        },
        {
          id: 'observations',
          type: 'textarea',
          name: 'observations',
          label: 'Observações',
          md: '12',
          required: true,
          list: false,
        },
      ]
    }
  ],
  footer: [
    {
      name: 'total_value',
      label: 'Valor Total',
      class: 'custom-footer-value',
      md: 2,
    }
  ]
}