import axios from 'axios';
import DynamicService from '@/service/DynamicService';
import { formatCurrency } from '@/utils';

const formatResponse = (item) => {
  
  item.reference_formatted = item.reference || '-';
  item.ncm_formatted = item.ncm || '-';
  item.cest_formatted = item.cest || '-';
  item.code_formatted = '-';
  item.bar_code_formatted = '-';
  item.price_var_formatted = formatCurrency(item.variations[0]?.price_var || 0);
  item.price_var_profit_formatted = formatCurrency(item.price_var_profit || 0);
  item.price_ata_formatted = formatCurrency(item.variations[0]?.price_ata || 0);
  item.price_ata_profit_formatted = formatCurrency(item.price_ata_profit || 0);
  item.price_cost_formatted = formatCurrency(item.variations[0]?.price_cost || 0);

  if(item.categories instanceof Object == false) {
    item.categories = {
      description: item.categories || '-'
    }
  }

  if(item.subcategories instanceof Object == false) {
    item.subcategories = {
      description: item.subcategories || '-'
    }
  }

  if(item.stocks){
    item.stocks.forEach((i) => {
      i.qtd_formatted = formatCurrency(i.qtd, {type: 'decimal', precision: 4});
    });
  }

  if(item.product_variation_type === 'single') {
    item.code_formatted = item.variations[0].code || '-';
    item.bar_code_formatted = item.variations[0].bar_code || '-';
  }

  if(item.variations.length > 0) {
    const arr_variations = [];
    item.variations.forEach((v) => {
      let variation = '';
      let stockFormatted = '';
      let qtd = 0;
      
      v.variation_values.forEach((v_value) => {
        variation += `${v_value.variation.description}: ${v_value.variation_value.description} | `;
      });

      variation = variation.trim().slice(0, -2);

      v.variation_stocks.forEach((v_stock) => {
        stockFormatted += `${v_stock.stock.description}: ${v_stock.qtd} | `;
        qtd += parseFloat(v_stock.qtd);
        v_stock.qtd_formatted = formatCurrency(v_stock.qtd, {type: 'decimal', precision: 3});
      });
      
      stockFormatted = stockFormatted.trim().slice(0, -2);
      arr_variations.push({
        id: v.id,
        product_id: v.product_id,
        code: v.code,
        description: variation,
        stockFormatted: stockFormatted,
        stocks: v.variation_stocks,
        qtd: qtd,
        qtd_formatted: formatCurrency(qtd || 0, {type: 'decimal', precision: 3}),
        sku: v.sku,
        bar_code: v.bar_code,
        price_cost: v.price_cost,
        price_cost_formatted: formatCurrency(v.price_cost || 0),
        price_var: v.price_var,
        price_var_formatted: formatCurrency(v.price_var || 0),
        price_var_profit: v.price_var_profit,
        price_var_profit_formatted: formatCurrency(v.price_var_profit || 0),
        price_ata: v.price_ata,
        price_ata_formatted: formatCurrency(v.price_ata || 0),
        price_ata_profit: v.price_ata_profit,
        price_ata_profit_formatted: formatCurrency(v.price_ata_profit || 0),
        product: {
          name: v.product.name
        },
        variation_stocks: v.variation_stocks,
        variation_values: v.variation_values
      });
    });
    item.variations_formatted = arr_variations;
  }
}

const formatRequest = (item) => {
  delete item ['categories'];
  delete item ['subcategories'];
  delete item ['stocks'];
  delete item ['stock_history'];
  delete item ['variations'];
  delete item ['variations_formatted'];
}

const ProductService = DynamicService('products', {
  formatResponse,
  formatRequest,
});

const ProductsCommands = () => ({
  async getProductStocksByProductId(product_variation_id) {
    return new Promise((resolve, reject) => {
      const payload = {
        "domain": "product_variation_stocks",
        "search": "",
        "ordering": {
          "column": "created_at",
          "value": "desc"
        },
        "q": [
          {
            "column": "product_variation_stocks.product_variation_id",
            "type": "equals",
            "value": product_variation_id
          }
        ]
      }
      axios.post(`filters`, payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async storeProductStock(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`product-stocks`, payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async getVariations() {
    return new Promise((resolve, reject) => {
      axios.get(`variations`).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async showVariations(id) {
    return new Promise((resolve, reject) => {
      axios.get(`variations/${id}`).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async storeVariationValues(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`variation-values`, payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async destroyVariationValues(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`variation-values/${id}`).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async updateVariationValues(id, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`variation-values/${id}`, payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async storeProductVariation(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`product-variations`, payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async deleteProductVariation(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`product-variations/${id}`).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async editProductVariation(id, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`product-variations/${id}`, payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async storeProductVariationStock(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`product-variation-stocks`, payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async storeProductVariationStockWithBarCode(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`product-variation-stocks-barcode`, payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async getProductVariationByBarCode(barCode) {
    return new Promise((resolve, reject) => {
      const payload = {
        domain: 'product_variations',
        search: '',
        ordering: {column: 'id', value: 'asc'},
        q: [{
          type: "equals",
          column: "bar_code",
          value: barCode,
        }]
      };

      axios.post(`filters`, payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
});

export default ProductService;
export { ProductService, ProductsCommands };