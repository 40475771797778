<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    class="dialog-custom"
  >
  <v-card class="">
    <v-card-title>
    </v-card-title>
    <v-card-text>
      <h3>Sua Nota Fiscal está sendo transmitida. Aguarde...</h3>
    </v-card-text>
  </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'DialogSendNFe',
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      loading: true,
      selected: [],
    }
  },
  watch: {
    initItemEqual() {
      console.log(this.initItemEqual)
    }
  },
  mounted() {
  },
}
</script>
<style scoped>
</style>