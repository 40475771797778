<template>
  <div>
    <SimpleCard 
      :title="schema.title" 
      :schema="schema">

      <DynamicDetailsPage
        :schema="schema"
        :service="service"
        :type-page="typePage"
        :items-selects="{ default: yes_no }"
        @save="save" />
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicDetailsPage from '@/components/DynamicDetailsPage'
import BankSchema from '../schemas/BankSchema';
import BankService from '../services/BankService';
import AllTypeService from '@/pages/Registers/services/AllTypeService';
import { yes_no } from '@/constants/options';

export default {
  name: 'ServiceForm',
  components: {
    SimpleCard,
    DynamicDetailsPage,
  },
  data () {
    return {
      schema: BankSchema,
      service: BankService,
      allTypeService: AllTypeService,
      typePage: this.$route.name === 'bank-create' ? 'create' : 'update',
      yes_no: yes_no,
    }
  },
  mounted() {
    this.getCategories();
    this.getSubCategories();
  },
  methods: {
    getCategories() {
      const { payload, paginate } = this.mountParamsFilter('categories');
      this.allTypeService.filters(payload, paginate).then((res) => {
        this.categories = res.data.data;
      }).catch((err) => {
        console.log(err)
      });
    },
    getSubCategories() {
      const { payload, paginate } = this.mountParamsFilter('subcategories');
      this.allTypeService.filters(payload, paginate).then((res) => {
        this.subcategories = res.data.data;
      }).catch((err) => {
        console.log(err)
      });
    },
    mountParamsFilter(value) {
      const payload = {
        domain: 'all_types',
        search: '',
        ordering: {
          column: "description",
          value: "asc"
        },
        q: [{ 
          type: 'equals',
          column: 'type',
          value,
        }]
      }
      const paginate = 'page=1&per_page=-1';

      return {
        payload,
        paginate,
      }
    },
    save() {
      this.$noty.success('Registro salvo com sucesso!');
      this.$router.push({name: this.schema.routes.list.name});
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
