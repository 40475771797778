import BoxesBusiness from '@/business/Boxes';
import { origin } from '@/constants/icons'

export default {
  domain: 'boxes',
  business: BoxesBusiness,
  title: 'Caixa',
  description: 'Relatório de entradas e saídas financeira do caixa.',
  singleSelect: true,
  disablePagination: true,
  noFooter: true,
  customSearch: false,
  noSearch: true,
  routes: {
    list: {
      name: 'box',
      path: '/caixa',
    },
    create: {},
    show: {},
  },
  create: false,
  show: false,
  destroy: false,
  betweenCol: 2,
  filtersParams: {
    ordering: {
      column: "registration_date",
      value: "desc"
    },
    between: { 
      type: 'between',
      column: 'registration_date' 
    },
    equals: { 
      type: 'equals',
      column: 'payment_method_id'
    }
  },
  moreActions: [
    {
      name: 'origin',
      label: 'Origem',
      icon: origin,
      action: 'origin'
    }
  ],
  fields: [
    {
      type: 'text',
      name: 'registration_date',
      formattedName: 'registration_date_formatted',
      label: 'Data',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'origin_type',
      formattedName: 'origin_type_formatted',
      label: 'Origem',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'origin.customer.id',
      formattedName: 'origin.customer.name',
      label: 'Cliente',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'payment_method.description',
      label: 'Forma de Pagamento',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'value',
      formattedName: 'value_formatted',
      label: 'Valor Total',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
  ],
  footer: [
    {
      name: 'value_total',
      label: 'Total Geral',
      class: 'custom-footer-value',
      md: 2,
    },
    {
      name: 'value_input',
      label: 'Entradas',
      class: 'custom-footer-value',
      md: 2,
    },
    {
      name: 'value_output',
      label: 'Saídas',
      class: 'custom-footer-value',
      md: 2,
    }
  ]
}