<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1200"
      class="dialog-custom"
    >
      <v-card class="">
        <v-card-title>
          <h3 small>{{ productSelected.name }}</h3>
        </v-card-title>

        <v-card-text>
          <v-divider></v-divider>
          <h3 small class="my-2">Variações</h3>
          <TextField label="Pesquisar" v-model="search" />

          <v-data-table
            item-key="id"
            :id="'id'"
            :headers="headers"
            :items="items"
            :search="search"
            :loading="loadingVariations"
            :items-per-page="parseInt(7)"
            class="elevation-1">

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu :nudge-width="200" bottom left offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ icons.dotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-list v-for="(i, index) in itemsMoreActions" :key="index" dense>
                    <v-list-item link @click="moreActions(item, i)">
                      <v-list-item-icon>
                        <v-icon class="icon-side-bar">{{ i.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="text-side-bar">{{ i.label }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
              
              <v-btn icon class="my-1" fab color="primary" x-small @click="openDialogEdit(item)">
                <v-icon dark>{{ icons.edit }}</v-icon>
              </v-btn>
              
              <v-btn icon class="my-1" fab color="red" x-small @click="openDialogDelete(item)">
                <v-icon dark>{{ icons.destroy }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <v-row class="mt-5">
            <v-col>
              <h3>Quant. Stock: {{ stockTotal }}</h3>
            </v-col>

            <v-col>
              <h3>Valor Varejo Total: {{ priceVarTotal }}</h3>
            </v-col>

            <v-col>
              <h3>Valor Atacado Total: {{ priceAtaTotal }}</h3>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small dark color="success" @click="dialogAddVariations = true">Nova Variação</v-btn>
          <v-btn small dark color="red" @click="$emit('closeDialog', 'dialogViewVariations')">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DialogAddVariations
      :dialog="dialogAddVariations"
      :product-selected="productSelected"
      @closeDialog="closeDialog" />

    <DialogViewStock
      :dialog="dialogViewStock"
      :items="stocks"
      @closeDialog="dialogViewStock = false" />

    <DialogAddStock
      :dialog="dialogAddStock"
      :productName="productNameSelected"
      :loading="loadingAddStock"
      @saveStock="saveStock"
      @closeDialog="dialogAddStock = false" />

    <DialogEditVariations
      :dialog="dialogEditVariation"
      :item="productVariationSelected"
      @closeDialog="dialogEditVariation = false"
      @editProductVariation="editProductVariation" />

    <DialogDestroy :dialog="dialogDelete" @noAction="dialogDelete = false" @yesAction="remove" />
  </div>
</template>

<script>
import { formatCurrency, messageErrors } from '@/utils';
import { destroy, edit, dotsVertical, databaseSearch, view } from '@/constants/icons';
import ProductService from '../services/ProductService';
import { ProductsCommands } from '../services/ProductService';
import DialogDestroy from '@/components/DialogDestroy';
import DialogEditVariations from '../components/DialogEditVariations';
import DialogViewStock from '../components/DialogViewStock';
import DialogAddStock from '../components/DialogAddStock';
import DialogAddVariations from '../components/DialogAddVariations';
import TextField from '@/components/vuetify/TextField';

export default {
  name: 'DialogViewVariations',
  components: {
    DialogDestroy,
    DialogEditVariations,
    DialogViewStock,
    DialogAddStock,
    DialogAddVariations,
    TextField,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    productId: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      productService: ProductService,
      productsCommands: ProductsCommands(),
      loadingVariations: false,
      dialogDelete: false,
      stockTotal: 0,
      priceVarTotal: 0,
      priceAtaTotal: 0,
      icons: {
        destroy: destroy,
        edit: edit,
        dotsVertical: dotsVertical,
        databaseSearch: databaseSearch,
        view: view,
      },
      search: '',
      headers: [
        {text: 'Código', value: 'code'},
        {text: 'Descrição', value: 'description'},
        {text: 'Preço Varejo', value: 'price_var_formatted'},
        {text: 'Preço Atacado', value: 'price_ata_formatted'},
        {text: 'Sku', value: 'sku'},
        {text: 'Cod. Barras', value: 'bar_code'},
        {text: 'Quant.', value: 'qtd_formatted'},
        {text: 'Excluir', value: 'actions'},
      ],
      productSelected: {},
      productVariationSelected: {},
      dialogAddVariations: false,
      dialogEditVariation: false,
      itemsMoreActions: [
        {
          action: 'viewStock',
          icon: view,
          label: 'Ver Estoques'
        },
        {
          action: 'addStock',
          icon: databaseSearch,
          label: 'Adicionar/Remover Estoque'
        },
      ],
      dialogViewStock: false,
      dialogAddStock: false,
      loadingAddStock: false,
      stocks: [],
      productNameSelected: '',
      items: [],
    }
  },
  watch: {
    productId() {
      this.getProduct();
    },
  },
  methods: {
    getProduct() {
      this.loadingVariations = true;
      this.productService.show(this.productId).then((res) => {
        this.productSelected = res;
        this.items = res.variations_formatted;
        this.counterTotalizers();
        this.loadingVariations = false
      }).catch((err) => {
        console.error(err)
        this.$noty.error('Erro ao carregar as variações');
        this.loadingVariations = false
      });
    },
    openDialogEdit(item) {
      this.dialogEditVariation = true;
      this.productVariationSelected = item;
    },
    openDialogDelete(item) {
      this.dialogDelete = true;
      this.productVariationSelected = item;
    },
    closeDialog(dialoag) {
      this[dialoag] = false;
      this.getProduct();
    },
    remove() {
      this.productsCommands.deleteProductVariation(this.productVariationSelected.id).then(() => {
        this.$noty.success('Operação Realizada com Sucesso!');
        this.dialogDelete = false;
        this.productVariationSelected = {};
      }).catch((err) => {
        this.dialogDelete = false;
        this.productVariationSelected = {};
        this.$noty.error(messageErrors(err));
      });
    },
    editProductVariation(data) {
      const {form, item} = data;
      
      this.productsCommands.editProductVariation(this.productVariationSelected.id, form).then((res) => {
        item.code = res.data.code;
        item.price_cost = res.data.price_cost;
        item.price_var = res.data.price_var;
        item.price_ata = res.data.price_ata;
        item.price_cost_formatted = formatCurrency(res.data.price_cost || 0);
        item.price_var_formatted = formatCurrency(res.data.price_var || 0);
        item.price_ata_formatted = formatCurrency(res.data.price_ata || 0);
        item.bar_code = res.data.bar_code;
        item.sku = res.data.sku;

        this.$noty.success('Operação Realizada com Sucesso!');
        this.dialogEditVariation = false;
        this.productVariationSelected = {};
      }).catch((err) => {
        this.$noty.error(messageErrors(err));
      });
    },
    moreActions(item, i) {
      this.productVariationSelected = item;
      this.productNameSelected = `${this.productVariationSelected?.product?.name} | ${this.productVariationSelected?.description}`
      this[i.action](item);
    },
    viewStock(item) {
      this.stocks = item.stocks
      this.dialogViewStock = true;
    },
    addStock() {
      this.dialogAddStock = true;
    },
    saveStock(form) {
      form.product_variation_id = this.productVariationSelected.id;
      this.productsCommands.storeProductVariationStock(form).then(() => {
        this.getProduct()
        this.dialogAddStock = false;
        this.$noty.success('Operação Realizada com Sucesso!');
      }).catch((err) => {
        this.dialogAddStock = false;
        this.$noty.error(messageErrors(err));
      });
    },
    counterTotalizers() {
      this.stockTotal = 0;
      this.items?.forEach((item) => {
        this.stockTotal += parseFloat(item.qtd);
        this.priceVarTotal += parseFloat(item.price_var);
        this.priceAtaTotal += parseFloat(item.price_ata);
      });

      this.stockTotal = formatCurrency(this.stockTotal, {type: 'decimal', precision: 3});
      this.priceVarTotal = formatCurrency(this.priceVarTotal);
      this.priceAtaTotal = formatCurrency(this.priceAtaTotal);
    }
  }
}
</script>