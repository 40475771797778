import AccountPayments from '@/business/AccountPayments';

const money_options = {
  locale: "pt-BR",
  prefix: "R$",
  suffix: "",
  length: 11,
  precision: 2
}

export default {
  domain: 'account_payments',
  business: AccountPayments,
  title: 'Contas a Pagar',
  description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
  singleSelect: true,
  customSearch: true,
  routes: {
    list: {
      name: 'accountpayments',
      path: '/contas-pagar',
    },
    create: {
      name: 'accountpayment-create',
      path: '/contas-pagar/novo',
      children: []
    },
    show: {
      name: 'accountpayment-show',
      path: '/contas-pagar/:id',
    },
  },
  show: true,
  destroy: true,
  create: true,
  noBetween: false,
  noEquals: false,
  searchCol: 3,
  betweenCol: 2,
  equalsCol: 2,
  filtersParams: {
    ordering: {
      column: "registration_date",
      value: "asc",
    },
    between: { 
      type: 'between',
      column: 'registration_date',
    },
    equals: { 
      type: 'equals',
    },
  },
  fields: [
    {
      id: 'id',
      type: 'select',
      name: 'account_payment_type_id',
      formattedName: 'account_payment_type.description',
      label: 'Tipo',
      item_id: 'id',
      item_text: 'description',
      align: '',
      md: '4',
      list: true,
    },
    {
      id: 'id',
      type: 'select',
      name: 'payment_method_id',
      label: 'Forma de Pagamento',
      item_id: 'id',
      item_text: 'description',
      align: '',
      md: '4',
    },
    {
      id: 'id',
      type: 'select',
      name: 'bank_id',
      label: 'Banco',
      item_id: 'id',
      item_text: 'description',
      align: '',
      md: '4',
    },
    {
      id: 'id',
      type: 'text',
      name: 'description',
      label: 'Descrição',
      md: '4',
      required: true,
      list: true,
    },
    {
      id: 'id',
      type: 'date',
      name: 'registration_date',
      formattedName: 'registration_date_formatted',
      label: 'Data dp Registro',
      md: '2',
      list: true,
    },
    {
      id: 'id',
      type: 'money',
      name: 'value',
      formattedName: 'value_formatted',
      label: 'Valor',
      money_options,
      md: '2',
      required: true,
      list: true,
    },
    {
      id: 'id',
      type: 'select',
      name: 'status',
      formattedName: 'type_account_payment_status_formatted',
      label: 'Status',
      align: '',
      md: '2',
      list: true,
    },
    {
      id: 'id',
      type: 'textarea',
      name: 'observation',
      label: 'Observações',
      md: '12',
      list: true,
    },
  ],
  footer: [
    {
      name: 'value_total',
      label: 'Total Geral',
      class: 'custom-footer-value',
      md: 2,
    },
    {
      name: 'value_pending',
      label: 'Total Pedente',
      class: 'custom-footer-value',
      md: 2,
    },
    {
      name: 'value_paid',
      label: 'Total Pago',
      class: 'custom-footer-value',
      md: 2,
    },
  ]
}