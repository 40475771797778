<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">
      
      <DynamicListPage
        ref="dynamicListPage"
        :schema="schema"
        :service="service"
        @moreActions="moreActions">

      </DynamicListPage>
    </SimpleCard>

    <DialogViewItemVariation
      :variation-selected="variationSelected"
      :dialog="dialogItemVariation"
      @update:dialogItemVariation="dialogItemVariation = $event" />
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import VariationSchema from '../schemas/VariationSchema';
import VariationService from '../services/VariationService';
import DialogViewItemVariation from './components/DialogViewItemVariation'

export default {
  name: 'Variations',
  components: {
    SimpleCard,
    DynamicListPage,
    DialogViewItemVariation,
  },
  data () {
    return {
      schema: VariationSchema,
      service: VariationService,
      dialogItemVariation: false,
      variationSelected: {}
    }
  },
  mounted() {
  },
  methods: {
    moreActions(value) {
      this[value.action](value.item);
    },
    viewItemsVariations(item) {
      this.variationSelected = item;
      this.dialogItemVariation = true;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss';
</style>
