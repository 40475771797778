import { formatCurrency, limitCharacters } from '@/utils';
import { sale_order_status } from '@/constants/options';

export default {
  beforeList: (formValue) => {
    let total_paid = 0;
    let total_pending = 0;
    let total_value = 0;
    let total_discount = 0;
    let total_final = 0;

    formValue.data.forEach((item) => {
      total_value += parseFloat(item.value_total);
      total_final += parseFloat(item.value_final);
      total_discount += parseFloat(item.value_discount);

      if(item.payments.length) {
        let value_paid_item = 0;

        // value paid global
        item.payments.forEach((payment) => {
          total_paid += parseFloat(payment.value_final);
          value_paid_item += parseFloat(payment.value_final);
        });

        // value paid and pending for item
        item.value_paid = value_paid_item;
        item.value_paid_formatted = formatCurrency(value_paid_item);
      }

      item.value_pending = parseFloat(item.value_final) - parseFloat(item.value_paid);
      item.value_pending_formatted = formatCurrency(item.value_pending);

      if(item.sale_order_status === 'finished'){
        item.disableDelete = true ;
      }

      if(item.sale_order_status != sale_order_status[1].value){
        item.disableItemMoreActions = {
          makeNFe: false,
        }
      }

      if(item.customer) {
        item.customer.name = limitCharacters(item.customer.name, 20);
      }
    });
    total_pending = total_final - total_paid;

    formValue.total_paid = formatCurrency(total_paid);
    formValue.total_pending = formatCurrency(total_pending);
    formValue.total_value = formatCurrency(total_value);
    formValue.total_discount = formatCurrency(total_discount);
    formValue.total_final = formatCurrency(total_final);
  }
}