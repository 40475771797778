<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">

      <DynamicListPage
        :schema="schema"
        :service="service"
        :items-equals="itemsEquals">
      </DynamicListPage>
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import RegisterSchema from '../schemas/RegisterSchema';
import RegisterService from '../services/RegisterService';
import { type_register } from '@/constants/options'
import ParamsEquals from '@/mixins/ParamsEquals'

export default {
  name: 'Registers',
  components: {
    SimpleCard,
    DynamicListPage,
  },
  data () {
    return {
      schema: RegisterSchema,
      service: RegisterService,
      filterSelected: 0,
      filtersStatus: type_register,
      items: {},
      page: 1,
      pageLength: 1,
      totalItemsPagination: 0,
      totalItemsPerPage: 30,
      typeRegister: type_register,
      itemsEquals: [],
    }
  },
  mounted() {
    this.mountItemsEquals();
  },
  mixins: [ParamsEquals],
  methods: {
    mountItemsEquals() {
      this.itemsEquals.push({
        name: 'type_register',
        label: 'Tipo do Registro',
        items: this.paramsEquals(this.typeRegister, 'text', 'value'),
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
