import Banks from '@/business/Banks';

const money_options = {
  locale: "pt-BR",
  prefix: "R$",
  suffix: "",
  length: 11,
  precision: 2
}

export default {
  domain: 'banks',
  business: Banks,
  title: 'Bancos',
  description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
  singleSelect: true,
  customSearch: true,
  routes: {
    list: {
      name: 'banks',
      path: '/bancos',
    },
    create: {
      name: 'bank-create',
      path: '/bancos/novo',
    },
    show: {
      name: 'bank-show',
      path: '/bancos/:id',
    }
  },
  show: true,
  destroy: true,
  create: true,
  noBetween: true,
  noEquals: true,
  searchCol: 4,
  fields: [
    {
      id: 'description',
      type: 'text',
      name: 'description',
      label: 'Descrição',
      align: '',
      md: '4',
      list: true,
    },
    {
      id: 'bank_number',
      type: 'text',
      name: 'bank_number',
      label: 'Nº Banco',
      align: '',
      md: '2',
      list: true,
    },
    {
      id: 'agency_number',
      type: 'text',
      name: 'agency_number',
      label: 'Nº Agência',
      align: '',
      md: '2',
      list: true,
    },
    {
      id: 'account_number',
      type: 'text',
      name: 'account_number',
      label: 'Nº Conta',
      md: '2',
      required: true,
      list: true,
    },
    {
      id: 'balance',
      type: 'money',
      name: 'balance',
      formattedName: 'balance_formatted',
      label: 'Saldo',
      money_options,
      md: '2',
      required: true,
      list: true,
    },
    {
      id: 'opening_date',
      type: 'date',
      name: 'opening_date',
      formattedName: 'opening_date_formatted',
      label: 'Abertura',
      md: '3',
      list: true,
    },
  ],
  footer: [
    {
      name: 'balance_total',
      label: 'Saldo Total',
      class: 'custom-footer-value',
      md: 2,
    }
  ]
}