/* eslint-disable */
import DynamicService from '@/service/DynamicService';

const AutoComplete = {
  methods: {
    debounceInput: _.debounce(function (data) {
      this.getAutoCompleteSearch(data);
    }, 700),
    autoCompleteKeyUp(data) {
      this[data.item].loading = true;
      this.debounceInput(data);
    },
    getAutoCompleteSearch(params) {
      let val = this[params.item].search
      let paginate = `page=1&per_page=-1`;
      let payload = {
        domain: params.domain,
        search: val,
        ordering: {
          column: "name",
          value: "asc"
        },
        q: [{
          type: "equals",
          column: "type_register",
          value: params.type
        }],
      }

      DynamicService().filters(payload, paginate).then((res) => {
        const { total, data } = res.data;
          let items = data;
          let autoItems = [];

          items.forEach((item) => {
            autoItems.push({id: item.id, text: item.name})
          });

          this[params.item].items = autoItems
          this[params.item].loading = false;
      }).catch(() => {
        this[params.item].loading = false;
      });
    },
  },
};

export default AutoComplete;
