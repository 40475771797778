<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">

      <DynamicListPage
        no-initial-between
        :schema="schema"
        :service="service"
        @moreActions="moreActions">
      </DynamicListPage>
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import DevolutionSchema from '../schemas/DevolutionSchema';
import DevolutionsService from '../services/DevolutionsService';

export default {
  name: 'Devolutions',
  components: {
    SimpleCard,
    DynamicListPage
  },
  data() {
    return {
      schema: DevolutionSchema,
      service: DevolutionsService,
      itemsEquals: []
    }
  },
  methods: {
    moreActions() {

    }
  }
}
</script>

<style>

</style>