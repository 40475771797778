import { formatCurrency } from '@/utils';

export default {
  beforeList: (formValue) => {
    let balance_total = 0;
    formValue.data.forEach((item) => {
      balance_total += parseFloat(item.balance);
    });
    formValue.balance_total = formatCurrency(balance_total);
  }
}