import ProductSchema from './schemas/ProductSchema';

import Products from './pages/Products';
import ProductForm from './pages/ProductForm';

export default [
  {
    path: ProductSchema.routes.list.path,
    name: ProductSchema.routes.list.name,
    component: Products,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: ProductSchema.routes.create.path,
    name: ProductSchema.routes.create.name,
    component: ProductForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: ProductSchema.routes.show.path,
    name: ProductSchema.routes.show.name,
    component: ProductForm,
    meta:{
      requiresAuth: true,
    }
  },
]