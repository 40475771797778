import MovementStocks from '@/business/MovementStocks';

export default {
  title: 'Movimentações dos Estoques',
  domain: 'product_variation_stock_moves',
  business: MovementStocks,
  description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
  singleSelect: true,
  routes: {
    list: {
      name: 'movimentations-stocks',
      path: '/movimentacoes-estoques',
    },
    back: {
      name: 'products',
      path: '/produtos',
    }
  },
  show: false,
  destroy: false,
  create: false,
  searchCol: 3,
  equalsCol: 3,
  betweenCol: 2,
  filtersParams: {
    ordering: {
      column: "created_at",
      value: "asc"
    },
    between: { 
      type: 'between',
      column: 'created_at' 
    },
    equals: { 
      type: 'equals',
      column: 'prod_stock.stock_id'
    }
  },
  fields: [
    {
      type: 'text',
      name: 'created_at',
      label: 'Data',
      md: '12',
      required: true,
      list: true,
    },
    {
      type: 'text',
      name: 'product',
      label: 'Produto',
      md: '12',
      required: true,
      list: true,
    },
    {
      type: 'text',
      name: 'stock_description',
      label: 'Estoque',
      md: '12',
      required: true,
      list: true,
    },
    {
      type: 'text',
      name: 'qtd',
      formattedName: 'qtd_formatted',
      label: 'Quant.',
      md: '12',
      required: true,
      list: true,
    },
    {
      type: 'text',
      name: 'origin',
      formattedName: 'origin',
      label: 'Origem',
      md: '12',
      required: true,
      list: true,
    },
    {
      type: 'text',
      name: 'user_name',
      formattedName: 'user_name',
      label: 'Usuário',
      md: '12',
      required: true,
      list: true,
    },
  ],
  footer: [
    {
      name: 'total_inputs',
      label: 'Total de Entradas',
      class: 'custom-footer-value',
      md: 2,
    },
    {
      name: 'total_outputs',
      label: 'Total de Saídas',
      class: 'custom-footer-value',
      md: 2,
    }
  ]
}