<template>
  <div>
    <v-card class="mt-3">
      <v-card-title>
        <h3>Dados Gerais</h3>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col md="2">
            <DataPick outlined dense no-icon model-name="devolutionDate" label="Data da Devolução"
              :rules="rules.required" :model="devolution.devolutionDate" @changeDataPick="changeDataPick" />
          </v-col>

          <v-col md="4">
            <v-autocomplete v-model="devolution.customer" outlined dense hide-no-data item-text="text" item-value="id"
              label="Cliente" return-object :rules="rules.required" :items="autocomletCustomer.items"
              :loading="autocomletCustomer.loading" :search-input.sync="autocomletCustomer.search"
              @keyup="autoCompleteKeyUp({item: 'autocomletCustomer', domain: 'registers', type: 'customer'})" />
          </v-col>

          <v-col md="2">
            <TextField v-model="devolution.qtd_items" label="Quant. Itens" dense readonly />
          </v-col>

          <v-col md="2">
            <InputCurrency v-model="devolution.difference" label="Diferença" readonly />
          </v-col>

          <v-col md="2">
            <InputCurrency v-model="devolution.value_total" label="Valor Total" readonly />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { rulesRequired } from '@/constants/masks';
import DataPick from '@/components/DataPick';
import TextField from '@/components/vuetify/TextField';
import InputCurrency from '@/components/vuetify/InputCurrency';
import AutoComplete from '@/mixins/AutoComplete'

export default {
  name: 'GeneralData',
  components: {
    DataPick,
    TextField,
    InputCurrency
  },
  props: {
    devolution: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      rules: rulesRequired,
      autocomletCustomer: {
        loading: false,
        search: '',
        items: [],
      },
    }
  },
  mixins: [AutoComplete],
  methods: {
    changeDataPick(data) {
      this.devolution[data.model] = data.date;
    },
  }
}
</script>

<style>

</style>