export default {
  title: 'Início',
  description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
  singleSelect: true,
  routes: {
    list: {
      name: 'home',
      path: '/',
    },
    create: {
      name: 'home',
      path: '/',
    },
  },
  show: false,
  destroy: false,
  fields: []
}