import UserSchema from './schemas/UserSchema';
import Users from './pages/Users';
import UserForm from './pages/UserForm';

export default [
  {
    path: UserSchema.routes.list.path,
    name: UserSchema.routes.list.name,
    component: Users,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: UserSchema.routes.create.path,
    name: UserSchema.routes.create.name,
    component: UserForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: UserSchema.routes.show.path,
    name: UserSchema.routes.show.name,
    component: UserForm,
    meta:{
      requiresAuth: true,
    }
  },
]