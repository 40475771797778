<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1100"
      class="dialog-custom"
    >
      <v-card class="">
        <v-card-title>
          <h3>Editar Variação</h3>
        </v-card-title>

        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-card-text>
            <v-row class="mt-0">
              <v-col>
                <v-text-field v-model="form.code" label="Código">
                  <v-icon slot="prepend">mdi-format-list-bulleted-square</v-icon>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="form.bar_code" label="Código de Barras" counter="13" v-mask="'#############'">
                  <v-icon slot="prepend">mdi-format-list-bulleted-square</v-icon>
                </v-text-field>
              </v-col>
              <v-col>
                <div class="content-money">
                  <div class="momey-icon"><v-icon>mdi-numeric</v-icon></div>
                  <vuetify-money
                    v-model="form.price_cost"
                    label="Preço Custo"
                    class="mt-5 money-content"
                    dense
                    required
                    :rules="rulesMoney"
                    :outlined="false"
                    :options="money_options"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col>
                <div class="content-money">
                  <div class="momey-icon"><v-icon>mdi-numeric</v-icon></div>
                  <vuetify-money
                    v-model="form.price_var"
                    label="Preço Varejo"
                    class="mt-5 money-content"
                    dense
                    required
                    :rules="rulesMoney"
                    :outlined="false"
                    :options="money_options"
                    @input="setPricesValues('price_var')"
                  />
                </div>
              </v-col>
              <v-col>
                <div class="content-money">
                  <div class="momey-icon"><v-icon>mdi-numeric</v-icon></div>
                  <vuetify-money
                    v-model="form.price_var_profit"
                    label="Lucro Varejo"
                    class="mt-5 money-content"
                    dense
                    required
                    :rules="rulesMoney"
                    :outlined="false"
                    :options="money_options"
                    @input="setPricesValues('price_var_profit')"
                  />
                </div>
              </v-col>
              <v-col>
                <div class="content-money">
                  <div class="momey-icon"><v-icon>mdi-numeric</v-icon></div>
                  <vuetify-money
                    v-model="form.price_ata"
                    label="Preço Atacado"
                    class="mt-5 money-content"
                    dense
                    required
                    :rules="rulesMoney"
                    :outlined="false"
                    :options="money_options"
                    @input="setPricesValues('price_ata')"
                  />
                </div>
              </v-col>
              <v-col>
                <div class="content-money">
                  <div class="momey-icon"><v-icon>mdi-numeric</v-icon></div>
                  <vuetify-money
                    v-model="form.price_ata_profit"
                    label="Preço Atacado"
                    class="mt-5 money-content"
                    dense
                    required
                    :rules="rulesMoney"
                    :outlined="false"
                    :options="money_options"
                    @input="setPricesValues('price_ata_profit')"
                  />
                </div>
              </v-col>
              <v-col>
                <v-text-field v-model="form.sku" label="SKU">
                  <v-icon slot="prepend">mdi-format-list-bulleted-square</v-icon >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small dark color="red" @click="$emit('closeDialog')">Fechar</v-btn>
            <v-btn small dark color="primary" @click="dialogEdit = true">Salvar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <DialogDestroy
      :dialog="dialogEdit"
      :colorButtons="{noBtn: 'red', yesBtn: 'primary'}"
      message="Deseja Realmente Alterar esta Variação?"
      :maxWidth="parseInt(500)"
      @noAction="dialogEdit = false"
      @yesAction="edit"
    />
  </div>
</template>

<script>
import DialogDestroy from "@/components/DialogDestroy";
import { moneyOptions } from '@/utils';
import _ from 'lodash';
import { mask } from 'vue-the-mask';
import { setValueProfits } from '@/business/Products';

export default {
  name: "DialogEditVariations",
  components: {
    DialogDestroy,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogEdit: false,
      form: {
        code: null,
        bar_code: '',
        price_cost: 0,
        price_var: 0,
        price_var_profit: 0,
        price_ata: 0,
        price_ata_profit: 0,
        sku: '',
      },
      validForm: true,
      rules: [
        value => !!value || 'Obrigatório.',
      ],
      rulesMoney: [
        value => this.parseStringFloatDecimal(value) || 'Digite valor maior que zero.',
      ],
    };
  },
  watch: {
    item: {
      handler() {
        const itemTmp = _.clone(this.item)
        this.form = itemTmp
      },
      deep: true,
    }
  },
  computed: {
    money_options() {
      return moneyOptions();
    },
    qtd_options() {
      return moneyOptions(3);
    }
  },
  directives: {
    mask
  },
  methods: {
    edit() {
      this.dialogEdit = false;
      this.$emit('editProductVariation', {form: this.form, item: this.item})
    },
    parseStringFloatDecimal(value) {
      value = value.replace('.', '')
      value = value.replace(',', '.')
      return value > 0;
    },
    setPricesValues(name) {
      setValueProfits({ name }, this.form);
    }
  },
};
</script>

<style lang="scss" scoped>
.content-money {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .momey-icon {
    margin-right: 10px;
  }

  .money-content {
    width: 100%;
  }
}
</style>