/* eslint-disable */
const MountParamsFilterEquals = {
  methods: {
    mountParamsFilterEquals(params, value) {
      const payload = {
        domain: params.domain,
        search: '',
        ordering: {
          column: params.ordering_column,
          value: params.ordering_value
        },
        q: [{ 
          type: 'equals',
          column: params.equals_column,
          value: params.equals_value,
        }]
      }
      const paginate = 'page=1&per_page=-1';

      return {
        payload,
        paginate,
      }
    },
  },
};

export default MountParamsFilterEquals;
