/* eslint-disable no-unused-vars */
import { formatCurrency } from '@/utils';

const setValueDefaultSelect = (data, model) => {
  model[data.name] = data.defaultValue
}

const verifyVariationSingle = (item) => {
  return item.product_variation_type == 'single' ? true : false
}

const setValuePriceVarProfit = (formValue) => {
  return ((formValue.price_var === undefined ? 0 : formValue.price_var - formValue.price_cost)*100) / formValue.price_cost;
}

const setValuePriceAtaProfit = (formValue) => {
  return ((formValue.price_ata - formValue.price_cost)*100) / formValue.price_cost;
}

const setValuePriceVar = (formValue) => {
  return ((formValue.price_cost * formValue.price_var_profit)/100)  + parseFloat(formValue.price_cost);
}

const setValuePriceAta = (formValue) => {
  return ((formValue.price_cost * formValue.price_ata_profit)/100)  + parseFloat(formValue.price_cost);
}

export const setValueProfits = (item, formValue) => {
  if(item.name === 'price_cost') {
    delete formValue.price_var
    delete formValue.price_var_profit
    delete formValue.price_ata
    delete formValue.price_ata_profit
  }else if(item.name === 'price_var') {
    formValue.price_var_profit = setValuePriceVarProfit(formValue);
  }else if(item.name === 'price_ata') {
    formValue.price_ata_profit = setValuePriceAtaProfit(formValue);
  }else if(item.name === 'price_var_profit') {
    formValue.price_var = setValuePriceVar(formValue);
  }else if(item.name === 'price_ata_profit') {
    formValue.price_ata = setValuePriceAta(formValue);
  }
}

export default {
  beforeList: (formValue) => {
    formValue.data.forEach((item) => {
      let qtd_stock = 0;

      item.variations.forEach((variation) => {
        variation.variation_stocks.forEach((variation_stock) => {
          qtd_stock += parseFloat(variation_stock.qtd);
         });
      });
      
      item.qtd_stock = qtd_stock;
      item.qtd_stock_formatted = formatCurrency(qtd_stock, {type: 'decimal', precision: 2});

      if(verifyVariationSingle(item)) {
        item.disableItemMoreActions = {
          viewVariations: true,
        }
      }

      if(!verifyVariationSingle(item)) {
        item.disableItemMoreActions = {
          viewStock: true,
          addStock: true,
        }
      }
    });
  },
  beforeFormMounted: (data) => {
    let items = [];
    data.fields.forEach((field) => {
      field.items.map((i) => {
        items.push(i)
      });
    })

    items.forEach((item) => {
      if(item.type == 'select') setValueDefaultSelect(item, data.models);
      if(data.route.name === 'product-create' && (item.name === 'product_variation_type' || item.name === 'stock_id')) item.disabled = false
      if(data.route.name === 'product-show') {
        if(item.name === 'product_variation_type' || item.name === 'stock_id' || item.name === 'code') {
          item.disabled = true;
          item.required = false;
        }
        if(item.name === 'product_variation_type' || item.name === 'stock_id' || item.name === 'code') {
          item.disabled = true;
          item.required = false;
        }
        
        if(data.models.product_variation_type === 'with_variations' && item.name === 'sku') {
          item.disabled = true;
          item.required = false;
        }
      }
    })
  },
  afterShow: ({ formValue, fields }) => {
  },
  selectChange(formValue, item, value, fields) {
    let items = [];
    fields.forEach((field) => {
      field.items.map((i) => {
        items.push(i)
      });
    })

    const obj_value = value == 'with_variations' ? { disabled: true, required: false } : { disabled: false, required: true };

    document.querySelector('#bar_code').disabled = obj_value.disabled;
    document.querySelector('#sku').disabled = obj_value.disabled;
    document.querySelector('#code').disabled = obj_value.disabled;

    items.forEach((item) => {
      if(item.name === 'qtd_stock') {
        item.disabled = obj_value.disabled;
        item.required = obj_value.required;
      }
    });
  },
  keyUp(formValue, item, value, fields) {
    setValueProfits(item, formValue)
  }
}