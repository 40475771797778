var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SimpleCard',{attrs:{"title":_vm.schema.title,"schema":_vm.schema}},[_c('DynamicDetailsPage',{attrs:{"schema":_vm.schema,"service":_vm.service,"type-page":_vm.typePage,"items-selects":{
        product_variation_type: _vm.product_variations,
        stock_id: _vm.stocks,
        category_id: _vm.categories,
        subcategory_id: _vm.subcategories,
        unit_measure_id: _vm.unit_measures,
        provider_id: _vm.providers,
        },"loading-select":_vm.loadingSelect},on:{"save":_vm.save}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }