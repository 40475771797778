import { routes as auth } from '@/pages/Auth';
import { routes as home } from '@/pages/Home';
import { routes as companies } from '@/pages/Companies';
import { routes as imports } from '@/pages/Imports';
import { routes as users } from '@/pages/Users';
import { routes as stock } from '@/pages/Stock';
import { routes as registers } from '@/pages/Registers';
import { routes as services } from '@/pages/Registers/Services';
import { routes as sale_orders } from '@/pages/Operations/SaleOrders';
import { routes as order_services } from '@/pages/Operations/OrderServices';
import { routes as devolutions } from '@/pages/Operations/Devolutions';
import { routes as credit_letters } from '@/pages/Operations/CreditLetters';
import { routes as account_payments } from '@/pages/AccountPayments';
import { routes as financial } from '@/pages/Financial';
import { routes as box } from '@/pages/Box'; 
import { routes as nfe } from '@/pages/NFe'; 
import { routes as reports } from '@/pages/Reports';
import { routes as pdv } from '@/pages/PDV';


export default [
  ...auth,
  ...home,
  ...companies,
  ...imports,
  ...users,
  ...stock,
  ...registers,
  ...services,
  ...sale_orders,
  ...order_services,
  ...devolutions,
  ...credit_letters,
  ...account_payments,
  ...financial,
  ...box,
  ...nfe,
  ...reports,
  ...pdv,
]