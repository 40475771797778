import DynamicService from '../../../../service/DynamicService';
import { formatCurrency, formatDate, getText } from '@/utils';
import { pagarme_transaction_status } from '@/constants/options';

const formatResponse = (item) => {
  item.created_at = formatDate(item.created_at);
  item.customer_name = item.customer.name;
  item.amount_formatted = formatCurrency(item.amount);
  item.billet_expiration_date_formatted = formatDate(item.billet_expiration_date);
  item.status_formatted = getText(pagarme_transaction_status, item.status);
  item.billet_url = 'https://app.siscomge.com.br/billet-example.pdf';
  item.billet_barcode = '07797.77705 00011.629987 26619.699486 4 87250000004000';
}

const BilletService = DynamicService('billets', {
  formatResponse
});

export default BilletService ;