<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">

      <DynamicListPage
        :schema="schema"
        :service="service"
        @moreActions="moreActions" />
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import BilletSchema from '../schemas/BilletSchema';
import BilletService from '../services/BilletService';

export default {
  name: 'Billets',
  components: {
    SimpleCard,
    DynamicListPage,
  },
  data () {
    return {
      schema: BilletSchema,
      service: BilletService,
    }
  },
  methods: {
    moreActions(value) {
      this[value.action](value.item);
    },
    printBillet(item) {
      console.log(item)
      window.open(item.billet_url, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
