import { render, staticRenderFns } from "./PaymentMethodSettingsForm.vue?vue&type=template&id=c6821514&scoped=true&"
import script from "./PaymentMethodSettingsForm.vue?vue&type=script&lang=js&"
export * from "./PaymentMethodSettingsForm.vue?vue&type=script&lang=js&"
import style0 from "./PaymentMethodSettingsForm.vue?vue&type=style&index=0&id=c6821514&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6821514",
  null
  
)

export default component.exports