import DynamicService from '../../../service/DynamicService';
import { all_types, yes_no } from '@/constants/options';
import { getText } from '@/utils';

const formatResponse = (item) => {
  item.type_formatted = getText(all_types, item.type);
  item.global_formatted = getText(yes_no, item.global);
}

const AllTypeService = DynamicService('all-types', {
  formatResponse
});

export default AllTypeService;