import { formatCurrency, getText, formatDatePT } from '@/utils';
import { type_origin_boxes } from '@/constants/options';

export default {
  beforeList: (formValue, schema) => {
    schema.preferencesParams.forEach((item) => {
      if(item.name === 'dataResume') {
        item.model = false;
      }
    });

    let value_total = 0;
    let value_final = 0;

    const options = formValue.data.shift();
    formValue.data.forEach((item) => {
      value_total += parseFloat(item.value);
      value_final += parseFloat(item.value_final);

      if(item.movement_history_status === 'done'){
        item.disableShow = true ;
        item.disableDelete = true ;
      }
    });

    formValue.value_total = formatCurrency(value_total);
    formValue.value_tax = formatCurrency(value_total-value_final);
    formValue.value_final = formatCurrency(options.value_final);
    formValue.balance_inputs_period = formatCurrency(options.balance_inputs_period);
    formValue.balance_outputs_period = formatCurrency(options.balance_outputs_period);
    formValue.previous_balance_inputs_total = formatCurrency(options.previous_balance_inputs_total);
    formValue.previous_balance_outputs_total = formatCurrency(options.previous_balance_outputs_total);
  },
  preferencesParams: (formValue, item, filter_params) => {
    if(item.name === 'dataResume') {
      const data_resume = [];
      let result = formValue.data.reduce(function (r, a) {
        r[a.origin_type] = r[a.origin_type] || [];
        r[a.origin_type].push(a);
        return r;
      }, Object.create(null));
    
      Object.keys(result).forEach((item, index) => {
        let value = 0;
        let tax = 0;
        let value_tax = 0;
        let value_final = 0;

        result[item].forEach((i) => {
          value += parseFloat(i.value);
          tax += parseFloat(i.tax);
          value_tax += parseFloat(i.value_tax);
          value_final += parseFloat(i.value_final);
        });

        data_resume.push({
          id: index,
          movement_date_formatted: `${formatDatePT(filter_params.between.dateInitial)} à ${formatDatePT(filter_params.between.dateFinal)}`,
          bank: {description: '-'},
          origin_type_formatted: `${getText(type_origin_boxes, item)}`,
          value_formatted: formatCurrency(value),
          tax_formatted: formatCurrency(tax, 'decimal'),
          value_tax_formatted: formatCurrency(value_tax),
          value_final_formatted: formatCurrency(value_final),
          movement_history_status_formatted: `-`,
        });
      });

      formValue.data = data_resume;
    }
  }
}