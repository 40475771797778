import axios from '../../../service'

const AuthService = {
  login(user) {
    return new Promise((resolve, reject) => {
      axios.post('/login', user)
      .then(resp => {
        const token = resp.data.access_token;
        const user = resp.data.user;
        const company = resp.data.user.company;
        
        localStorage.setItem(`${process.env.VUE_APP_NAME}.access_token`, token);
        localStorage.setItem(`${process.env.VUE_APP_NAME}.user`, JSON.stringify(user));
        localStorage.setItem(`${process.env.VUE_APP_NAME}.company`, JSON.stringify(company));

        resolve(resp);
      })
      .catch(err => {
        this.cleanLocalStorage();
        reject(err);
      })
    })
  },
  register(company) {
    return new Promise((resolve, reject) => {
      axios.post('/companies', company)
      .then(resp => {
        const token = resp.data.access_token;
        const user = resp.data.user;
        const company = resp.data.user.company;
        
        localStorage.setItem(`${process.env.VUE_APP_NAME}.access_token`, token);
        localStorage.setItem(`${process.env.VUE_APP_NAME}.user`, JSON.stringify(user));
        localStorage.setItem(`${process.env.VUE_APP_NAME}.company`, JSON.stringify(company));

        resolve(resp);
      })
      .catch(err => {
        this.cleanLocalStorage();
        reject(err);
      })
    })
  },
  me() {
    return new Promise((resolve, reject) => {
      axios.post('/me')
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        this.cleanLocalStorage();
        reject(err);
      })
    })
  },
  logout() {
    return new Promise((resolve) => {
      this.cleanLocalStorage();
      resolve(true);
    })
    
  },
  cleanLocalStorage() {
    localStorage.removeItem(`${process.env.VUE_APP_NAME}.access_token`);
    localStorage.removeItem(`${process.env.VUE_APP_NAME}.user`);
    localStorage.removeItem(`${process.env.VUE_APP_NAME}.company`);
  }
}

export default AuthService;