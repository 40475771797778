<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">

      <DynamicListPage
        ref="dynamicListPageStocks"
        :schema="schema"
        :service="service"
        @moreActions="moreActions"/>
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import StockSchema from '../schemas/StockSchema';
import StockService from '../services/StockService';
import { yes_no } from '@/constants/options';

export default {
  name: 'Stocks',
  components: {
    SimpleCard,
    DynamicListPage,
  },
  data () {
    return {
      schema: StockSchema,
      service: StockService,
    }
  },
  methods: {
    moreActions(value) {
      this[value.action](value.item);
    },
    setStockDefault(item) {
      const id = item.id;
      const data = {
        default: yes_no[0].value
      };

      this.service.update(id, data).then(() => {
        this.$noty.success('Alterado com sucesso');
        this.$refs.dynamicListPageStocks.getDataFromApi();
      }).catch(() => {
        this.$noty.error('Erro ao alterar');
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
