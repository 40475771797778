import { formatCurrency } from '@/utils';

export default {
  beforeList: (formValue) => {
    let total_devolutions = 0;
    console.log(formValue)
    formValue.data.forEach((item) => {
      total_devolutions += parseFloat(item.value_total)
    });

    formValue.total_devolutions = formatCurrency(total_devolutions);
  }
}