<template>
  <v-card
    width="320"
    class="mx-2 my-2"
  >
    <v-card-text>
      <div>Importação de:</div>
      <p class="text-h4 text--primary">{{ item.label }}</p>
      <div class="text--primary">Clique no botão "Baixar Aquivos Modelos" para baixar os modelos de arquivs CSV para a importação</div>
    </v-card-text>
    <v-card-actions>
      <v-btn small color="primary" @click="$emit('openModal', item)">Importar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
    name: 'CardImportType',
    props: {
        item: {
            type: Object,
            default: () => {}
        }
    }
}
</script>

<style>

</style>