/* eslint-disable */
import { formatDate } from '@/utils';

const PrintMovementHistory = {
  methods: {
    print() {
      var class_header_content = 'padding: 10px; border: 1px solid #ccc;  width: 1020px; position: relative;  top: 0; right: 0; left: 0; bottom: 0; margin: 5px auto; text-align: center;';
      var class_custom_footer = 'display: flex; margin-top: 25px';
      var class_custom_footer_item = 'margin: 0 15px; padding: 0 7px; border: 1px solid #ccc; min-width: 130px';
      var class_table = 'table {width: 100%;}'
      var class_theader = 'table thead, table tfoot {background-color: #EFEFEF; border: none; padding: 5px;}'

      const data = document.getElementsByClassName('v-data-table__wrapper')[0].innerHTML
      const previousBalanceInputsTotal = document.getElementsByClassName('custom-footer-value')[0].innerHTML
      const previousBalanceOutputsTotal = document.getElementsByClassName('custom-footer-value')[1].innerHTML
      const balanceInputsPeriods = document.getElementsByClassName('custom-footer-value')[2].innerHTML
      const balanceOutputsPeriods = document.getElementsByClassName('custom-footer-value')[3].innerHTML
      const win = window.open('', '', 'width=800, heigth=600');

      win.document.write('<html>');
      win.document.write('<head>');
      win.document.write('<title>Histórico de Movimentações</title>');
      win.document.write('<style>');
      win.document.write(class_table);
      win.document.write(class_theader);
      win.document.write('</style>');
      win.document.write('</head>');
      win.document.write('<body>');
      
      win.document.write('<div style="'+class_header_content+'">');
      win.document.write('<h3>Relatório de Caixa</h3>');
      win.document.write('<p>'+formatDate(this.dateInitial)+' à '+formatDate(this.dateFinal)+'</p>');
      win.document.write('</div>');

      win.document.write('<div style="'+class_header_content+'">');
        win.document.write(data);
        win.document.write('<div style="'+class_custom_footer+'">');
          win.document.write('<div style="'+class_custom_footer_item+'">');
            win.document.write(previousBalanceInputsTotal);
          win.document.write('</div>');
          win.document.write('<div style="'+class_custom_footer_item+'">');
            win.document.write(previousBalanceOutputsTotal);
          win.document.write('</div>');
          win.document.write('<div style="'+class_custom_footer_item+'">');
            win.document.write(balanceInputsPeriods);
          win.document.write('</div>');
          win.document.write('<div style="'+class_custom_footer_item+'">');
            win.document.write(balanceOutputsPeriods);
          win.document.write('</div>');
        win.document.write('</div>');
      win.document.write('</div>');

      win.document.write('</body>');
      win.document.write('</html>');
      win.document.close;
    },
  },
};

export default PrintMovementHistory;
