<template>
  <div>
    <v-autocomplete
      v-model="localModal"
      prepend-icon="mdi-format-list-bulleted-square"
      return-object
      item-text="text"
      item-value="id"
      hide-no-data
      ref="autoCompleteSearch"
      v-on:keyup.enter="autoCompleteKeyUp()"
      :items="items"
      :loading="loading"
      :search-input.sync="searh"
      :readonly="readOnly"
      :label="label"
      :placeholder="label"
      @change="autoCompleteChange()"
      @keyup="autoCompleteKeyUp()"></v-autocomplete>
  </div>
</template>

<script>
import _ from 'lodash';
import DynamicService from '@/service/DynamicService';

export default {
  name: 'AutoCompleteSearch',
  components: {

  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    autoFocus: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      localModal: {},
      loading: false,
      searh: '',
      items: [],
    }
  },
  mounted() {
  },
  watch: {
    localModal() {
      this.$emit('actionAutoComplete', this.localModal);
    }
  },
  methods: {
    autoCompleteChange() {
    },
    autoCompleteKeyUp() {
      this.debounceInput();
    },
    debounceInput: _.debounce(function () {
      this.getAutoCompleteSearch();
    }, 1000),
    getAutoCompleteSearch () {
      this.loading = true;
      let val = this.searh;
      let paginate = `page=1&per_page=-1`;

      let payload = {
        domain: this.config.domain,
        search: val,
        ordering: this.config.ordering,
        q: this.config.q,
      }

      DynamicService().filters(payload, paginate).then((res) => {
        const { total, data } = res.data;
          this.count = total;
          let items = data;
          let autoItems = [];

          items.forEach((item) => {
            autoItems.push({id: item.id, text: item.name})
          });

          this.items = autoItems;
          this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
  }
}
</script>

<style>

</style>