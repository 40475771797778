<template>
  <div>
    <SimpleCard 
      :title="schema.title" 
      :schema="schema">

      <DynamicDetailsPage
        :schema="schema"
        :service="service"
        :type-page="typePage"
        :items-selects="{type: allTypes}"
        @save="save" />
    </SimpleCard>
    
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicDetailsPage from '@/components/DynamicDetailsPage'
import AllTypeSchema from '../schemas/AllTypeSchema';
import AllTypeService from '../services/AllTypeService';
import { all_types } from '@/constants/options';

export default {
  name: 'AllTypeForm',
  components: {
    SimpleCard,
    DynamicDetailsPage,
  },
  data () {
    return {
      schema: AllTypeSchema,
      service: AllTypeService,
      typePage: this.$route.name === 'alltype-create' ? 'create' : 'update',
      allTypes: all_types,
    }
  },
  mounted() {
  },
  methods: {
    save() {
      this.$noty.success('Registro salvo com sucesso!');
      this.$router.push({name: this.schema.routes.list.name});
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
