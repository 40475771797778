import axios from 'axios';
import DynamicService from '../../../service/DynamicService';

// const formatResponse = (item) => {

// }

const ImportService = DynamicService('imports', {
});

const ImportsCommands = () => ({
  async uploadFileCSV(params) {
    return new Promise((resolve, reject) => {
      let payload = new FormData();
      payload.append('file', true);
      payload.append(params.domain, params.file_import);
      
      axios.post(`import-${params.domain}?_method=POST`, payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async downloadModel() {
    return new Promise((resolve, reject) => {
      axios.post(`download-model-import`).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    })
  },
});

export default ImportService;
export { ImportService, ImportsCommands };