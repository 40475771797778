// import axios from 'axios';
import DynamicService from '@/service/DynamicService';
import { formatDate,formatCurrency } from '@/utils';

const formatResponse = (item) => {
    item.devolution_date_formatted = formatDate(item.devolution_date)
    item.value_total_formatted = formatCurrency(item.value_total);
}

const formatRequest = (item) => {
  item.items.forEach((i) => {
    delete i.product_variation;
    delete i.i_number;
    delete i.sale_order_id;
    delete i.order_date;
    delete i.item_number;
    delete i.bar_code;
    delete i.product_name;
    delete i.value_single;
    delete i.value_discount;
    delete i.order_date_formatted;
    delete i.value_single_formatted;
    delete i.value_discount_formatted;
    delete i.value_total_formatted;
  });
}

const DevolutionService = DynamicService('devolutions', {
  formatResponse,
  formatRequest
});

const DevolutionCommands = () => ({
  async test() {
  },
});

export default DevolutionService;
export { DevolutionService, DevolutionCommands };