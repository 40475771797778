<template>
  <div>
    <SimpleCard 
      :title="schema.title" 
      :schema="schema"
      @actionBtn="actionBtn">

      <DynamicDetailsPage
        :schema="schema"
        :service="service"
        :type-page="typePage"
        :items-selects="{type: type_users}" />
    </SimpleCard>
  </div>
</template>

<script>
/* eslint-disable */
import SimpleCard from '@/components/SimpleCard'
import DynamicDetailsPage from '@/components/DynamicDetailsPage'
import UserSchema from '../schemas/UserSchema';
import UserService from '../services/UserService';
import { type_users } from '@/constants/options';

export default {
  name: 'UserForm',
  components: {
    SimpleCard,
    DynamicDetailsPage,
  },
  data () {
    return {
      schema: UserSchema,
      service: UserService,
      typePage: this.$route.name === 'user-create' ? 'create' : 'update',
      item: {},
      type_users: type_users
    }
  },
  mounted() {
    
  },
  methods: {
    actionBtn() {
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
