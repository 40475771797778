import { view } from '@/constants/icons';

export default {
  domain: 'variations',
  title: 'Variações',
  business: null,
  description: 'Neste tela você pode visualizar todos as variações cadastradas no sistema',
  singleSelect: true,
  icon: 'mdi-database',
  routes: {
    list: {
      name: 'variations',
      path: '/variacoes',
    },
    create: {
      name: 'variation-create',
      path: '/variacoes/novo',
      children: []
    },
    show: {
      name: 'variation-show',
      path: '/variacoes/:id',
    },
  },
  noBetween: true,
  noEquals: true,
  show: true,
  destroy: true,
  moreActions: [
    {
      name: 'viewItemsVariations',
      label: 'Itens da Variação',
      icon: view,
      action: 'viewItemsVariations',
    },
  ],
  create: true,
  noSearch: false,
  searchCol: 4,
  fields: [
    {
      icon: null,
      title: 'Dados da Variação',
      items: [
        {
          id: 'description',
          type: 'text',
          name: 'description',
          formattedName: 'description',
          label: 'Descrição',
          align: '',
          md: '6',
          list: true,
          required: true,
        },
      ]
    },
  ]
}