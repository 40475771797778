<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    class="dialog-custom"
  >
    <v-card class="">
      <v-card-title>
        <h3>Adicionar ou Alterar Item</h3>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="mt-3">
        <h3>Pesquisar Item:</h3>
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row class="mt-1">
            <v-col>
              <v-autocomplete
                v-model="serviceSelected"
                outlined
                dense
                hide-no-data
                item-text="text"
                item-value="id"
                label="Pesquise o item"
                return-object
                :items="autocomletServices.items"
                :loading="autocomletServices.loading"
                :search-input.sync="autocomletServices.search"
                @keyup="autoCompleteKeyUp({item: 'autocomletServices', domain: 'services', type: ''})" />
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="col-12 col-md-3 mt-0">
              <v-text-field
                v-model="values.qtd"
                dense
                outlined
                label="Quantidade"
                :rules="rules.biggerZero" />
            </v-col>

            <v-col cols="col-12 col-md-3 mt-0">
              <div class="content-money">
                <vuetify-money
                  v-model="values.discount"
                  class="money-content"
                  outlined
                  dense
                  label="Desconto"
                  :options="money_options" />
              </div>
            </v-col>

            <v-col cols="col-12 col-md-3 mt-0">
              <div class="content-money">
                <vuetify-money
                  v-model="values.unity_value"
                  class="money-content"
                  outlined
                  dense
                  label="Valor Unitário"
                  readonly
                  :rules="rules.money" 
                  :options="money_options" />
              </div>
            </v-col>

            <v-col cols="col-12 col-md-3 mt-0">
              <div class="content-money">
                <vuetify-money
                  v-model="values.total_value"
                  class="money-content"
                  outlined
                  dense
                  label="Valor Total"
                  readonly
                  :rules="rules.money" 
                  :options="money_options" />
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="col-12 col-md-3">
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col>
              <v-progress-linear v-if="loadingForm"
                :indeterminate="loadingForm"
                rounded
                color="primary"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small dark rounded color="red" @click="resetItem(), $emit('close')">Cancelar (ESC)</v-btn>
        <v-btn small dark rounded color="primary" @click="action()" v-on:keyup.enter="action()">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AutoComplete from '@/mixins/AutoComplete'
import ServiceService from '@/pages/Registers/Services/services/ServiceService';
import { mask } from "vue-the-mask";
import { rulesMoney, rulesBiggerZero } from '@/constants/masks';

export default {
  name: 'DialogItem',
  components: {
  },
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    typeOrder: {
      type: String,
      default: 'retail',
      required: true,
    }
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      this.changeActionKeyUp(e.keyCode);
    });
  },
  data() {
    return {
      validForm: true,
      serviceSelected: {},
      values: {
        service_id: null,
        qtd: 1,
        discount: 0,
        unity_value: 0,
        total_value: 0,
      },
      item: {},
      autocomletServices: {
        loading: false,
        search: '',
        items: [],
      },
      money_options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2
      },
      rules: {
        money: rulesMoney,
        biggerZero: rulesBiggerZero,
      },
      loadingForm: false,
    }
  },
  directives: {
    mask,
  },
  watch: {
    serviceSelected: {
      handler() {
        this.getService();
      },
      deep: true,
    },
    ['values.qtd']: {
      handler() {
        this.calculateTotalValue();
      },
      deep: true,
    },
    ['values.discount']: {
      handler() {
        this.calculateTotalValue();
      },
      deep: true,
    },
  },
  mixins: [AutoComplete],
  methods: {
    changeActionKeyUp(keyCode) {
      if(keyCode == 27) {
        this.$emit('close')
      }else if(keyCode == 13) {
        this.action();
      }
    },
    action() {
      if(!this.$refs.form.validate())
        return;

      this.$emit('action', {action: 'addItem', close: 'dialogItem', params: {item: this.item, newItem: true}});
    },
    getService() {
      if(!this.serviceSelected.id)
        return;

      this.loadingForm = true;
      ServiceService.show(this.serviceSelected.id).then((item) => {
        this.values.unity_value = this.typeOrder === 'retail' ? item.price_var : item.price_ata;
        
        this.item.id = null;
        this.item.service_id = item.id;
        this.item.name = item.name;
        this.calculateTotalValue();
        this.loadingForm = false;
      }).catch(() => {
        this.loadingForm = false;
      });
    },
    calculateTotalValue() {
      this.values.total_value = (parseFloat(this.values.unity_value) * parseFloat(this.values.qtd) - this.values.discount);

      this.item.qtd = this.values.qtd;
      this.item.discount = this.values.discount;
      this.item.price_unity = this.values.unity_value;
      this.item.total_value = (parseFloat(this.item.price_unity) * parseFloat(this.values.qtd) - this.values.discount);
    },
    resetItem() {
      this.$refs.form.resetValidation();
      this.serviceSelected = {};
      this.values.qtd = 1;
      this.values.discount = 0;
      this.values.unity_value = 0;
      this.values.total_value = 0;
    }
  }
}
</script>
<style scoped>
</style>