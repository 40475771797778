<template>
  <div>
    <v-card class="my-12" style="background-color: #FCFCFC">
      <v-card-title style="background-color: #ccc">
        <div class="simple-card-header" >
          <div class="simple-card-infos">
            <h3 class="">
              <v-icon class="header-icon">{{ schema.icon }}</v-icon>
              {{ schema.title }} <p>{{ schema.description }}</p>
            </h3>
            <small>{{ $route.meta.description }}</small>
          </div>
          <div>
            <v-btn v-if="!noBack" small color="primary" class="mr-1" @click="$router.back()">Voltar</v-btn>
            <v-btn v-if="routeCreate" small color="success" :to="{name: routeCreate}">Novo</v-btn>
          </div>
        </div>
      </v-card-title>

      <v-card-text>
        <slot></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'SimpleCard',
  props: {
    schema: {
      type: Object,
      default: () => {}
    },
    routeCreate: {
      type: String,
      default: '',
    },
    noBack: {
      type: Boolean,
      default: false,
    },
    noBtnAction: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    actionBtn() {
      this.$emit('actionBtn');
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-icon {
    font-size: 32px;
  }

  .simple-card-infos {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0 !important;
      p{
        font-size: 14px;
        line-height: 18px !important;
        font-weight: normal;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .simple-card-header {
    flex-direction: column;
    align-items: flex-start;

    .header-icon {
      font-size: 32px;
    }

    .simple-card-infos {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>