import { routes as banks } from './Banks';
import { routes as movement_histories } from './MovementHistories';
import { routes as payment_method_settings } from './PaymentMethodSettings';
import { routes as billets } from './Billets';
import { routes as payment_links } from './PaymentLinks';

export default [
  ...banks,
  ...movement_histories,
  ...payment_method_settings,
  ...billets,
  ...payment_links,
]