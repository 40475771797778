import axios from 'axios';
import DynamicService from '../../../service/DynamicService';

const formatResponse = (item) => {
  item
}

const CompanyPreferenceParameterService = DynamicService('sale-orders', {
  formatResponse,
});

const CompanyPreferenceParameterCommands = () => ({
  async get_with_filters(payload) {
    return new Promise((resolve, reject) => {
      axios.get(`company-preferences?${payload}`).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async get_all_by_domain(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`company-preferences-by-domain`, payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
});

export default CompanyPreferenceParameterService;
export { CompanyPreferenceParameterService, CompanyPreferenceParameterCommands };