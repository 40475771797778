/* eslint-disable */
import { formatDate } from '@/utils';

const PrintDanfeNFCe = {
  methods: {
    getCompany() {
      return JSON.parse(localStorage.getItem('siscomge.company'));
    },
    getLogo(logo) {
      if(!logo)
        return require(`@/assets/images/logo-default.jpg`);

      return logo;
    },
    print(item, barCode) {
      const classes = this.defineClass();

      const tela_impressao = window.open('about:blank');
      tela_impressao.document.write('<html>');
        tela_impressao.document.write('<head>');
        tela_impressao.document.write('<title>DANFE</title>');
          tela_impressao.document.write('<style>');
          tela_impressao.document.write(classes);
          tela_impressao.document.write('</style>');
        tela_impressao.document.write('</head>');
        tela_impressao.document.write('<div id="print" class="conteudo tax-coupon">');
          tela_impressao.document.write(this.makeHeader(item));
          tela_impressao.document.write(this.makeEmission(item));
          tela_impressao.document.write(this.makeHeader(item));
          tela_impressao.document.write(this.makeEmission(item));
          tela_impressao.document.write(this.makeTitleDANFE(item));
          tela_impressao.document.write(this.makeItemsHeader(item));
          tela_impressao.document.write(this.makeItems(item));
          tela_impressao.document.write(this.makeTotals(item));
          tela_impressao.document.write(this.makeCusctomerSeller(item));
          tela_impressao.document.write(this.makeDataTax(item));
          tela_impressao.document.write(this.makeQRCode(item, barCode));
        tela_impressao.document.write('</div>');
      tela_impressao.document.write('</html>');
      tela_impressao.window.print();
      tela_impressao.window.close();
    },
    defineClass() {
      let classes = '* { margin: 0px !important; padding: 0px !important } ';
      classes += '.tax-coupon { width: 100%; max-width: 300px; font-size: 12px; } ';
      classes += '.tax-coupon-header { text-align: center; font-weight: bold; border-bottom: 1px dashed #000; padding: 5px !important } ';
      classes += '.tax-coupon-emission { text-align: center; border-bottom: 1px dashed #000; padding: 5px !important } ';
      classes += '.tax-coupon-title-danfe { text-align: center; border-bottom: 1px dashed #000; padding: 5px 15px !important } ';
      classes += '.tax-coupon-items { border-bottom: 1px dashed #000; padding: 5px !important } ';
      classes += '.item-table { font-size: 12px; } ';
      classes += '.tax-coupon-totals { border-bottom: 1px dashed #000; padding: 5px !important } ';
      classes += '.tax-coupon-customer-seller { border-bottom: 1px dashed #000; padding: 5px !important } ';
      classes += '.tax-coupon-data-tax { text-align: center; border-bottom: 1px dashed #000; padding: 5px !important } ';
      classes += '.tax-coupon-qrcode { text-align: center; border-bottom: 1px dashed #000; padding: 5px !important } ';
      classes += '.content-qrcode { margin-top: 20px !important; display: flex; justify-content: center } ';
      
      return classes;
    },
    makeHeader(item) {
      let html = `
        <div class="tax-coupon-header">
          <p>MGE Tech</p>
          <p>Av. Miguel Castro, 1098 - Lagoa Nova</p>
          <p>CEP: 59.075-740 - Natal/RN</p>
          <p>CNPJ: 11.574.224/0001-00 IE: 20.654.25</p>
          <p>Fone: (84)98848-1941</p>
        </div>
      `;
      return html;
    },
    makeEmission(item) {
      let html = `
        <div class="tax-coupon-emission">
          <p>Emissão: 01/11/2021 - 16:21:55</p>
        </div>
      `;
      return html;
    },
    makeTitleDANFE(item) {
      let html = `
        <div class="tax-coupon-title-danfe">
          <p>DANFE - Documento Auxiliar de Nota Fiscal Eletrônica para Consulmidor Final</p>
          <p>Não permite aproveitamento de crédito de ICMS</p>
        </div>
      `;
      return html;
    },
    makeItemsHeader(item) {
      let html = `
        <div class="tax-coupon-items">
          <table width="100%">
            <tr>
              <td><p class="item-table" class="item-table">Item</p><td>
              <td><p class="item-table">Cod</p><td>
              <td colspan="5"><p class="item-table">Descr</p><td>
            </tr>
            <tr>
              <td width="5%"><p class="item-table"></p><td>
              <td width="5%"><p class="item-table">Qtde</p><td>
              <td width="5%"><p class="item-table">Un</p><td>
              <td width="25%"><p class="item-table">Vlr Uni</p><td>
              <td width="25%"><p class="item-table">Vlr Total</p><td>
              <td width="10%"><p class="item-table">Desc</p><td>
              <td width="25%"><p class="item-table">Vlr Final</p><td>
            </tr>
          </table>
        </div>
      `;
      return html;
    },
    makeItems(item) {
      let html = `
        <div class="tax-coupon-items">
          <table width="100%">
            <tr>
              <td><p class="item-table" class="item-table">1</p><td>
              <td><p class="item-table">35765</p><td>
              <td colspan="5"><p class="item-table">Biscoito Recheado de Chocolate</p><td>
            </tr>
            <tr>
              <td width="5%"><p class="item-table"></p><td>
              <td width="5%"><p class="item-table">2</p><td>
              <td width="5%"><p class="item-table">UN</p><td>
              <td width="25%"><p class="item-table">2,00</p><td>
              <td width="25%"><p class="item-table">4,00</p><td>
              <td width="10%"><p class="item-table">0,00</p><td>
              <td width="25%"><p class="item-table">4,00</p><td>
            </tr>
          </table>
        </div>
      `;
      return html;
    },
    makeTotals(item) {
      let html = `
        <div class="tax-coupon-totals">
          <table width="100%">
            <tr>
              <td width="50%" align="left"><p class="item-table" class="item-table">Qtd Items</p><td>
              <td width="50%" align="right"><p class="item-table">1</p><td>
            </tr>
            <tr>
              <td width="50%" align="left"><p class="item-table" class="item-table">Qtd Volumes</p><td>
              <td width="50%" align="right"><p class="item-table">1</p><td>
            </tr>
            <tr>
              <td width="50%" align="left"><p class="item-table" class="item-table">Valor Total</p><td>
              <td width="50%" align="right"><p class="item-table">4,00</p><td>
            </tr>
            <tr>
              <td width="50%" align="left"><p class="item-table" class="item-table">Valor Desconto</p><td>
              <td width="50%" align="right"><p class="item-table">0,00</p><td>
            </tr>
            <tr>
              <td width="50%" align="left"><p class="item-table" class="item-table">Valor Final</p><td>
              <td width="50%" align="right"><p class="item-table">4,00</p><td>
            </tr>
            <tr>
              <td width="50%" align="left"><p class="item-table" class="item-table">Forma Pagamento</p><td>
              <td width="50%" align="right"><p class="item-table">Valores Pago</p><td>
            </tr>
            <tr>
              <td width="50%" align="left"><p class="item-table" class="item-table">Dinheiro</p><td>
              <td width="50%" align="right"><p class="item-table">4,00</p><td>
            </tr>
          </table>
        </div>
      `;
      return html;
    },
    makeCusctomerSeller(item) {
      let html = `
        <div class="tax-coupon-customer-seller">
          <p><strong>Vendedor: </strong>Vendedor Padrão</p>
          <p><strong>Consumidor: </strong>Renan Trindade dos Reis - CPF: 026.640.933-47</p>
        </div>
      `;
      return html;
    },
    makeDataTax(item) {
      let html = `
        <div class="tax-coupon-data-tax">
          <p>Número: 25658 - Série: 2 - Emissão: 01/11/2021 16:20:28</p>
          <p>Consulte pela chave de acesso em</p>
          <p>https://www.set.rn.gov.br/consulta</p>
          <p>Chave de Acesso</p>
          <p>6542 5256 5856 4585 2356 7459 9153 4586 5852</p>
        </div>
      `;
      return html;
    },
    makeQRCode(item, barCode) {
      let html = `
        <div class="tax-coupon-qrcode">
          <p>Consulta via leitor de QR Code</p>
          <div class="content-qrcode"><div>${barCode}</div></div>
          <p style="margin-top: 20px !important">Protocolo de Autorização</p>
          <p>65451564654564 01/11/2021 16:00:55</p>
          <p style="margin-top: 20px !important">Valor Apx Tributos 2,99 (15,3% Imp) IBPT</p>
        </div>
      `;
      return html;
    },
  },
};

export default PrintDanfeNFCe;
