import InvoiceSchema from './schemas/InvoiceSchema';
import Invoices from './pages/Invoices';
import InvoiceForm from './pages/InvoiceForm';

export default [
  {
    path: InvoiceSchema.routes.list.path,
    name: InvoiceSchema.routes.list.name,
    component: Invoices,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: InvoiceSchema.routes.create.path,
    name: InvoiceSchema.routes.create.name,
    component: InvoiceForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: InvoiceSchema.routes.show.path,
    name: InvoiceSchema.routes.show.name,
    component: InvoiceForm,
    meta:{
      requiresAuth: true,
    }
  },
]