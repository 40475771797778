import DynamicService from '@/service/DynamicService';
import { yes_no } from '@/constants/options';
import { getText } from '@/utils';

const formatResponse = (item) => {
  item.default_formatted = getText(yes_no, item.default);
}

const StockService = DynamicService('stocks', {
  formatResponse,
});

export default StockService ;