<template>
  <div>
    <v-text-field
      class="mb-5"
      outlined
      dense
      v-model="search"
      append-icon="mdi-magnify"
      label="Pesquisar"
      hide-details>
    </v-text-field>

    <v-data-table 
      v-model="selected"
      :id="id"
      :loading="loadingDataTable"
      :search="search"
      :headers="headers"
      :items="itemsDataTable"
      :options.sync="options"
      :server-items-length="totalItemsDataTable"
      :show-select="showSelect"
      :single-select="singleSelect"
      class="elevation-1 mt-5">
    </v-data-table>
  </div>
  
</template>

<script>
/* eslint-disable */
import { debounce } from "@/plugins/debounce.js";

export default {
  name: 'DataTableDefault',
  components: {},
  props: {
    id: {
      type: String,
      default: 'id'
    },
    headers: {
      type: Array,
      default: () => []
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    showSelect: {
      type: Boolean,
      default: true
    },
    singleSelect: {
      type: Boolean,
      default: true
    },
    loadingDataTable: {
      type: Boolean,
      default: false
    },
    itemsDataTable: {
      type: Array,
      default: () => []
    },
    totalItemsDataTable: {
      type: Number,
      default: 0
    }
  },
  mounted() {
  },
  data() {
    return {
      search: '',
      options: {
        page: 1,
        itemsPerPage: this.itemsPerPage
      },
      selected: [],
    }
  },
  watch: {
    itemsDataTable: {
      handler () {
        this.items = this.itemsDataTable
      },
      deep: true
    },
    totalItemsDataTable: {
      handler () {
        this.totalItems = this.totalItemsDataTable;
      },
      deep: true
    },
    search: debounce(function (search) {
      this.$emit('searchDataTable', search)
    }, 1000),
    options: {
      handler () {
        this.$emit('paginateDataTable', this.options)
      },
      deep: true,
    },
    selected() {
      if(this.selected.length > 0)
        this.$emit('selectedItem', this.selected);
    },
  },
  methods: {
  }
}
</script>

<style>

</style>