/* eslint-disable no-unused-vars */
import Vue from 'vue'
import { formatCurrency } from '@/utils';
import { type_account_payment_status } from '@/constants/options';
import PaymentMethodSettingsService from '@/pages/Financial/PaymentMethodSettings/services/PaymentMethodSettingsService';

const mountParamsFilterEqualsPaymentMethodSettings = (value) => {
  const payload = {
    domain: 'payment_method_settings',
    search: '',
    ordering: {
      column: "id",
      value: "asc"
    },
    q: [{ 
      type: 'equals',
      column: 'payment_method_id',
      value,
    }]
  }
  const paginate = 'page=1&per_page=-1';

  return {
    payload,
    paginate,
  }
};

export default {
  beforeList: (formValue) => {
    let value_total = 0;
    let value_pending = 0;
    let value_paid = 0;

    formValue.data.forEach((item) => {
      value_total += parseFloat(item.value);
      if(item.status === type_account_payment_status[0].value){
        value_pending += parseFloat(item.value);
      }else{
        value_paid += parseFloat(item.value);
        item.disableShow = true ;
        item.disableDelete = true ;
      }
    });

    formValue.value_total = formatCurrency(value_total);
    formValue.value_pending = formatCurrency(value_pending);
    formValue.value_paid = formatCurrency(value_paid);
  },
  selectChange: (formValue, item, value, fields) => {
    if(item.name === 'payment_method_id') {
      const { payload, paginate } = mountParamsFilterEqualsPaymentMethodSettings(value);
      PaymentMethodSettingsService.filters(payload, paginate).then((res) => {
        Vue.set(formValue, 'bank_id', 0)
        if(res.data.data.length)
          Vue.set(formValue, 'bank_id', res.data.data[0].bank_id)
      }).catch((err) => {
        console.log(err)
      });
    }
  }
}