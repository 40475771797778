import DynamicService from '@/service/DynamicService';
import { formatCurrency, formatDatePT, getText } from '@/utils';
import { type_origin_boxes, movement_history_status } from '@/constants/options';

const formatResponse = (data) => {
  data.movement_date_formatted = formatDatePT(data.movement_date);
  data.value_formatted = formatCurrency(data.value);
  data.tax_formatted = formatCurrency(data.tax, 'percent');
  data.value_tax = parseFloat(data.value)-parseFloat(data.value_final);
  data.value_tax_formatted = formatCurrency(parseFloat(data.value)-parseFloat(data.value_final));
  data.value_final_formatted = formatCurrency(data.value_final);
  data.movement_histories_status_formatted = data.movement_histories_status;
  data.origin_type_formatted = `${getText(type_origin_boxes, data.origin_type)}: ${data.origin_id}`;
  data.movement_history_status_formatted = `${getText(movement_history_status, data.movement_history_status)}`;

  return data;
}


const MovementHistoryService = DynamicService('movement-histories', {
  formatResponse
});

export default MovementHistoryService ;