<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="900"
      class="dialog-custom"
    >
      <v-card class="">
        <v-form 
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-card-title>
            <h3>Variação: {{ variationSelected.description }}</h3>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <v-text-field
              outlined
              dense
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              hide-details>
            </v-text-field>

            <v-data-table
              item-key="id"
              :items-per-page="parseInt(7)"
              :id="'id'"
              :headers="headers"
              :items="items"
              :loading="false"
              :search="search"
              class="elevation-1 mt-3">

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon class="my-1" fab color="primary" x-small @click="openDialogEdit(item)">
                  <v-icon dark>{{ icons.edit }}</v-icon>
                </v-btn>
                
                <v-btn icon class="my-1" fab color="red" x-small @click="openDialogDestroy(item)">
                  <v-icon dark>{{ icons.destroy }}</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small dark color="red" @click="$emit('update:dialogItemVariation', false)">Fechar</v-btn>
            <v-btn small dark color="success" @click="dialogItem = true">Novo Item</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <DialogAddItemVariation
      :variation-id="variationSelected.id"
      :variation-description="variationSelected.description"
      :dialog="dialogItem"
      @update:dialogItem="dialogItem = $event"
      @changeList="getVariationValues" />

    <DialogEditItemVariation
      :variation-id="variationSelected.id"
      :variation-description="variationSelected.description"
      :item-variation-selected="itemVariationSelected"
      :dialog="dialogEdit"
      @update:dialogEdit="dialogEdit = $event"
      @changeList="getVariationValues" />

    <DialogDestroy
      message="Deseja Realmente Excluir este Item?"
      :dialog="dialogDestroy"
      :colorButtons="{noBtn: 'red', yesBtn: 'primary'}"
      :maxWidth="parseInt(500)"
      :loading="loading"
      @noAction="dialogDestroy = false"
      @yesAction="deleteItem" />
  </div>
</template>

<script>
import { ProductsCommands } from '../../../Products/services/ProductService';
import { messageErrors } from '@/utils';
import { destroy, edit, dotsVertical, databaseSearch, view } from '@/constants/icons';
import DialogAddItemVariation from './DialogAddItemVariation';
import DialogEditItemVariation from './DialogEditItemVariation';
import DialogDestroy from '@/components/DialogDestroy'

export default {
  name: 'DialogViewItemVariation',
  components: {
    DialogAddItemVariation,
    DialogDestroy,
    DialogEditItemVariation,
  },
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    variationSelected: {
      type: Object,
      default: () => { return {description: ''} }
    }
  },
  data() {
    return {
      valid: true,
      productsCommands: ProductsCommands(),
      dialogItem: false,
      dialogEdit: false,
      dialogDestroy: false,
      icons: {
        destroy,
        edit,
        dotsVertical,
        databaseSearch,
        view
      },
      headers: [
        {text: 'Descrição', value: 'description'},
        {text: 'Ações', value: 'actions', align: 'end'},
      ],
      items: [],
      form: {
        type: null,
        stock_id: null,
        qtd: 0,
        bar_code: '',
      },
      rules: [
        value => !!value || 'Obrigatório.',
      ],
      loading: false,
      itemVariationSelected: {},
      search: '',
    }
  },
  watch: {
    variationSelected: {
      handler() {
        this.getVariationValues();
      },
      deep: true
    }
  },
  methods: {
    getVariationValues() {
      this.productsCommands.showVariations(this.variationSelected.id).then((res) => {
        this.items = res.data.values;
      }).catch((err) => {
        this.$noty.error(messageErrors(err));
      });
    },
    openDialogEdit(item) {
      this.itemVariationSelected = item;
      this.dialogEdit = true;
    },
    openDialogDestroy(item) {
      this.itemVariationSelected = item;
      this.dialogDestroy = true;
    },
    deleteItem() {
      this.loading = true;
      this.productsCommands.destroyVariationValues(this.itemVariationSelected.id).then(() => {
        this.loading = false;
        this.$noty.success('Excluído com sucesso!');
        this.loading = false;
        this.dialogDestroy = false;
        this.getVariationValues();
      }).catch((err) => {
        this.$noty.error(messageErrors(err));
        this.loading = false;
        this.dialogDestroy = false;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
</style>