<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">

      <DynamicListPage
        ref="dynamicListPage"
        :schema="schema"
        :service="service"
        @moreActions="moreActions">

        <template slot="custom-header">
          <v-row class="mb-3">
            <v-col>
              <v-btn color="primary" small @click="dialogDisableNumbersNFe = true">
                Inutilizar NF-e
              </v-btn>

              <v-btn class="mx-2" color="primary" small @click="dialogDownloadXML = true">
                Download XML
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </DynamicListPage>

        <DialogSendNFe 
          :dialog="dialogSendNFe" />

        <DialogDisableNumbersNFe 
          :dialog="dialogDisableNumbersNFe"
          :loadingBtn="loadingBtn"
          @close="close"
          @disableNumbersNFe="disableNumbersNFe" />

        <DialogDownloadXML 
          :dialog="dialogDownloadXML"
          :loadingBtn="loadingBtn"
          @close="close"
          @downloadXML="downloadXML" />

        <DialogCancelNFe 
          :dialog="dialogCancelNFe"
          :loadingBtn="loadingBtn"
          :nfeSelected="nfeSelected"
          @close="close"
          @sendCancelNFe="sendCancelNFe" />

        <DialogCorrectionLetter 
          :dialog="dialogCorrectionLetter"
          :loadingBtn="loadingBtn"
          :nfeSelected="nfeSelected"
          @close="close"
          @sendCorrectionLetter="sendCorrectionLetter" />
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import InvoiceSchema from '../schemas/InvoiceSchema';
import InvoiceService from '../services/InvoiceService';
import DialogSendNFe from '../components/DialogSendNFe';
import DialogDisableNumbersNFe from '../components/DialogDisableNumbersNFe';
import DialogDownloadXML from '../components/DialogDownloadXML';
import DialogCancelNFe from '../components/DialogCancelNFe';
import DialogCorrectionLetter from '../components/DialogCorrectionLetter';
import { InvoicesCommands } from '../services/InvoiceService';
import PrintDanfeNFe from '@/mixins/PrintDanfeNFe'
import { messageErrors } from '@/utils';

export default {
  name: 'Invoices',
  components: {
    SimpleCard,
    DynamicListPage,
    DialogSendNFe,
    DialogDisableNumbersNFe,
    DialogCancelNFe,
    DialogCorrectionLetter,
    DialogDownloadXML,
  },
  data () {
    return {
      schema: InvoiceSchema,
      service: InvoiceService,
      serviceCommands: InvoicesCommands(),
      dialogSendNFe: false,
      dialogDisableNumbersNFe: false,
      dialogCancelNFe: false,
      dialogCorrectionLetter: false,
      dialogDownloadXML: false,
      loadingBtn: false,
      nfeSelected: {}
    }
  },
  mixins: [PrintDanfeNFe],
  methods: {
    moreActions(value) {
      this[value.action](value.item);
    },
    sendNFe(item) {
      this.dialogSendNFe = true;
      const params = {
        id: item.id
      };

      this.serviceCommands.sendNFe(params).then((res) => {
        this.dialogSendNFe = false;

        if(res.data.cStat != 100){
          this.$noty.error(res.data.xMotivo);
          return;
        }

        this.$noty.success('NFe enviada com sucesso!');
        this.$refs.dynamicListPage.getDataFromApi();
      }).catch((err) => {
        this.$noty.error(messageErrors(err.response));
        this.dialogSendNFe = false;
      });
    },
    printDANFE(item) {
      this.serviceCommands.getBarCode({value: item.chNFe, type: 'C128'}).then((res) => {
        this.print(item, res.data.url_image);
      }).catch((err) => {
        this.$noty.error(messageErrors(err.response));
      });
    },
    disableNumbersNFe(params) {
      this.loadingBtn = true;
      this.serviceCommands.disableNumberNFe(params).then((res) => {
        this.dialogDisableNumbersNFe = false;
        this.loadingBtn = false;

        if(res.data.cStat != 102){
          this.$noty.error(res.data.xMotivo);
          return;
        }

        this.$noty.success('Números inutilizados com sucesso!');
      }).catch((err) => {
        this.$noty.error(messageErrors(err.response));
        this.dialogDisableNumbersNFe = false;
        this.loadingBtn = false;
      });
    },
    close(dialog) {
      this[dialog] = false;
    },
    cancelNFe(item) {
      this.nfeSelected = item;
      this.dialogCancelNFe = true;
    },
    correctionLetter(item) {
      this.nfeSelected = item;
      this.dialogCorrectionLetter = true;
    },
    sendCancelNFe(params) {
      this.loadingBtn = true;

      const payload = {
        invoice_id: this.nfeSelected.id,
        justification: params.justification,
      }

      this.serviceCommands.cancelNFe(payload).then((res) => {
        this.dialogCancelNFe = false;
        this.loadingBtn = false;

        if(res.data.cStat != 101 && res.data.cStat != 135){
          this.$noty.error(res.data.xReason);
          return;
        }

        this.$noty.success(res.data.xReason);
        this.$refs.dynamicListPage.getDataFromApi();
      }).catch((err) => {
        this.$noty.error(messageErrors(err.response));
        this.dialogCancelNFe = false;
        this.loadingBtn = false;
      });
    },
    sendCorrectionLetter(params) {
      this.loadingBtn = true;

      const payload = {
        invoice_id: this.nfeSelected.id,
        justification: params.justification,
      }

      this.serviceCommands.correctionLetter(payload).then((res) => {
        this.dialogCorrectionLetter = false;
        this.loadingBtn = false;

        if(res.data.cStat != 101 && res.data.cStat != 135){
          this.$noty.error(res.data.xReason);
          return;
        }

        this.$noty.success(res.data.xReason);
        this.$refs.dynamicListPage.getDataFromApi();
      }).catch((err) => {
        this.$noty.error(messageErrors(err.response));
        this.dialogCorrectionLetter = false;
        this.loadingBtn = false;
      });
    },
    downloadXML(form) {
      const date_arr = form.date.split('-')
      const year = date_arr[0];
      const month = date_arr[1];
      this.loadingBtn = true;
      
      this.serviceCommands.downloadNFe({month, year}).then((res) => {
        location.href = res.data.url_download;
        this.loadingBtn = false;
        this.dialogDownloadXML = false;
      }).catch((err) => {
        this.loadingBtn = false;
        this.dialogDownloadXML = false;
        this.$noty.error(messageErrors(err.response));
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
