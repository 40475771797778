<template>
  <div>
    <SimpleCard
      :schema="schema">
      <v-form ref="form" v-model="validForm" lazy-validation>
        <v-row>
          <v-col>
            <div class="sale-order-header mt-2">
              <div class="d-flex">
                <h4 class="mx-2">Qtd Itens: {{ totalizers.qtdItems }}</h4>
                <h4 class="mx-2">Qtd Volumes: {{ totalizers.qtdVolumes }}</h4>
                <h4 class="mx-2">Valor Total: {{ utils.formatCurrency(totalizers.valueTotal) }}</h4>
                <h4 class="mx-2">Desconto: {{ utils.formatCurrency(totalizers.valueDiscount) }}</h4>
                <h4 class="mx-2">Valor Final:  {{ utils.formatCurrency(totalizers.valueFinal) }}</h4>
                <h4 class="mx-2">Total Pago:  {{ utils.formatCurrency(totalizers.valuePay) }}</h4>
                <h4 class="mx-2">Falta Pagar:  {{ utils.formatCurrency(totalizers.lackPay) }}</h4>
              </div>
            </div>
          </v-col>
        </v-row>

        <GeneralData
          :stocks="stocks"
          :stock-selected="stockSelected"
          :sale-order="saleOrder"
          :sale-order-finished="saleOrderFinished()"
          @changeDataPick="changeDataPick"
          @setGeneralData="setGeneralData" />

        <v-card class="mt-3">
          <v-card-title>
            <h3>Itens</h3>
          </v-card-title>

          <v-card-text>
            <div class="d-flex justify-end">
              <div class="text-bar-code">
                <TextField
                  ref="searchBarCode"
                  v-model="bar_code"
                  label="Código de Barras"
                  :disabled="saleOrderFinished()"
                  @keydown.enter="searchBarCode()" />
              </div>
              <v-btn
              class="mt-2"
              rounded
              small
              color="primary"
              :disabled="saleOrderFinished()"
              @click="dialogItem = true">
                <v-icon small class="mr-2">{{ icons.plus }}</v-icon>
                Novo Item
              </v-btn>
            </div>

            <v-data-table
              item-key="id"
              :headers="headersItems"
              :items="saleOrder.items"
              :hide-default-footer="true"
              :disable-pagination="true"
              dense
              class="elevation-1 mt-3"
            >
              <template v-slot:[`item.qtd`]="props">
                <div class="text-value-single">
                  <vuetify-money
                    v-if="true"
                    v-model="props.item.qtd"
                    dense
                    :outlined="false"
                    :options="decimal_options"
                    :readonly="!props.item.newItem"
                    @input="changeQtd(props.index, $event)" />
                    <p v-else>{{ props.item.qtd }}</p>
                </div>
              </template>

              <template v-slot:[`item.value_single_formatted`]="props">
                <div class="text-value-single">
                  <vuetify-money
                    v-if="true"
                    v-model="props.item.value_single"
                    dense
                    :outlined="false"
                    :options="money_options"
                    :readonly="!props.item.newItem"
                    @input="changeValueSingle(props.index, $event)" />
                    <p v-else>{{ props.item.value_single_formatted }}</p>
                </div>
              </template>

              <template v-slot:[`item.delete`]="props">
                <v-btn
                  class="mx-1 my-1" icon
                  fab
                  color="red"
                  x-small
                  :disabled="saleOrderFinished()"
                  @click="removeItem(props.index)">
                  <v-icon dark>{{ icons.delete }}</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-card class="mt-3" v-if="saleOrder.payments.length">
          <v-card-title>
            <h3>Pagamentos</h3>
          </v-card-title>
          <v-card-text>
            <v-data-table
              item-key="id"
              :headers="headersPayments"
              :items="saleOrder.payments"
              :hide-default-footer="true"
              :disable-pagination="true"
              dense
              class="elevation-1 mt-3" />
          </v-card-text>
        </v-card>

        <v-card class="mt-3">
          <v-card-title>
            <h3>Desconto e Observações</h3>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col md="2">
                <vuetify-money
                  label="Desconto"
                  v-model="discount"
                  dense
                  outlined
                  :readonly="saleOrderFinished()"
                  :options="money_options" />
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col>
                <v-textarea
                  v-model="observations"
                  outlined
                  dense
                  label="Observações"
                  :readonly="saleOrderFinished()" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  small
                  rounded
                  color="primary"
                  :disabled="!saleOrder.items.length || saleOrderFinished()"
                  @click="dialogSave = true">
                  Salvar
                </v-btn>
                <v-btn
                  small
                  rounded
                  color="success"
                  class="ml-3"
                  :disabled="!saleOrder.items.length || saleOrderFinished()"
                  @click="dialogPayments = true">
                  Pagamento
                </v-btn>
                <v-btn
                  small
                  rounded
                  color="secondary"
                  class="ml-3"
                  :disabled="!saleOrder.items.length || (totalizers.valuePay <= totalizers.lackPay) || saleOrderFinished()"
                  @click="dialogFinish = true">
                  Finalizar Pedido
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </SimpleCard>

    <div>
      <DialogItem
        ref="dialogItem"
        :dialog="dialogItem"
        :type-order="saleOrder.typeSale"
        :stock-default-id="stock_default_id"
        @action="actionDialog"
        @close="dialogItem = false" />

      <DialogDestroy
        message='Deseja Realmente Salvar o Pedido de Venda?'
        :dialog="dialogSave"
        :color-buttons="{noBtn: '', yesBtn: 'primary'}"
        :loading="loadingSave"
        :max-width="560"
        @noAction="dialogSave = false, loadingSave = false"
        @yesAction="saveOrder" />

      <DialogDestroy
        message='Deseja Realmente Finalizar o Pedido de Venda?'
        :dialog="dialogFinish"
        :color-buttons="{noBtn: '', yesBtn: 'primary'}"
        :loading="loadingFinish"
        :max-width="570"
        @noAction="dialogFinish = false, loadingFinish = false"
        @yesAction="finishOrder" />

      <DialogPayments
        ref="dialogPayments"
        :dialog="dialogPayments"
        :totalizers="totalizers"
        :payments="saleOrder.payments"
        :sale-order-is-finished="false"
        @mountPayment="mountPayment"
        @closeDialogPayments="dialogPayments = false" />
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import { formatCurrency } from '@/utils';
import { sale_order_status, yes_no } from '@/constants/options';
import { rulesRequired } from '@/constants/masks';
import { plus, destroy } from '@/constants/icons';
import SimpleCard from '@/components/SimpleCard';
import DialogItem from '../components/DialogItem';
import TypeComponents from '@/mixins/TypeComponents'
import GetCompanyPreferences from '@/mixins/GetCompanyPreferences';
import SaleOrderSchema from '../schemas/SaleOrderSchema';
import DialogDestroy from '@/components/DialogDestroy';
import SaleOrderService from '../services/SaleOrderService'
import DialogPayments from '@/components/DialogPayments'
import RegisterService from '@/pages/Registers/services/RegisterService';
import StockService from '@/pages/Stock/TypeStock/services/StockService';
import TextField from '@/components/vuetify/TextField';
import { ProductsCommands } from '@/pages/Stock/Products/services/ProductService';
import GeneralData from '../components/GeneralData';

export default {
  name: 'CreateSaleOrder',
  components: {
    SimpleCard,
    DialogItem,
    DialogDestroy,
    DialogPayments,
    TextField,
    GeneralData,
  },
  data () {
    return {
      validForm: true,
      icons: {
        plus: plus,
        delete: destroy,
      },
      utils: {
        formatCurrency: formatCurrency,
      },
      rules: {
        required: rulesRequired,
      },
      schema: SaleOrderSchema,
      service: SaleOrderService,
      registerService: RegisterService,
      stockService: StockService,
      saleOrder: {
        orderDate: new Date().toISOString().substr(0, 10),
        exitDate: new Date().toISOString().substr(0, 10),
        typeSale: 'retail',
        customer: {},
        seller: {},
        removedItems: [],
        items: [],
        totalizers: {},
        observations: '',
        payments: [],
        finished: false,
      },
      dialogItem: false,
      dialogSave: false,
      loadingSave: false,
      dialogFinish: false,
      loadingFinish: false,
      dialogPayments: false,
      headersItems: [
        { text: 'Nº Item', value: 'item_number', },
        { text: 'Produto', value: 'name', },
        { text: 'Qtd', value: 'qtd', },
        { text: 'Valor Uni.', value: 'value_single_formatted', },
        { text: 'Valor Total', value: 'value_total_formatted', },
        { text: 'Excluir', value: 'delete', },
      ],
      headersPayments: [
        {text: 'Data', value: 'payment_date_formatted'},
        {text: 'Forma de Pagamento', value: 'payment_method'},
        {text: 'Bandeira', value: 'flag'},
        {text: 'Valor Pago', value: 'payment_value_formatted'},
      ],
      discount: 0,
      observations: '',
      totalizers: {
        qtdItems: 0,
        qtdVolumes: 0,
        valueTotal: 0,
        valueDiscount: 0,
        valueFinal: 0,
        valuePay: 0,
        lackPay: 0,
      },
      money_options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2
      },
      decimal_options: {
        locale: "pt-BR",
        prefix: "",
        suffix: "",
        length: 11,
        precision: 2
      },
      customer_default: {},
      seller_default: {},
      stock_default_id: 0,
      bar_code: '',
      stocks: [],
      stockSelected: {}
    }
  },
  watch: {
    discount: {
      handler() {
        this.totalizers.valueDiscount = parseFloat(this.discount);
        this.calculateTotalizers();
      },
      deep: true,
    },
    observations: {
      handler() {
        this.saleOrder.observations = this.observations;
      },
      deep: true,
    },
  },
  mounted() {
    this.getCompanyPreferences(['sale_order']);
    this.getStocks();
    if(this.componentShow)
      this.getSaleOrder()
  },
  mixins: [TypeComponents, GetCompanyPreferences],
  methods: {
    setCustomerDefault(id) {
      this.registerService.show(id).then((res) => {
        this.customer_default = { id: res.id, name: res.name };
        this.setCustomer(res);
      }).catch(() => {
      })
    },
    setSellerDefault(id) {
      this.registerService.show(id).then((res) => {
        this.seller_default = { id: res.id, name: res.name };
        this.setSeller(res);
      }).catch(() => {
      })
    },
    setStockDefault(id) {
      this.stockService.show(id).then((res) => {
        this.stock_default_id = res.id;
      }).catch(() => {
      })
    },
    changeDataPick(data) {
      this.saleOrder[data.model] = data.date;
    },
    actionDialog(data) {
      this[data.action](data.params);
      this[data.close] = false
    },
    addItem(params) {
      const { item, newItem, searchBarCode } = _.cloneDeep(params);

      if(newItem && !searchBarCode)
        this.$refs.dialogItem.resetItem();

      this.saleOrder.items.push({
        id: item.id,
        product_id: item.product_id,
        product_variation_id: item.product_variation_id,
        stock_id: item.stock_id,
        item_number: this.getNumberItem(),
        name: item.name,
        qtd: item.qtd,
        value_single: item.price_unity,
        value_single_formatted: formatCurrency(item.price_unity),
        value_total: item.total_value,
        value_total_formatted: formatCurrency(item.total_value),
        newItem
      })

      this.calculateTotalizers();
    },
    removeItem(item) {
      this.saleOrder.removedItems.push(this.saleOrder.items[item]);
      this.saleOrder.items.splice(item, 1);

      this.saleOrder.items.map((item, index) => {
        item.item_number = index + 1;
        return item;
      });
      this.calculateTotalizers();
    },
    changeQtd(index, qtd){
      this.saleOrder.items[index].value_total = qtd * this.saleOrder.items[index].value_single;
      this.saleOrder.items[index].value_total_formatted = formatCurrency(parseFloat(this.saleOrder.items[index].value_total));
      this.calculateTotalizers();
    },
    changeValueSingle(index, value){
      this.saleOrder.items[index].value_total = value * this.saleOrder.items[index].qtd;
      this.saleOrder.items[index].value_total_formatted = formatCurrency(parseFloat(this.saleOrder.items[index].value_total));
      this.calculateTotalizers();
    },
    calculateTotalizers() {
      this.resetTotalizers();
      this.saleOrder.items.forEach((item) => {
        this.totalizers.qtdItems++;
        this.totalizers.qtdVolumes += parseFloat(item.qtd);
        this.totalizers.valueTotal += parseFloat(item.value_total);
      });

      this.totalizers.valueFinal = parseFloat(this.totalizers.valueTotal)-parseFloat(this.totalizers.valueDiscount);
      this.totalizers.lackPay = parseFloat(this.totalizers.valueFinal)-parseFloat(this.totalizers.valuePay);
      this.saleOrder.totalizers = this.totalizers;
    },
    resetTotalizers() {
      this.totalizers.qtdItems = 0;
      this.totalizers.qtdVolumes = 0;
      this.totalizers.valueTotal = 0;
      this.totalizers.valueFinal = 0;
    },
    getNumberItem() {
      return parseFloat(this.saleOrder.items.length + 1);
    },
    getSaleOrder() {
      const { id } = this.$route.params;

      this.service.show(id).then((res) => {
        this.saleOrder.orderDate = res.order_date;
        this.saleOrder.exitDate = res.delivery_date;
        this.saleOrder.typeSale = res.type_sale;
        this.discount = res.value_discount;
        this.observations = res.observations;
        this.saleOrder.sale_order_status = res.sale_order_status;

        res.items.forEach((item) => {
          this.changeItems(item);
        });

        res.payments.forEach((item) => {
          this.totalizers.valuePay += parseFloat(item.value_final);
          this.saleOrder.payments.push({...item, new: false});
        });

        this.$refs.dialogPayments.changePayments(this.saleOrder.payments);
        this.setCustomer(res.customer);
        this.setSeller(res.employee);
      }).catch((err) => {
        console.log(err)
      });
    },
    saleOrderFinished() {
      return this.saleOrder.sale_order_status == sale_order_status[1].value || false;
    },
    setCustomer(customer) {
      this.saleOrder.customer = {id: customer.id, text: customer.name};
    },
    setSeller(seller) {
      this.saleOrder.seller = {id: seller.id, text: seller.name};
    },
    setGeneralData(data) {
      const { type, value } = data;
      this.saleOrder[type] = value;
      if (type === 'stockSelected') this[type] = value;
    },
    saveOrder() {
      if(!this.$refs.form.validate())
        return;

      this.loadingSave = true;
      if(this.componentCreate) {
        this.createOrder();
      }else{
        this.updateOrder();
      }
    },
    createOrder() {
      this.service.create(this.saleOrder).then(() => {
        this.$noty.success("Pedido Concluído com Sucesso.");
        this.loadingSave = false;
        this.dialogSave = false;
        this.$router.push({name: this.schema.routes.list.name});
      }).catch((err) => {
        this.loadingSave = false;
        this.dialogSave = false;
        this.$noty.error(err);
      });
    },
    updateOrder() {
      const { id } = this.$route.params;
      this.service.update(id, this.saleOrder).then(() => {
        this.$noty.success("Pedido Atualizado com Sucesso");
        this.loadingSave = false;
        this.dialogSave = false;
        this.$router.push({name: this.schema.routes.list.name});
      }).catch((err) => {
        this.loadingSave = false;
        this.dialogSave = false;
        this.$noty.error(err);
      });
    },
    mountPayment(params) {
      this.dialogPayments = false
      this.saleOrder.payments = params.payments;
      this.calculatePayments();
    },
    finishOrder() {
      this.loadingFinish = true;
      this.saleOrder.finished = true;
      this.saveOrder();
    },
    calculatePayments() {
      let valueTotalPaid = 0;

      this.saleOrder.payments.forEach((item) => {
        valueTotalPaid += parseFloat(item.value_final);
      });

      this.totalizers.valuePay = valueTotalPaid;
      this.calculateTotalizers();
    },
    changeItems(item) {
      const param = {
        id: item.id,
        product_id: item.product.id,
        product_variation_id: item.product_variation_id,
        stock_id: item.product_variation_stock.stock_id,
        item_number: item.item_number,
        name: item.name,
        qtd: item.qtd,
        price_unity: item.value_single,
        total_value: item.value_total,
      }
      this.addItem({item: param, newItem: false});
    },
    searchBarCode() {
      if(!this.bar_code)
        return;

      if(Object.keys(this.stockSelected).length === 0) {
        this.$noty.error('Selecione o Estoque!');
        return;
      }

      ProductsCommands().getProductVariationByBarCode(this.bar_code).then((res) => {
        const { data } = res.data;

        if(data.length === 0) {
          this.$noty.error('Produto não localizado');
          this.bar_code = '';
          return;
        }else if(data.length > 1) {
          this.$noty.error('Código de barras duplicado');
          this.bar_code = '';
          return;
        }

        const price_unity = this.typeOrder === 'retail' ? data[0].price_var : data[0].price_ata;
        const item = {
          id: null,
          stock_id: this.stockSelected.id,
          product_id: data[0].product_id,
          product_variation_id: data[0].id,
          name: data[0].product.name,
          price_unity: price_unity,
          total_value: price_unity,
          qtd: 1,
        }
        this.bar_code = '';
        this.addItem({ item, newItem: true, searchBarCode: true })

      }).catch((err) => {
        console.error(err);
        this.$noty.error('Erro ao localizar o Produto.');
      })
    },
    getStocks() {
      this.productStocks = [];
      this.stockService.index().then((res) => {
        res.data.data.forEach((item) => {
          this.stocks.push({
            id: item.id,
            text: item.description
          });

          if(item.default === yes_no[0].value) {
            this.stockSelected = { id: item.id, text: item.description }
          }
        })
      }).catch(() => {
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
