import Products from '@/business/Products';
import { dataRegisters, databaseSearch, view, plus } from '@/constants/icons';

const money_options = {
  locale: "pt-BR",
  prefix: "R$",
  suffix: "",
  length: 11,
  precision: 2
}

export default {
  domain: 'products',
  title: 'Produtos',
  business: Products,
  description: 'Neste tela você pode visualizar todos os produtos cadastrados no sistema',
  singleSelect: true,
  icon: 'mdi-database',
  routes: {
    list: {
      name: 'products',
      path: '/produtos',
    },
    create: {
      name: 'product-create',
      path: '/produtos/novo',
      children: []
    },
    show: {
      name: 'product-show',
      path: '/produtos/:id',
    },
  },
  noBetween: true,
  noEquals: true,
  show: true,
  destroy: true,
  moreActions: [
    {
      name: 'viewStock',
      label: 'Ver Estoque',
      icon: view,
      action: 'viewStock',
    },
    {
      name: 'addStock',
      label: 'Adicionar/Remover Estoque',
      icon: databaseSearch,
      action: 'addStock',
    },
    {
      name: 'viewVariations',
      label: 'Variações',
      icon: plus,
      action: 'viewVariations',
    },
  ],
  create: true,
  noSearch: false,
  searchCol: 4,
  fields: [
    {
      icon: dataRegisters,
      title: 'Dados do Produto',
      items: [
        {
          id: 'code',
          type: 'text',
          name: 'code',
          formattedName: 'code_formatted',
          label: 'Código',
          align: '',
          md: '1',
          list: true,
        },
        {
          id: 'variations_id',
          type: 'select',
          name: 'product_variation_type',
          formattedName: 'product_variation_type',
          label: 'Tipo',
          item_id: 'value',
          item_text: 'text',
          align: '',
          md: '2',
          list: false,
          required: true,
          selectChange: true,
          defaultValue: 'single'
        },
        {
          id: 'stock_id',
          type: 'select',
          name: 'stock_id',
          formattedName: 'stock.description',
          label: 'Tipo Estoque',
          item_id: 'id',
          item_text: 'description',
          align: '',
          md: '2',
          list: false,
          required: true,
        },
        {
          id: 'unit_measure_id',
          type: 'select',
          name: 'unit_measure_id',
          formattedName: 'unit_measures.description',
          label: 'Unidade de Medida',
          item_id: 'id',
          item_text: 'description',
          align: '',
          md: '2',
          list: false,
          required: true,
        },
        {
          id: 'provider_id',
          type: 'select',
          name: 'provider_id',
          formattedName: 'provider.name',
          label: 'Fornecedor',
          item_id: 'id',
          item_text: 'name',
          align: '',
          md: '3',
          list: false,
        },
        {
          id: 'category_id',
          type: 'select',
          name: 'category_id',
          formattedName: 'categories.description',
          label: 'Categoria',
          item_id: 'id',
          item_text: 'description',
          align: '',
          md: '2',
          list: true,
        },
        {
          id: 'subcategory_id',
          type: 'select',
          name: 'subcategory_id',
          formattedName: 'subcategories.description',
          label: 'Sub Categoria',
          item_id: 'id',
          item_text: 'description',
          align: '',
          md: '3',
          list: true,
        },
        {
          id: 'name',
          type: 'text',
          name: 'name',
          label: 'Nome',
          md: '4',
          required: true,
          list: true,
        },
        {
          id: 'reference',
          type: 'text',
          name: 'reference',
          formattedName: 'reference_formatted',
          label: 'Referência',
          md: '3',
          list: true,
        },
        {
          id: 'bar_code',
          type: 'text',
          name: 'bar_code',
          formattedName: 'bar_code_formatted',
          label: 'Cod. Barras',
          md: '2',
          list: true,
          counter: 13,
          mask: '#############',
          ref: 'bar_code'
        },
        {
          id: 'price_cost',
          type: 'money',
          name: 'price_cost',
          formattedName: 'price_cost_formatted',
          label: 'Preço Custo',
          money_options,
          md: '2',
          list: false,
          keyUp: true,
        },
        {
          id: 'price_var',
          type: 'money',
          name: 'price_var',
          formattedName: 'price_var_formatted',
          label: 'Preço Varejo',
          money_options,
          md: '2',
          required: true,
          list: true,
          keyUp: true,
        },
        {
          id: 'price_var_profit',
          type: 'money',
          name: 'price_var_profit',
          formattedName: 'price_var_profit_formatted',
          label: 'Lucro Varejo',
          money_options: {
            locale: "pt-BR",
            length: 11,
            precision: 2,
            suffix: "%",
          },
          md: '2',
          required: false,
          list: false,
          keyUp: true,
        },
        {
          id: 'price_ata',
          type: 'money',
          name: 'price_ata',
          formattedName: 'price_ata_formatted',
          label: 'Preço Atacado',
          money_options,
          md: '2',
          list: true,
          keyUp: true,
        },
        {
          id: 'price_ata_profit',
          type: 'money',
          name: 'price_ata_profit',
          formattedName: 'price_ata_profit_formatted',
          label: 'Lucro Atacado',
          money_options: {
            locale: "pt-BR",
            length: 11,
            precision: 2,
            suffix: "%",
          },
          md: '2',
          required: false,
          list: false,
          keyUp: true,
        },
        {
          id: 'ncm',
          type: 'text',
          name: 'ncm',
          formattedName: 'ncm_formatted',
          label: 'NCM',
          money_options,
          md: '2',
          list: false,
          required: true,
          counter: 8,
          mask: '########',
        },
        {
          id: 'cest',
          type: 'text',
          name: 'cest',
          formattedName: 'cest_formatted',
          label: 'CEST',
          money_options,
          md: '2',
          list: false,
          counter: 7,
          mask: '#######',
        },
        {
          id: 'weight',
          type: 'money',
          name: 'weight',
          formattedName: 'weight',
          label: 'Peso',
          money_options: {
            locale: "pt-BR",
            length: 11,
            precision: 3
          },
          md: '2',
          list: false,
        },
        {
          id: 'sku',
          type: 'text',
          name: 'sku',
          formattedName: 'sku',
          label: 'SKU',
          md: '2',
          list: false,
        },
        {
          id: 'qtd_stock',
          type: 'money',
          name: 'qtd_stock',
          formattedName: 'qtd_stock',
          label: 'Qtd',
          money_options: {
            locale: "pt-BR",
            length: 11,
            precision: 3
          },
          md: '2',
          list: true,
          required: true,
          noFormUpdate: true,
        },
      ]
    },
  ]
}