import { formatCurrency } from '@/utils';

export default {
  beforeList: (formValue) => {
    let total_inputs = 0;
    let total_outputs = 0;

    formValue.data.forEach((item) => {
      if(parseFloat(item.qtd) >= 0){
        total_inputs += parseFloat(item.qtd);
      }

      if(parseFloat(item.qtd) < 0){
        total_outputs += parseFloat(item.qtd);
      }
    });

    formValue.total_inputs = formatCurrency(total_inputs, {type: 'decimal', precision: 4});
    formValue.total_outputs = formatCurrency(total_outputs, {type: 'decimal', precision: 4});
  }
}