const money_options_tax = {
  locale: "pt-BR",
  prefix: "",
  suffix: "",
  length: 11,
  precision: 2
}

export default {
  domain: 'payment_method_settings',
  title: 'Configurações de Pagamento',
  description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
  singleSelect: true,
  customSearch: true,
  routes: {
    list: {
      name: 'paymentmethodsettings',
      path: '/configuracoes-pagamentos',
    },
    create: {
      name: 'paymentmethodsettings-create',
      path: '/configuracoes-pagamentos/novo',
    },
    show: {
      name: 'paymentmethodsettings-show',
      path: '/configuracoes-pagamentos/:id',
    }
  },
  show: true,
  destroy: true,
  create: true,
  noBetween: true,
  noEquals: true,
  searchCol: 4,
  fields: [
    {
      id: 'payment_method_id',
      type: 'select',
      name: 'payment_method_id',
      formattedName: 'payment_method.description',
      label: 'Forma de Pagamento',
      item_id: 'id',
      item_text: 'description',
      align: '',
      md: '4',
      list: true,
    },
    {
      id: 'tax',
      type: 'money',
      name: 'tax',
      formattedName: 'tax_formatted',
      label: 'Taxa',
      md: '4',
      money_options_tax,
      required: true,
      list: true,
    },
    {
      id: 'bank_id',
      type: 'select',
      name: 'bank_id',
      formattedName: 'bank.description',
      label: 'Banco Padrão',
      item_id: 'id',
      item_text: 'description',
      align: '',
      md: '4',
      list: true,
    },
    {
      id: 'bank.agency_number',
      type: 'text',
      name: 'bank.agency_number',
      label: 'Nº Agência',
      align: '',
      md: '3',
      list: true,
      noForm: true,
    },
    {
      id: 'bank.account_number',
      type: 'text',
      name: 'bank.account_number',
      label: 'Nº Conta',
      md: '3',
      required: true,
      list: true,
      noForm: true,
    },
  ],
  footer: []
}