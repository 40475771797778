import ServiceSchema from './schemas/ServiceSchema';
import Services from './pages/Services';
import ServiceForm from './pages/ServiceForm';

export default [
  {
    path: ServiceSchema.routes.list.path,
    name: ServiceSchema.routes.list.name,
    component: Services,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: ServiceSchema.routes.create.path,
    name: ServiceSchema.routes.create.name,
    component: ServiceForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: ServiceSchema.routes.show.path,
    name: ServiceSchema.routes.show.name,
    component: ServiceForm,
    meta:{
      requiresAuth: true,
    }
  },
]