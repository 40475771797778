import { dataRegisters } from '@/constants/icons';

export default {
  domain: 'store_front',
  title: 'Frente de Loja',
  description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
  singleSelect: true,
  customSearch: true,
  routes: {
    list: {
      name: 'storefront',
      path: '/frente-loja',
    },
    create: {
      name: 'bank-create',
      path: '/bancos/novo',
    },
    show: {
      name: 'bank-show',
      path: '/bancos/:id',
    }
  },
  show: true,
  destroy: true,
  create: true,
  noBetween: true,
  noEquals: true,
  searchCol: 4,
  fields: [
    {
      icon: dataRegisters,
      title: 'Dados Cadastrais',
      items: [
        {
          type: 'text',
          name: 'created_at',
          formattedName: 'created_at_formatted',
          label: 'Data Criação',
          md: '3',
          required: true,
          noFilter: false,
          list: true,
          noForm: true,
        },
      ],
    },
  ],
  footer: [
    {
      name: 'balance_total',
      label: 'Saldo Total',
      class: 'custom-footer-value',
      md: 2,
    }
  ]
}