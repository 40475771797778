import BoxSchema from './schemas/BoxSchema';
import Box from './pages/Box';

export default [
  {
    path: BoxSchema.routes.list.path,
    name: BoxSchema.routes.list.name,
    component: Box,
    meta:{
      requiresAuth: true,
    }
  },
]