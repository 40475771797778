import Invoices from '@/business/Invoices';
import { note, dataRegisters,  } from '@/constants/icons';
// address, security
export default {
  domain: 'invoices',
  business: Invoices,
  title: 'Notas Fiscais',
  icon: note,
  description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
  singleSelect: true,
  customSearch: true,
  routes: {
    list: {
      name: 'invoices',
      path: '/notas',
    },
    create: {
      name: 'invoice-create',
      path: '/notas/novo',
    },
    show: {
      name: 'invoice-show',
      path: '/notas/:id',
    }
  },
  show: true,
  destroy: true,
  readOnlyGlobal: true,
  moreActions: [
    {
      name: 'sendNFe',
      label: 'Enviar NFe',
      icon: note,
      action: 'sendNFe',
    },
    {
      name: 'printDANFE',
      label: 'Imprimir DANFE',
      icon: note,
      action: 'printDANFE'
    },
    {
      name: 'correctionLetter',
      label: 'Carta Correção',
      icon: note,
      action: 'correctionLetter'
    },
    {
      name: 'cancelNFe',
      label: 'Cancelar NFe',
      icon: note,
      action: 'cancelNFe'
    },
    {
      name: 'clone',
      label: 'Clonar NFe',
      icon: note,
      action: 'clone'
    },
    {
      name: 'download',
      label: 'Download XML',
      icon: note,
      action: 'downloadNFe'
    },
    {
      name: 'sendEmail',
      label: 'Enviar por E-mail',
      icon: note,
      action: 'sendEmail'
    }
  ],
  create: true,
  noBetween: true,
  noEquals: true,
  searchCol: 4,
  fields: [
    {
      icon: dataRegisters,
      title: 'Emitente',
      items: [
        {
          id: 'emitter.xNome',
          type: 'text',
          name: 'emitter.xNome',
          formattedName: 'emitter.xNome',
          label: 'Razão Social',
          align: '',
          md: '4',
          list: false,
        },
        {
          id: 'emitter.CNPJ',
          type: 'text',
          name: 'emitter.CNPJ',
          formattedName: 'emitter.CNPJ',
          label: 'CNPJ',
          align: '',
          md: '2',
          list: false,
        },
        {
          id: 'emitter.IE',
          type: 'text',
          name: 'emitter.IE',
          formattedName: 'emitter.IE',
          label: 'Inscrição Estadual',
          align: '',
          md: '2',
          list: false,
        },
        {
          id: 'emitter.xLgr',
          type: 'text',
          name: 'emitter.xLgr',
          formattedName: 'emitter.xLgr',
          label: 'Endereço',
          align: '',
          md: '4',
          list: false,
        },
        {
          id: 'emitter.xBairro',
          type: 'text',
          name: 'emitter.xBairro',
          formattedName: 'emitter.xBairro',
          label: 'Bairro',
          align: '',
          md: '3',
          list: false,
        },
        {
          id: 'emitter.nro',
          type: 'text',
          name: 'emitter.nro',
          formattedName: 'emitter.nro',
          label: 'Nº',
          align: '',
          md: '2',
          list: false,
        },
        {
          id: 'emitter.CEP',
          type: 'text',
          name: 'emitter.CEP',
          formattedName: 'emitter.CEP',
          label: 'CEP',
          align: '',
          md: '2',
          list: false,
        },
        {
          id: 'created_at',
          type: 'text',
          name: 'created_at',
          formattedName: 'created_at_formatted',
          label: 'Criação',
          align: '',
          md: '4',
          list: true,
          noForm: true,
        },
        {
          id: 'dhEmi',
          type: 'text',
          name: 'dhEmi',
          formattedName: 'dhEmi_formatted',
          label: 'Emissão',
          align: '',
          md: '4',
          list: true,
          noForm: true,
        },
        {
          id: 'tpAmb',
          type: 'text',
          name: 'recitpAmbpient',
          formattedName: 'tpAmb_formatted',
          label: 'Ambiente',
          align: '',
          md: '4',
          list: true,
          noForm: true,
        },
        {
          id: 'recipient',
          type: 'text',
          name: 'recipient',
          formattedName: 'xNome_formatted',
          label: 'Cliente',
          align: '',
          md: '4',
          list: true,
          noForm: true,
        },
        {
          id: 'nNF',
          type: 'text',
          name: 'nNF',
          formattedName: 'nNF_formatted',
          label: 'Nº NFe',
          align: '',
          md: '4',
          list: true,
          noForm: true,
        },
        {
          id: 'chNFe',
          type: 'text',
          name: 'chNFe',
          formattedName: 'chNFe_formatted',
          label: 'Chave',
          align: '',
          md: '2',
          list: true,
          noForm: true,
        },
        {
          id: 'vNF',
          type: 'text',
          name: 'vNF',
          formattedName: 'vNF_formatted',
          label: 'Valor',
          align: '',
          md: '2',
          list: true,
          noForm: true,
        },
        {
          id: 'cStat',
          type: 'text',
          name: 'cStat',
          formattedName: 'cStat',
          label: 'Status',
          md: '2',
          required: true,
          list: true,
          noForm: true,
        },
        {
          id: 'xMotivo',
          type: 'text',
          name: 'xMotivo',
          formattedName: 'xMotivo_formatted',
          label: 'Motivo',
          align: '',
          md: '2',
          list: true,
          noForm: true,
        },
      ],
    },
    {
      icon: dataRegisters,
      title: 'Destinatário',
      items: [
        {
          id: 'recipient.xNome',
          type: 'text',
          name: 'recipient.xNome',
          formattedName: 'recipient.xNome',
          label: 'Razão Social',
          align: '',
          md: '3',
          list: false,
        },
        {
          id: 'recipient.CNPJ',
          type: 'text',
          name: 'recipient.CNPJ',
          formattedName: 'recipient.CNPJ',
          label: 'CNPJ',
          align: '',
          md: '2',
          list: false,
        },
        {
          id: 'recipient.CPF',
          type: 'text',
          name: 'recipient.CPF',
          formattedName: 'recipient.CPF',
          label: 'CPF',
          align: '',
          md: '2',
          list: false,
        },
        {
          id: 'recipient.IE',
          type: 'text',
          name: 'recipient.IE',
          formattedName: 'recipient.IE',
          label: 'Inscrição Estadual',
          align: '',
          md: '2',
          list: false,
        },
        {
          id: 'recipient.xLgr',
          type: 'text',
          name: 'recipient.xLgr',
          formattedName: 'recipient.xLgr',
          label: 'Endereço',
          align: '',
          md: '3',
          list: false,
        },
        {
          id: 'recipient.xBairro',
          type: 'text',
          name: 'recipient.xBairro',
          formattedName: 'recipient.xBairro',
          label: 'Bairro',
          align: '',
          md: '3',
          list: false,
        },
        {
          id: 'recipient.nro',
          type: 'text',
          name: 'recipient.nro',
          formattedName: 'recipient.nro',
          label: 'Nº',
          align: '',
          md: '2',
          list: false,
        },
        {
          id: 'recipient.CEP',
          type: 'text',
          name: 'recipient.CEP',
          formattedName: 'recipient.CEP',
          label: 'CEP',
          align: '',
          md: '2',
          list: false,
        },
        {
          id: 'recipient.fone',
          type: 'text',
          name: 'recipient.fone',
          formattedName: 'recipient.fone',
          label: 'Contato',
          align: '',
          md: '2',
          list: false,
        },
      ],
    },
    {
      icon: dataRegisters,
      title: 'Produtos',
      type: 'list',
      nameData: 'items',
      items: [
        {
          id: 'nItem',
          type: 'text',
          name: 'nItem',
          formattedName: 'nItem',
          label: 'Item',
          listFormItem: true,
          readonly: true,
          md: 2,
        },
        {
          id: 'product.xProd',
          type: 'text',
          name: 'product.xProd',
          formattedName: 'product.xProd',
          label: 'Produto',
          listFormItem: true,
          md: 7,
        },
        {
          id: 'product.NCM',
          type: 'text',
          name: 'product.NCM',
          formattedName: 'product.NCM',
          label: 'NCM',
          listFormItem: true,
          md: 3,
        },
        {
          id: 'product.qCom',
          type: 'text',
          name: 'product.qCom',
          formattedName: 'product.qCom',
          label: 'Qtd',
          listFormItem: true,
          md: 3,
        },
        {
          id: 'product.vUnCom',
          type: 'text',
          name: 'product.vUnCom',
          formattedName: 'product.vUnCom',
          label: 'Vlr. Unit.',
          listFormItem: true,
          md: 3,
        },
        {
          id: 'product.vProd',
          type: 'text',
          name: 'product.vProd',
          formattedName: 'product.vProd',
          label: 'Vlr. Total.',
          listFormItem: true,
          md: 3,
        },
      ]
    },
    {
      icon: dataRegisters,
      title: 'Pagamentos',
      type: 'list',
      nameData: 'payments',
      items: [
        {
          id: 'tPag',
          type: 'text',
          name: 'tPag',
          formattedName: 'tPag',
          label: 'Cod. Pag.',
          listFormItem: true,
        },
        {
          id: 'xPag',
          type: 'text',
          name: 'xPag',
          formattedName: 'xPag',
          label: 'Tipo Pag.',
          listFormItem: true,
        },
        {
          id: 'vPag',
          type: 'text',
          name: 'vPag',
          formattedName: 'vPag',
          label: 'Valor',
          listFormItem: true,
        },
        {
          id: 'tBand',
          type: 'text',
          name: 'tBand',
          formattedName: 'tBand',
          label: 'Bandeira',
          listFormItem: true,
        },
      ]
    }
  ],
  footer: [
    {
      name: 'total_value',
      label: 'Valor Total',
      class: 'custom-footer-value',
      md: 2,
    }
  ]
}