// import Devolutions from '@/business/Devolutions';
import { dataRegisters } from '@/constants/icons';

export default {
  domain: 'credit-letters',
  // business: Devolutions,
  title: 'Cartas de Crédito',
  description: 'Listagens das Cartas de Crédito',
  icon: 'mdi-point-of-sale',
  singleSelect: true,
  routes: {
    list: {
      name: 'credit-letters',
      path: '/cartas-creditos',
    },
    create: {
      name: 'credit-letter-create',
      path: '/cartas-creditos/novo',
    },
    show: {
      name: 'credit-letter-show',
      path: '/cartas-creditos/:id',
    },
  },
  create: true,
  show: true,
  destroy: true,
  destroyDisabled: false,
  noBetween: false,
  noEquals: false,
  searchCol: 4,
  betweenCol: 2,
  moreActions: null,
  filtersParams: null,
  fields: [
    {
      icon: dataRegisters,
      title: 'Dados do Carta de Crédito',
      items: [
        {
          type: 'text',
          name: 'id',
          label: 'Código',
          align: '',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'date',
          name: 'created_at_formatted',
          label: 'Data',
          align: '',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'customer.name',
          formattedName: 'customer_formatted',
          label: 'Cliente',
          align: '',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'origin_type_formatted',
          label: 'Origem',
          align: '',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'origin_id',
          label: 'Cod. Origem',
          align: '',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'value_total_formatted',
          label: 'Valor Total',
          align: '',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'value_total_used_formatted',
          label: 'Valor Usado',
          align: '',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'status_formatted',
          label: 'Status',
          align: '',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
      ],
    }
  ],
  footer: []
}