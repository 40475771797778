<template>
  <div>
    <v-card class="mt-3">
      <v-card-title>
        <h3>Desconto e Observações</h3>
      </v-card-title>

      <v-card-text>
        <InputCurrency 
          v-model="devolution.discount"
          label="Desconto" />

        <TextArea 
          v-model="devolution.observations"
          outlined
          dense
          label="Observações" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import InputCurrency from '@/components/vuetify/InputCurrency';
import TextArea from '@/components/vuetify/TextArea';

export default {
  name: 'DiscountObservations',
  components: {
    InputCurrency,
    TextArea,
  },
  props: {
    devolution: {
      type: Object,
      default: () => {}
    }
  },
}
</script>

<style>

</style>