import PaymentLinkSchema from './schemas/PaymentLinkSchema';
import PaymentLinks from './pages/PaymentLinks';
import PaymentLinkForm from './pages/PaymentLinkForm';

export default [
  {
    path: PaymentLinkSchema.routes.list.path,
    name: PaymentLinkSchema.routes.list.name,
    component: PaymentLinks,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: PaymentLinkSchema.routes.create.path,
    name: PaymentLinkSchema.routes.create.name,
    component: PaymentLinkForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: PaymentLinkSchema.routes.show.path,
    name: PaymentLinkSchema.routes.show.name,
    component: PaymentLinkForm,
    meta:{
      requiresAuth: true,
    }
  },
]