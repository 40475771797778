import { yes_no } from '@/constants/options';

export default {
  beforeList: (formValue) => {
    formValue.data.forEach((item) => {
      if(item.default != yes_no[1].value){
        item.disableItemMoreActions = {
          setStockDefault: false,
        }
      }
    })
  }
}