import PaymentMethodSettingsSchema from './schemas/PaymentMethodSettingsSchema';
import PaymentMethodSettings from './pages/PaymentMethodSettings';
import PaymentMethodSettingsForm from './pages/PaymentMethodSettingsForm';

export default [
  {
    path: PaymentMethodSettingsSchema.routes.list.path,
    name: PaymentMethodSettingsSchema.routes.list.name,
    component: PaymentMethodSettings,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: PaymentMethodSettingsSchema.routes.create.path,
    name: PaymentMethodSettingsSchema.routes.create.name,
    component: PaymentMethodSettingsForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: PaymentMethodSettingsSchema.routes.show.path,
    name: PaymentMethodSettingsSchema.routes.show.name,
    component: PaymentMethodSettingsForm,
    meta:{
      requiresAuth: true,
    }
  },
]