import SaleOrders from '@/business/SaleOrders';
import { note } from '@/constants/icons';

export default {
  domain: 'sale_orders',
  business: SaleOrders,
  title: 'Pedido de Vendas',
  description: 'Listagens dos Pedidos de Vendas',
  icon: 'mdi-point-of-sale',
  singleSelect: true,
  routes: {
    list: {
      name: 'sale-orders',
      path: '/pedido-vendas',
    },
    create: {
      name: 'sale-order-create',
      path: '/pedido-vendas/novo',
    },
    show: {
      name: 'sale-order-show',
      path: '/pedido-vendas/:id',
    },
  },
  create: true,
  show: true,
  destroy: true,
  destroyDisabled: true,
  noBetween: false,
  noEquals: false,
  searchCol: 4,
  betweenCol: 2,
  moreActions: [
    {
      name: 'makeNFe',
      label: 'Gerar NFe',
      icon: note,
      action: 'makeNFe'
    },
    {
      name: 'printSaleOrder',
      label: 'Imprimir Pedido',
      icon: note,
      action: 'printSaleOrder'
    }
  ],
  filtersParams: {
    ordering: {
      column: "order_date",
      value: "desc"
    },
    between: { 
      type: 'between',
      column: 'order_date' 
    },
    equals: { 
      type: 'equals',
      column: 'sale_order_status' 
    },
  },
  fields: [
    {
      type: 'text',
      name: 'id',
      label: 'Código',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'order_date',
      formattedName: 'order_date_formatted',
      label: 'Data',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'customer_id',
      formattedName: 'customer.name',
      label: 'Cliente',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'employee_id',
      formattedName: 'employee.name',
      label: 'Vendedor',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'value_total',
      formattedName: 'value_total_formatted',
      label: 'Valor Total',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'value_discount',
      formattedName: 'value_discount_formatted',
      label: 'Desconto',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'value_final',
      formattedName: 'value_final_formatted',
      label: 'Valor Final',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'value_paid',
      formattedName: 'value_paid_formatted',
      label: 'Valor Pago',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'value_pending',
      formattedName: 'value_pending_formatted',
      label: 'Valor Pendente',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'text',
      name: 'type_sale_formatted',
      formattedName: 'type_sale_formatted',
      label: 'Tipo Venda',
      align: '',
      md: '5',
      required: false,
      noFilter: true,
      list: true,
    },
    {
      type: 'text',
      name: 'sale_order_status',
      formattedName: 'sale_order_status_formatted',
      label: 'Status',
      align: '',
      md: '5',
      required: true,
      noFilter: false,
      list: true,
    },
  ],
  footer: [
    {
      name: 'total_paid',
      label: 'Total Pago',
      class: 'custom-footer-value',
      md: 2,
    },
    {
      name: 'total_pending',
      label: 'Total Pendente',
      class: 'custom-footer-value',
      md: 2,
    },
    {
      name: 'total_value',
      label: 'Total Geral',
      class: 'custom-footer-value',
      md: 2,
    },
    {
      name: 'total_discount',
      label: 'Total Descontos',
      class: 'custom-footer-value',
      md: 2,
    },
    {
      name: 'total_final',
      label: 'Total Final',
      class: 'custom-footer-value',
      md: 2,
    },
  ]
}