import BankSchema from './schemas/BankSchema';
import Banks from './pages/Banks';
import BankForm from './pages/BankForm';

export default [
  {
    path: BankSchema.routes.list.path,
    name: BankSchema.routes.list.name,
    component: Banks,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: BankSchema.routes.create.path,
    name: BankSchema.routes.create.name,
    component: BankForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: BankSchema.routes.show.path,
    name: BankSchema.routes.show.name,
    component: BankForm,
    meta:{
      requiresAuth: true,
    }
  },
]