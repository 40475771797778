import { formatCurrency } from '@/utils';
import { type_boxes } from '@/constants/options';

export default {
  beforeList: (formValue) => {
    let value_total = 0;
    let value_input = 0;
    let value_output = 0;
    formValue.data.forEach((item) => {
      value_total += parseFloat(item.value);

      if(item.type_box === type_boxes[0].value) {
        value_input += parseFloat(item.value);
      }else{
        value_output += parseFloat(item.value);
      }
    });
    formValue.value_total = formatCurrency(value_total);
    formValue.value_input = formatCurrency(value_input);
    formValue.value_output = formatCurrency(value_output);
  }
}