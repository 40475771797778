import DynamicService from '../../../../service/DynamicService';
import { formatCurrency, formatDate, getText } from '@/utils';
import { pagarme_payment_link_status } from '@/constants/options';

const formatResponse = (item) => {
  item.created_at = formatDate(item.created_at);
  item.customer_name = item.customer.name;
  item.amount_formatted = formatCurrency(item.amount);
  item.status_formatted = getText(pagarme_payment_link_status, item.status);
}

const PaymentLinkService = DynamicService('payment-links', {
  formatResponse
});

export default PaymentLinkService ;