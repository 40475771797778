<template>
  <v-app>
  <div>
    <v-card style="height: 100vh !important">
      <div style="display: flex; height: 100vh !important">
        <SideBar v-if="$route.name != 'login' && $route.name != 'register' && $route.name != 'storefront'" />
        <div :style="{width: '100%', paddingLeft: appPaddingLeft}">
          <div class="content-login" v-if="$route.name == 'login' || $route.name == 'register' || $route.name == 'storefront'">
            <router-view></router-view>
          </div>

          <div class="content-app" v-else>
            <div class="content-main">
              <v-card class="overflow-hidden v-card-content-main">
                <v-app-bar color="light" absolute v-if="$route.name != 'login'">
                  <v-toolbar-title>{{ getCompany.social_name || '' }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-card width="350">
                      <v-list>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-img src="@/assets/images/ico-user.png"></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>{{ getUser.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ getUser.email }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <v-divider></v-divider>

                      <v-list>
                        <v-list-item link @click="$router.push({name: 'companies'}),  menu = false">
                          <v-list-item-icon>
                            <v-icon class="icon-side-bar">mdi-domain</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title class="text-side-bar">Empresa</v-list-item-title>
                        </v-list-item>
                      </v-list>

                      <v-list>
                        <v-list-item link @click="dialogCertificate = true">
                          <v-list-item-icon>
                            <v-icon class="icon-side-bar">{{ icons.security }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title class="text-side-bar">Certificado Digital</v-list-item-title>
                        </v-list-item>
                      </v-list>

                      <v-list>
                        <v-list-item link @click="$router.push({name: 'imports'}),  menu = false">
                          <v-list-item-icon>
                            <v-icon class="icon-side-bar">{{ icons.security }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title class="text-side-bar">Importação de Dados</v-list-item-title>
                        </v-list-item>
                      </v-list>

                      <v-divider></v-divider>

                      <v-list>
                        <v-list-item>
                          <v-btn @click="logout(), menu = false" color="primary" style="width: 100%">Sair</v-btn>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-app-bar>

                <v-sheet id="scrolling-techniques-7"  class="overflow-y-auto content-main-pages">
                  <div class="px-5 py-5 mt-10">
                    <div class="content-main-header" v-if="$route.name != 'login'">
                      <h3>Seja bem vindo: {{ getUser.name }}</h3>
                    </div>

                    <div class="content-main-body">
                      <router-view></router-view>
                    </div>
                  </div>
                </v-sheet>
              </v-card>
            </div>
          </div>

        </div>
      </div>
    </v-card>

    <ModalUploadCertificate 
      :dialog="dialogCertificate"
      @close="dialogCertificate = false" />
  </div>
  </v-app>
</template>

<script>
import SideBar from  './components/SideBar';
import AuthService from './pages/Auth/services/AuthService';
import { getCompany, getUser } from '@/utils';
import ModalUploadCertificate from '@/pages/Companies/components/ModalUploadCertificate';
import { security } from '@/constants/icons';

export default {
  name: 'App',
  components: {
    SideBar,
    ModalUploadCertificate,
  },

  data: () => ({
    icons: {
      security: security,
    },
    service: AuthService,
    menu: false,
    getCompany: getCompany(),
    getUser: getUser(),
    dialogCertificate: false,
  }),
  computed: {
    appPaddingLeft() {
      return this.$route.name == 'login' || this.$route.name == 'register' || this.$route.name == 'storefront' ? '0px' : '65px';
    }
  },
  methods: {
    logout() {
      this.service.logout().then(() => {
        this.$router.push({ name: 'login' });
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.content-app {
  height: 100vh !important;
  display: flex;
  background: #fff;

  .list-sub-groups {
    padding-left: 40px !important;
  }

  .content-main {
    width: 100%;
    height:100% !important;
    position: relative;

    .v-card-content-main {
      height:100% !important;

      .content-main-pages {
        height: 100% !important;
      }
    }

    .content-main-header {
      width: 100%;
      height: 250px;
      background: #5E72E4;
      position: absolute;
      top: 0;
      left: 0;

      h3 {
        position: absolute;
        top: 80px;
        left: 20px;
        color: #fff;
      }
    }

    .content-main-body {
      position: relative;
      top: 30px;
    }

    .content-main-footer {
      width: 100%;
      height: 50px;
      background: orange;
      position: absolute;
      bottom: 0;
      left: 0;

      h3 {
        position: absolute;
        top: 80px;
        left: 20px;
        color: #fff;
      }
    }
  }
}
</style>
