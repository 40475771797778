<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
      class="dialog-custom"
    >
      <v-card class="">
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-card-title>
            <h3>Pesquise o Produto</h3>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-row class="mt-3">
              <v-col md="5" class="pt-0 pb-0">
                <v-select
                  v-model="stock_id"
                  item-text="name"
                  item-value="id"
                  label="Stock"
                  dense
                  outlined
                  :rules="rules.required"
                  :items="stocks" />
              </v-col>
            </v-row>

            <DataTableDefault
              class="mt-5" 
              :data="{items: [{id: 1, name: 'Test'}], totalItems: 1}"
              :headers="headers"
              :config="config"
              :itemsPerPage="10"
              :itemsDataTable="itemsDataTable"
              :totalItemsDataTable="totalItemsDataTable"
              :loadingDataTable="loadingDataTable"
              :business="businessProducts"
              @selectedItem="selectedItem"
              @searchDataTable="searchDataTable"
              @paginateDataTable="paginateDataTable" />
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small dark color="red" @click="$emit('close')">Cancelar (ESC)</v-btn>
            <v-btn small dark color="primary" @click="action()">Confirmar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <DialogViewVariations
      :items=productVariations
      :dialog="dialogViewVariations"
      @selectVariation="selectVariation"
      @update:dialogViewVariations="dialogViewVariations = $event" />
  </div>
</template>

<script>
import DataTableDefault from '@/components/DataTableDefault';
import Products from '@/business/Products';
import ProductService from '@/pages/Stock/Products/services/ProductService';
import StockService from '@/pages/Stock/TypeStock/services/StockService';
import { rulesRequired } from '@/constants/masks';
import DialogViewVariations from '@/pages/Operations/SaleOrders/components/DialogViewVariations';
import { verifyItemSigleVariation } from '@/utils'

export default {
  name: 'DialogProducts',
  components: {
    DataTableDefault,
    DialogViewVariations,
  },
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    stockDefaultId: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      validForm: false,
      service: ProductService,
      serviceStock: StockService,
      businessProducts: Products,
      rules: {
        required: rulesRequired,
      },
      config: {
        domain: 'products',
        ordering: {
          column: 'name',
          value: 'asc'
        },
        q: [],
      },
      headers: [
        {text: 'Nome', value: 'name'},
        {text: 'Cod. Barras', value: 'bar_code'},
        {text: 'Estoque', value: 'qtd_stock_formatted'},
        {text: 'Vlr. Varejo', value: 'price_var'},
        {text: 'Vlr. Atacado', value: 'price_ata'},
      ],
      productVariationSelected: {},
      productVariations: [],
      stock_id: 0,
      stocks: [],
      itemsDataTable: [],
      totalItemsDataTable: 0,
      loadingDataTable: false,
      dialogViewVariations: false,
      search: '',
      optionsDataTable: {
        page: 1,
        per_page: 10
      }
    }
  },
  mounted() {
    this.getStocks();
    this.getDataFromApi();
    window.addEventListener('keydown', (e) => {
      this.changeActionKeyUp(e.keyCode);
    });
  },
  watch: {
    stockDefaultId: {
      handler() {
        this.stock_id = this.stockDefaultId
      },
      deep: false,
    },
    dialog: {
      handler() {
        if(this.dialog)
          this.getDataFromApi();
      },
      deep: true
    }
  },
  methods: {
    searchDataTable(search) {
      this.search = search
      this.getDataFromApi()
    },
    paginateDataTable(options) {
      this.optionsDataTable = options;
      this.getDataFromApi()
    },
    getDataFromApi() {
      this.loadingDataTable = true;
      let paginate = `page=${this.optionsDataTable.page}&per_page=${this.optionsDataTable.per_page}`;

      let payload = {
        domain: this.config.domain,
        search: this.search,
        ordering: this.config.ordering,
        q: this.config.q,
      }
      
      this.service.filters(payload, paginate).then((res) => {
        const { total, data } = res.data;
          this.totalItems = total;
          this.itemsDataTable = data;
          this.totalItemsDataTable = total;

          if(this.businessProducts.beforeList instanceof Function)
            this.businessProducts.beforeList({data: this.itemsDataTable})

          this.loadingDataTable = false;
      }).catch(() => {
        this.loadingDataTable = false;
      });
    },
    getStocks() {
      this.stocks = [];
      this.serviceStock.index().then((res) => {
        this.stocks.push({
            id: null,
            name: '',
        });
        res.data.data.forEach((item) => {
          this.stocks.push({
            id: item.id,
            name: item.description,
          });
        })
      }).catch(() => {
      })
    },
    changeActionKeyUp(keyCode) {
      if(keyCode == 27) {
        this.$emit('close')
      }
    },
    selectedItem(item) {
      if(!this.$refs.form.validate()) {
        item[0] = {}
        return;
      }
      
      this.productVariationSelected = item[0];
      this.action();
    },
    action() {
      if(this.productVariationSelected.variations.length === 1) {
        if(verifyItemSigleVariation(this.productVariationSelected.variations[0])) {
          const params = { productVariation: this.productVariationSelected.variations[0], stock_id: this.stock_id };
          this.$emit('action', {action: 'changeProductSelected', params});
        }else{
          this.changeVariations(this.productVariationSelected)
        }
      }else{
        this.changeVariations(this.productVariationSelected)
      }
    },
    selectVariation(item) {
      const params = { productVariation: item, stock_id: this.stock_id }
      this.dialogViewVariations = false;
      this.$emit('action', {action: 'changeProductSelected', params});
    },
    changeVariations(item) {
      this.productVariations = item.variations_formatted;
      this.dialogViewVariations = true;
    },
  }
}
</script>