<template>
  <div class="content-sidebar">
    <v-card class="card-side-bar">  
      <v-navigation-drawer :permanent="drawer" :expand-on-hover="drawer" fixed mini-variant-width="75" dark elevation="5">
        <v-list>
          <v-list-item class="">
            <div style="display: flex; justify-content: space-between; width: 100%">
              <v-list-item-avatar>
                <v-img src="@/assets/images/ico-logo.png"></v-img>
              </v-list-item-avatar>
              <v-icon color="white" @click="drawer = !drawer" v-if="!drawer" class="icon-side-bar">{{ icons.close }}</v-icon>
            </div>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <div v-for="(item, index) in menus" :key="index">
            <v-list-item link v-if="!item.children.length" @click="$router.push({name: item.route}).catch(err => {})">
              <v-list-item-icon>
                <v-icon color="white" class="icon-side-bar">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title class="text-side-bar">{{ item.title }}</v-list-item-title>
            </v-list-item>

            <v-list-group v-else >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon color="white" class="icon-side-bar">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-side-bar">{{ item.title }}</v-list-item-title>
              </template>

              <v-list-item link v-for="(sub_item, sub_index) in item.children" :key="sub_index" class="list-sub-groups" @click="$router.push({name: sub_item.route}).catch(err => {})">
                <v-list-item-icon>
                  <v-icon color="white" class="icon-side-bar">{{ sub_item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-side-bar">{{ sub_item.title }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<script>
import { note, noteMultiple, close, money, web } from '@/constants/icons';

export default {
  name: 'SideBar',
  data() {
    return {
      icons: {
        close: close,
        money: money
      },
      drawer: true,
      menus: [
        { title: 'Início', icon: 'mdi-home', route: 'home', children: [] },
        { title: 'Cadastros', icon: 'mdi-checkbox-multiple-marked-circle', route: '', children: [
          { title: 'Usuários', icon: 'mdi-account-circle', route: 'users', },
          { title: 'Registros', icon: 'mdi-account-settings', route: 'registers', },
          { title: 'Tipos Gerais', icon: 'mdi-expand-all', route: 'alltypes', },
          { title: 'Serviços', icon: 'mdi-account-group-outline', route: 'services' },
        ]},
        { title: 'Estoque', icon: 'mdi-archive-arrow-down-outline', route: '', children: [
          { title: 'Produtos', icon: 'mdi-database', route: 'products' },
          { title: 'Tipo Estoque', icon: 'mdi-database-search', route: 'stocks' },
          { title: 'Movimentações', icon: 'mdi-arrow-left-right-bold', route: 'movimentations-stocks' },
          { title: 'Variações', icon: 'mdi-arrow-left-right-bold', route: 'variations' },
        ]},
        { title: 'Operações', icon: 'mdi-point-of-sale', route: '', children: [
          { title: 'Pedidos de Vendas', icon: 'mdi-point-of-sale', route: 'sale-orders' },
          { title: 'Ordem de Serviços', icon: 'mdi-point-of-sale', route: 'order-services' },
          { title: 'Devoluções', icon: 'mdi-point-of-sale', route: 'devolutions' },
          { title: 'Cartas de Crédito', icon: 'mdi-point-of-sale', route: 'credit-letters' },
        ]},
        { title: 'Financeiro', icon: 'mdi-account-cash-outline', route: '', children: [
          { title: 'Bancos', icon: 'mdi-bank', route: 'banks' },
          { title: 'Configurações Pagamento', icon: 'mdi-credit-card', route: 'paymentmethodsettings' },
          { title: 'Histórico de Movimentações', icon: 'mdi-bank-transfer', route: 'movementhistories' },
          { title: 'Caixa', icon: 'mdi-cash-multiple', route: 'box'},
          { title: 'Contas a Pagar', icon: 'mdi-swap-horizontal-bold', route: 'accountpayments' },
          { title: 'Boletos', icon: money, route: 'billets' },
          { title: 'Links de Pagamento', icon: web, route: 'paymentlinks' },
        ]},
        { title: 'Notas Fiscais', icon: noteMultiple, route: '', children: [
          { title: 'NFe Geradas', icon: note, route: 'invoices' },
        ]},
        { title: 'Relatórios', icon: 'mdi-chart-sankey', route: '', children: [
          { title: 'Pedidos de Venda', icon: 'mdi-point-of-sale', route: 'reportssaleorders' },
        ]},
        { title: 'PDV', icon: 'mdi-point-of-sale', route: '', children: [
          { title: 'Frente de Loja', icon: money, route: 'storefront' },
        ]},
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.content-sidebar {
  height: 100vh;
  display: flex;

  .card-side-bar {
    box-shadow: 1px 1px 1px #ccc !important;
  }

  .icon-side-bar {
    font-size: 20px;
  }

  .text-side-bar {
    font-weight: 300;
    font-family: 'Roboto', 'sans-serif';
  }

  .list-sub-groups {
    padding-left: 28px !important;
  }

  .content-main {
    width: 100%;
    height:100% !important;

    .v-card-content-main {
      height:100% !important;

      .content-main-pages {
        height: 100% !important;
      }
    }
  }
}

.v-list-item__icon {
  margin-right: 7px !important;
}
</style>