<template>
  <div class="auth">
    <div class="auth-card">
      <div class="left">
        <img src="@/assets/images/Logo-MGE.png" width="250" alt="">

        <div class="form">
          <v-form
            ref="form"
            lazy-validation
            v-model="valid"
            v-on:submit.prevent="login()"
          >

            <div class="form-control">
              <div class="text-icon">
                <v-icon color="white" class="icon-side-bar">{{ icons.domain }}</v-icon>
              </div>
              <input type="text" v-model="company.name" class="input-text" placeholder="digite a empresa" />
            </div>

            <div class="form-control">
              <div class="text-icon">
                <v-icon color="white" class="icon-side-bar">{{ icons.numeric }}</v-icon>
              </div>
              <input type="text" v-model="company.cnpj" class="input-text" v-mask="'##.###.###/####-##'" placeholder="digite o CNPJ" />
            </div>

            <div class="form-control">
              <div class="text-icon">
                <v-icon color="white" class="icon-side-bar">{{ icons.phone }}</v-icon>
              </div>
              <input type="text" v-model="company.phone" class="input-text" v-mask="['(##)####-####', '(##)#####-####']" placeholder="digite seu contato" />
            </div>

            <div class="form-control">
              <div class="text-icon">
                <v-icon color="white" class="icon-side-bar">{{ icons.email }}</v-icon>
              </div>
              <input type="text" v-model="company.email" class="input-text" placeholder="digite seu e-mail" />
            </div>

            <div class="form-control">
              <div class="text-icon">
                <v-icon color="white" class="icon-side-bar">{{ icons.password }}</v-icon>
              </div>
              <input type="password" v-model="company.password" class="input-text" 
                placeholder="digite sua senha" v-on:keyup.enter="register()" />
            </div>

            <div class="form-footer">
              <v-btn
                color="orange"
                class="mr-4"
                :disabled="!valid"
                :loading="loading"
                @click="register()"
              >
                Entrar
              </v-btn>
            </div>
          </v-form>
        </div>

        <div class="auth-footer">
          <p>Já sou cadastrado. <router-link :to="{name: 'login'}">Fazer Login</router-link></p>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
import { email as icoEmail, lockOutline, domain, phone, cellPhone, numeric } from '@/constants/icons';
import { mask } from "vue-the-mask";
import AuthService from './services/AuthService';

export default {
  name: 'Register',
  components: {
  },
  directives: {
    mask,
  },
  data () {
    return {
      icons: {
        email: icoEmail,
        password: lockOutline,
        domain: domain,
        phone: phone,
        cellPhone: cellPhone,
        numeric: numeric,
      },
      loading: false,
      company: {
        name: '',
        cnpj: '',
        phone: '',
        email: '',
        password: ''
      }
    }
  },
  computed: {
    valid: {
      get(){
        return this.company.name && this.company.cnpj && 
          this.company.phone && this.company.email && this.company.password ? true : false;
      },
      set(){
        return true;
      } 
    }
  },
  methods: {
    register() {
      if(this.valid){
        this.loading = true;
        AuthService.register(this.company).then(() => {
          this.loading = false;
          const urlRoot = process.env.VUE_APP_URL;
          window.location = `${urlRoot}/login`;
          this.$router.push({name: 'home'});
        }).catch(() => {
          this.loading = false;
          this.$noty.error('Erro no Cadastro!');
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss'
</style>
