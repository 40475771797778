<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="480"
  >
    <v-card>
      <v-card-title class="headline">
        {{ message }}
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small @click="eventBtn(false)">Não</v-btn>
        <v-btn small dark color="primary" @click="eventBtn(true)" :loading="loading">Sim</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogSimpleQuestion',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: 'Deseja realmente excluir este registro?'
    }
  },
  methods: {
    eventBtn(value) {
      this.$emit('eventBtn', value);
    }
  }
}
</script>