<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    class="dialog-custom"
  >
  <v-card>
      <v-card-title class="headline">
        Cancelar NF-e
        
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="mt-3">
        <v-row>
          <v-col md="3">
            <p>Número: {{ nfeSelected.nNF }}</p>
          </v-col>
          <v-col>
            <p>Chave: {{ nfeSelected.chNFe }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.justification"
              label="Justificativa"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small @click="$emit('close', 'dialogCancelNFe')">Fechar</v-btn>
        <v-btn small dark color="primary" @click="$emit('sendCancelNFe', form)" :loading="loadingBtn">Enviar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'DialogCancelNFe',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    loadingBtn: {
      type: Boolean,
      default: false
    },
    nfeSelected: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      form: {
        justification: '',
      }
    }
  },
  watch: {
  },
  mounted() {
  },
}
</script>
<style scoped>
</style>