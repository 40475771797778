<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema"
      :route-create="schema.routes.create.name" 
      @actionBtn="actionBtn">
      <p>Conteúdo</p>

      <div style="width: 450px; display: flex">
        <bar-chart :labels="sale.labels" :values="sale.values" title="Vendas"></bar-chart>
        <bar-chart :labels="customers.labels" :values="customers.values" color="#082" title="Clientes"></bar-chart>
      </div>
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import HomeSchema from '../schemas/HomeSchema';
import AuthService from '@/pages/Auth/services/AuthService';
import BarChart from '@/components/BarChart';

export default {
  name: 'Home',
  components: {
    SimpleCard,
    BarChart
  },
  data () {
    return {
      schema: HomeSchema,
      sale: {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho'],
        values: [50, 42, 55, 64, 80, 65, 70]
      },
      customers: {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho'],
        values: [30, 12, 45, 54, 30, 65, 40]
      }
    }
  },
  mounted() {
    this.me();
  },
  methods: {
    me(){
      AuthService.me().then(() => {
        
      })
    },
    actionBtn() {
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
