<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="900"
    class="dialog-custom"
  >
    <v-card class="">
      <v-card-title>
        <h3>Selecione o Estoque</h3>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-data-table
          hide-default-footer
          class="elevation-1 mt-5"
          item-key="id"
          :id="'id'"
          :headers="headers"
          :items="stocks"
          :loading="loadingStocks">

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn class="my-1" color="success" x-small @click="action(item)">
              Selecionar
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small dark color="red" @click="$emit('update:dialogStocks', false)">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StockService from '@/pages/Stock/TypeStock/services/StockService';
import { messageErrors } from '@/utils';

export default {
  name: 'DialogStocks',
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
  },
  mounted() {
    this.getStocks();
  },
  data() {
    return {
      headers: [
        {text: 'Estoque', value: 'description'},
        {text: 'Ações', value: 'actions', align: 'end'},
      ],
      stocks: [],
      loadingStocks: false,
    }
  },
  methods: {
    getStocks() {
      StockService.index().then((res) => {
        this.stocks = res.data.data;
      }).catch((err) => {
        this.$noty.error(messageErrors(err));
      })
    },
    action(item) {
      this.$emit('action', {action: 'changeStockSelected', params: {stock: item}});
    }
  }
}
</script>
<style scoped>
</style>