import CreditLetterSchema from './schemas/CreditLetterSchema';
import CreateCreditLetter from './pages/CreateCreditLetter';
import CreditLetters from './pages/CreditLetters';

export default [
  {
    path: CreditLetterSchema.routes.list.path,
    name: CreditLetterSchema.routes.list.name,
    component: CreditLetters,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: CreditLetterSchema.routes.create.path,
    name: CreditLetterSchema.routes.create.name,
    component: CreateCreditLetter,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: CreditLetterSchema.routes.show.path,
    name: CreditLetterSchema.routes.show.name,
    component: CreateCreditLetter,
    meta:{
      requiresAuth: true,
    }
  },
]