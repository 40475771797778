<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="680"
  >
    <v-card>
      <v-card-title class="headline">
        {{ item.label }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <p>Selecione o arquivo para importação</p>
            <v-file-input
              v-model="fileImport"
              hide-details="auto"
              label="Arquivo">
            </v-file-input>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small @click="$emit('close')">Fechar</v-btn>
        <v-btn small dark color="primary" @click="$emit('actionImport', fileImport)" :loading="loadingBtn">Enviar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalUploadCSV',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    loadingBtn: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      fileImport: {},
    }
  },
  mounted() {
  },
  watch: {
  },
  methods: {
  }
}
</script>