import DynamicService from '@/service/DynamicService';

const formatResponse = (item) => {
  item
}

const formatRequest = () => {
}

const VariationService = DynamicService('variations', {
  formatResponse,
  formatRequest,
});

const VariationsCommands = () => ({
});

export default VariationService;
export { VariationService, VariationsCommands };