<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
    class="dialog-custom"
  >
    <v-card class="">
      <form @submit.prevent="action()">
        <v-card-title>
          <h3>Quantidade</h3>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="mt-5">
          <vuetify-money
            label="Qtds"
            v-model="qtd"
            dense
            outlined
            :options="money_options"
            v-on:keyup.enter="action()" />
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small dark color="red" @click="$emit('close')">Cancelar (ESC)</v-btn>
          <v-btn small dark color="primary" type="submit">Confirmar</v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'DialogQtd',
  components: {
  },
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      this.changeActionKeyUp(e.keyCode);
    });
  },
  data() {
    return {
      qtd: 1,
      money_options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2
      },
    }
  },
  methods: {
    changeActionKeyUp(keyCode) {
      if(keyCode == 27) {
        this.$emit('close')
      }
    },
    action() {
      const qtdTmp = _.clone(this.qtd)
      this.qtd = 1;
      this.$emit('action', {action: 'changeQtd', params: {qtd: qtdTmp}});
    }
  }
}
</script>
<style scoped>
</style>