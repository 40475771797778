/* eslint-disable */
import { formatDate } from '@/utils';

const PrintDanfeNFe = {
  methods: {
    getCompany() {
      return JSON.parse(localStorage.getItem('siscomge.company'));
    },
    getLogo(logo) {
      if(!logo)
        return require(`@/assets/images/logo-default.jpg`);

      return logo;
    },
    print(item, barCode) {
      const win = window.open('', '', 'width=800, heigth=600');
      win.document.write('');

      const classes = this.defineClass(win);

      win.document.write('<html>');
        win.document.write('<head>');
          win.document.write('<title>DANFE</title>');
          win.document.write('<style>');
            win.document.write(classes);
          win.document.write('</style>');
        win.document.write('</head>');
        win.document.write('<body>');
      
      win.document.write('<div class="danfe_pagina">');
        win.document.write('<div class="danfe_pagina2">');
          win.document.write(this.makeHeader(item));
          win.document.write(this.makeEmitter(item, barCode));
          win.document.write(this.makeDest(item));
          // win.document.write(this.makeBill(item));
          win.document.write(this.makeTaxes(item));
          win.document.write(this.makeTransp(item));
          win.document.write(this.makeItems(item));
          win.document.write(this.makeInfoCompl(item));
        win.document.write('</div>');
      win.document.write('</div>');

      win.document.write('</body>');
      win.document.write('</html>');
      win.document.close;
    },
    defineClass() {
      let classes = 'body {width: 800px !important;} ';
      classes = '.danfe_pagina{ width: 29.7cm !important; font-size:10px;font-family:Arial,Helvetica;margin:0px;padding:1px; position: relativ } ';
      classes += '.danfe_pagina2{ margin:1px;padding:0 } ';
      classes += '.danfe_linha_tracejada{ width:100%;border-bottom:#000 1px dashed;margin:10px 0 10px 0 } ';
      classes += '.danfe_tabelas{ border-collapse:collapse;width:100%;margin:0;padding:0 } ';
      classes += '.danfe_celula_bordas{ border:1px solid black; vertical-align:top } ';
      classes += '.danfe_celula_titulo{ margin:0;font-size:7pt;padding:0 2px 0px 2px } ';
      classes += '.danfe_celula_valor{ margin:0;font-size:8pt;padding-left:4px } ';
      classes += '.danfe_canhoto_bordas{ font-size:7pt;border:1px solid #000;margin:0px;padding:0;margin:0 1px 0 1px;min-height:30px } ';
      classes += '.danfe_canhoto_texto{ font-size:6pt;margin:0;font-weight:normal;padding:0 2px 1px 2px } ';
      classes += '.danfe_cabecalho_danfe{ font-size:13px;font-weight:bold;margin:0;text-align:center } ';
      classes += '.danfe_cabecalho_danfe_texto{ font-size:7pt;padding:0;margin:0 1px 0 1px;text-align:center } ';
      classes += '.danfe_cabecalho_numero{ font-size:13px;font-weight:bold;margin:0;text-align:center } ';
      classes += '.danfe_cabecalho_entrada_saida{ font-size:7pt; } ';
      classes += '.danfe_cabecalho_entrada_saida_quadrado{ font-size:13pt;border:1px solid #000000; margin: 0 10px; text-align:center; width:30px } ';
      classes += '.danfe_titulo_externo{ font-size:8pt;margin:4px 0 0 0;font-weight:bold } ';
      classes += '.danfe_item{ border:1px black solid;border-top:none;border-bottom:dotted 1pt #dedede; font-size:8pt; text-align: right; } ';
      classes += '.danfe_item_ultimo{ border:1px black solid;border-top:none;margin:0px;padding:0;font-size:1px } ';
      classes += '.danfe_item_cabecalho{ border:1px solid #000;text-align:left;font-size:7pt } ';
      classes += '.danfe_item_cabecalho_tabela{ border-collapse:collapse;width:100%;margin:0;padding:0;border:1px solid #000 } ';
      classes += '.content-flex-column-center{ display: flex; flex-direction: column; justify-content: center; align-items: center; } ';
      classes += '.content-logo, .content-emitter-info{ min-height: 100px } ';
      classes += 'h3, p { margin: 0; padding: 0 } ';
      classes += '.info-danfe { display: flex; justify-content: center; align-items: center; } ';
      classes += '.no-border-top { border-top: 0 } ';
      classes += '.info-compl { width: 29.7cm !important; position: absolute; bottom: 10px; width: calc(100vw - 15px);} ';
      return classes;
    },
    makeHeader(item) {
      let html = `
        <table class="danfe_tabelas">
          <tr>
            <td>
              <table class="danfe_tabelas" style="min-height:60px;">
                <tr>
                  <td class="danfe_celula_bordas" colspan="2">
                    <p class="danfe_canhoto_texto">RECEBEMOS DE: ${item.emitter.xNome  || ''} OS PRODUTOS CONSTANTES DA NOTA FISCAL INDICADA AO LADO</p>
                  </td>
                </tr>

                <tr>
                  <td class="danfe_canhoto_bordas">
                    <p class="danfe_celula_titulo">Data de recebimento</p>
                    <p class="danfe_celula_valor">&nbsp;</p>
                  </td>

                  <td class="danfe_canhoto_bordas">
                    <p class="danfe_celula_titulo">Identificação e assinatura do recebedor</p>
                    <p class="danfe_celula_valor">&nbsp;</p>
                  </td>
                </tr>
              </table>
            </td>
            <td>&nbsp;</td>
            <td>
              <table class="danfe_tabelas" style="min-height:60px">
                <tr>
                  <td class="danfe_celula_bordas" align='center'>
                    <strong>NF-e<strong>
                    <h2 class="danfe_cabecalho_numero">Número: ${item.nNF || ''}</h2>
                    <strong>Série: ${item.serie  || ''}</strong>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <div class="danfe_linha_tracejada"></div>
      `;
      return html;
    },
    makeEmitter(item, barCode) {
      const company = this.getCompany();
      console.log(company)
      let html = `
      <table class="danfe_tabelas">
        <tr>
          <td rowspan='3' class="danfe_celula_bordas" width="150">
            <div class="content-logo content-flex-column-center">
              <img src="${this.getLogo(company.logo)}" width="150" height="80" />
            </div>
          </td>
          <td rowspan='3' class="danfe_celula_bordas" align='center'>
            <div class="content-flex-column-center content-emitter-info">
              <h3>${company.name}</h3>
              <p>${company.street} ${company.number || ''} ${company.neighbourhood || ''}</p>
              <p>${company.city}/${company.state || ''}</p>
              <p>${company.email || ''}</p>
              <p>${company.phone || ''}</p>
            </div>
          </td>
          <td rowspan='3' class="danfe_celula_bordas" align='center'>
            <div class="content-emitter-info content-flex-column-center">
              <p class="danfe_cabecalho_danfe">DANFE</p>
              <p class="danfe_cabecalho_danfe_texto">Documento Auxiliar da <br>Nota Fiscal Eletr&ocirc;nica</p>
              <table class="danfe_tabelas">
                <tr>
                  <td nowrap class='danfe_cabecalho_entrada_saida' align='right' width="50">
                    <div class="info-danfe">
                      <div>
                        <p>0-Entrada</p>
                        <p>1-Saída</p>
                      </div>
                      <div class="danfe_cabecalho_entrada_saida_quadrado">
                        <p>${item.tpNF || ''}</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
              <p class="danfe_cabecalho_numero">Número: ${item.nNF || ''}</p>
              <p class="danfe_cabecalho_danfe_texto">SÉRIE: ${item.serie || ''}</p>
            </div>
          </td>
          <td class="danfe_celula_bordas" align='center'>
            <p class="danfe_celula_titulo">Controle do Fisco</p>
            <div style="padding: 5px">
            <img src="${barCode}" />
            </div>
          </td>
        </tr>
        <tr>
          <td class="danfe_celula_bordas" align='center'>
            <p class="danfe_celula_titulo">Chave de acesso</p>
            <p class="danfe_celula_valor">${item.chNFe || ''}</p>
          </td>
        </tr>
        <tr>
          <td class="danfe_celula_bordas" align='center'>
            <p class="danfe_celula_titulo">Consulta de autenticidade no portal nacional da NF-e</p>
            <p class="danfe_celula_valor">
            <a href="http://www.nfe.fazenda.gov.br/portal" target="_blank">www.nfe.fazenda.gov.br/portal</a> ou no site da Sefaz autorizadora</p>
          </td>
        </tr>
        <tr>
          <td colspan='3' class="danfe_celula_bordas">
            <p class="danfe_celula_titulo">Natureza da operação</p>
            <p class="danfe_celula_valor">${item.natOp || ''}</p>
          </td>
          <td class="danfe_celula_bordas" align='center'>
            <p class="danfe_celula_titulo">Número de protocolo de autorização de uso da NF-e</p>
            <p class="danfe_celula_valor">${item.nProt || ''}</p>
          </td>
        </tr>
      </table>

      <table class="danfe_tabelas">
        <tr>
          <td class="danfe_celula_bordas no-border-top">
            <p class="danfe_celula_titulo">Inscrição Estadual</p>
            <p class="danfe_celula_valor">${company.ie}</p>
          </td>
          <td class="danfe_celula_bordas no-border-top">
            <p class="danfe_celula_titulo">Inscr. Est. do Subst. Trib.</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
          <td class="danfe_celula_bordas no-border-top">
            <p class="danfe_celula_titulo">CNPJ</p>
            <p class="danfe_celula_valor">${company.cnpj}</p>
          </td>
        </tr>
      </table>
      `;
      return html;
    },
    makeDest(item) {
      let html = `
      <br>
      <h3 class="danfe_titulo_externo">Destinatário/Remetente</h3>
      <table class="danfe_tabelas">
        <tr>
          <td>
            <table class="danfe_tabelas">
              <tr>
                <td class="danfe_celula_bordas">
                  <p class="danfe_celula_titulo">Nome/Razão Social</p>
                  <p class="danfe_celula_valor">${item.recipient.xNome || ''}</p>
                </td>
                <td class="danfe_celula_bordas">
                  <p class="danfe_celula_titulo">CNPJ/CPF</p>
                  <p class="danfe_celula_valor">${item.recipient.CNPJ ? item.recipient.CNPJ : item.recipient.CPF}</p>
                </td>
                <td class="danfe_celula_bordas">
                  <p class="danfe_celula_titulo">Inscrição Estadual</p>
                  <p class="danfe_celula_valor">${item.recipient.IE || ''}</p>
                </td>
                <td class="danfe_celula_bordas">
                  <p class="danfe_celula_titulo">Data emissão</p>
                  <p class="danfe_celula_valor">${item.dhEmi || '&nbsp;'}</p>
                </td>
              </tr>
              <tr>
                <td class="danfe_celula_bordas">
                  <p class="danfe_celula_titulo">Endereço</p>
                  <p class="danfe_celula_valor">${item.recipient.xLgr || ''}</p>
                </td>
                <td class="danfe_celula_bordas">
                  <p class="danfe_celula_titulo">Bairro</p>
                  <p class="danfe_celula_valor">${item.recipient.xNoxBairrome || ''}</p>
                </td>
                <td class="danfe_celula_bordas">
                  <p class="danfe_celula_titulo">CEP</p>
                  <p class="danfe_celula_valor">${item.recipient.CEP || ''}</p>
                </td>
                <td class="danfe_celula_bordas">
                  <p class="danfe_celula_titulo">Data saída</p>
                  <p class="danfe_celula_valor">${item.dhEmi || '&nbsp;'}</p>
                </td>
              </tr>
              <tr>
                <td class="danfe_celula_bordas">
                  <p class="danfe_celula_titulo">Município</p>
                  <p class="danfe_celula_valor">${item.recipient.xMun || ''}</p>
                </td>
                <td class="danfe_celula_bordas">
                  <p class="danfe_celula_titulo">Fone/Fax</p>
                  <p class="danfe_celula_valor">${item.recipient.fone || ''}</p>
                </td>
                <td class="danfe_celula_bordas">
                  <p class="danfe_celula_titulo">UF</p>
                  <p class="danfe_celula_valor">${item.recipient.UF || ''}</p>
                </td>
                <td class="danfe_celula_bordas">
                  <p class="danfe_celula_titulo">Hora saída</p>
                  <p class="danfe_celula_valor">${item.dhEmi || '&nbsp;'}</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      `;
      return html;
    },
    makeBill(item) {
      let html = `
      <br>
      <h3 class="danfe_titulo_externo">Faturas</h3>
      <table class="danfe_tabelas">
        <tr class='danfe_item_cabecalho_tabela' align='left'>
          <th class="danfe_item_cabecalho">Número</th>
          <th class="danfe_item_cabecalho">Vencimento</th>
          <th class="danfe_item_cabecalho" style="text-align:right;">Valor</th>
          <th class="danfe_item_cabecalho">Número</th>
          <th class="danfe_item_cabecalho">Vencimento</th>
          <th class="danfe_item_cabecalho" style="text-align:right;">Valor</th>
          <th class="danfe_item_cabecalho">Número</th>
          <th class="danfe_item_cabecalho">Vencimento</th>
          <th class="danfe_item_cabecalho" style="text-align:right;">Valor</th>
        </tr>
        <tr class='danfe_item'>
          <td align='left'>{fatura}</td>
          <td align='left'>{data}</td>
          <td style='border-right:solid black 1px'>{valor}</td>
          <td align='left'>{fatura}</td>
          <td align='left'>{data}</td>
          <td style='border-right:solid black 1px'>{valor}</td>
          <td align='left'>{fatura}</td>
          <td align='left'>{data}</td>
          <td style='border-right:solid black 1px'>{valor}</td>
        </tr>
        <tr>
          <td class="danfe_item_ultimo" colspan='9'>&nbsp;</td>
        </tr>
      </tab
      `;
      return html;
    },
    makeTaxes(item) {
      let html = `
      <br>
      <h3 class="danfe_titulo_externo">Cálculo do imposto</h3>
      <table class="danfe_tabelas">
      <tr>
        <td class="danfe_celula_bordas">
          <p class="danfe_celula_titulo">Base de cálculo do ICMS</p>
          <p class="danfe_celula_valor">0,00</p>
        </td>
        <td class="danfe_celula_bordas">
          <p class="danfe_celula_titulo">Valor do ICMS</p>
          <p class="danfe_celula_valor">0,00</p>
        </td>
        <td class="danfe_celula_bordas">
          <p class="danfe_celula_titulo">Base de cálculo do ICMS Subst.</p>
          <p class="danfe_celula_valor">0,00</p>
        </td>
        <td class="danfe_celula_bordas">
          <p class="danfe_celula_titulo">Valor do ICMS Subst.</p>
          <p class="danfe_celula_valor">0,00</p>
        </td>
        <td class="danfe_celula_bordas">
          <p class="danfe_celula_titulo">Valor total dos produtos</p>
          <p class="danfe_celula_valor">${item.vProd}</p>
        </td>
      </tr>
      </table>
      <table class="danfe_tabelas">
      <tr>
        <td class="danfe_celula_bordas no-border-top">
          <p class="danfe_celula_titulo">Valor do frete</p>
          <p class="danfe_celula_valor">0,00</p>
        </td>
        <td class="danfe_celula_bordas no-border-top">
          <p class="danfe_celula_titulo">Valor do seguro</p>
          <p class="danfe_celula_valor">0,00</p>
        </td>
        <td class="danfe_celula_bordas no-border-top">
          <p class="danfe_celula_titulo">Desconto</p>
          <p class="danfe_celula_valor">0,00</p>
        </td>
        <td class="danfe_celula_bordas no-border-top">
          <p class="danfe_celula_titulo">Outras despesas acessórias</p>
          <p class="danfe_celula_valor">0,00</p>
        </td>
        <td class="danfe_celula_bordas no-border-top">
          <p class="danfe_celula_titulo">Valor do IPI</p>
          <p class="danfe_celula_valor">0,00</p>
        </td>
        <td class="danfe_celula_bordas no-border-top">
          <p class="danfe_celula_titulo">Valor total da nota</p>
          <p class="danfe_celula_valor">${item.vNF}</p>
        </td>
      </tr>
      </table>
      `;
      return html;
    },
    makeTransp(item) {
      let html = `
      <br>
      <h3 class="danfe_titulo_externo">Transportador/Volumes transportados</h3>
      <table class="danfe_tabelas">
        <tr>
          <td class="danfe_celula_bordas">
            <p class="danfe_celula_titulo">Nome</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
          <td class="danfe_celula_bordas">
            <p class="danfe_celula_titulo">Frete por conta</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
          <td class="danfe_celula_bordas">
            <p class="danfe_celula_titulo">Código ANTT</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
          <td class="danfe_celula_bordas">
            <p class="danfe_celula_titulo">Placa do veículo</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
          <td class="danfe_celula_bordas">
            <p class="danfe_celula_titulo">UF</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
          <td class="danfe_celula_bordas">
            <p class="danfe_celula_titulo">CNPJ/CPF</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
        </tr>
      </table>
      <table class="danfe_tabelas">
        <tr>
          <td class="danfe_celula_bordas no-border-top">
            <p class="danfe_celula_titulo">Endereço</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
          <td class="danfe_celula_bordas no-border-top">
            <p class="danfe_celula_titulo">Município</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
          <td class="danfe_celula_bordas no-border-top">
            <p class="danfe_celula_titulo">UF</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
          <td class="danfe_celula_bordas no-border-top">
            <p class="danfe_celula_titulo">Inscrição Estadual</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
        </tr>
      </table>
      <table class="danfe_tabelas">
        <tr>
          <td class="danfe_celula_bordas no-border-top">
            <p class="danfe_celula_titulo">Quantidade</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
          <td class="danfe_celula_bordas no-border-top">
            <p class="danfe_celula_titulo">Espécie</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
          <td class="danfe_celula_bordas no-border-top">
            <p class="danfe_celula_titulo">Marca</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
          <td class="danfe_celula_bordas no-border-top">
            <p class="danfe_celula_titulo">Numeração</p>
            <p class="danfe_celula_valor">&nbsp;</p>
          </td>
          <td class="danfe_celula_bordas no-border-top">
            <p class="danfe_celula_titulo">Peso bruto</p>
            <p class="danfe_celula_valor">0,00</p>
          </td>
          <td class="danfe_celula_bordas no-border-top">
            <p class="danfe_celula_titulo">Peso líquido</p>
            <p class="danfe_celula_valor">0,00</p>
          </td>
        </tr>
      </table>
      `;
      return html;
    },
    makeItems(item) {
      let html = `
      <br>
      <h3 class="danfe_titulo_externo">Itens da nota fiscal</h3>
      <table class="danfe_item_cabecalho_tabela">
        <tr>
          <th class="danfe_item_cabecalho">Código</th>
          <th class="danfe_item_cabecalho">Descrição do produto/serviço</th>
          <th class="danfe_item_cabecalho">NCM/SH</th>
          <th class="danfe_item_cabecalho">CST</th>
          <th class="danfe_item_cabecalho">CFOP</th>
          <th class="danfe_item_cabecalho">UN</th>
          <th class="danfe_item_cabecalho">Qtde</th>
          <th class="danfe_item_cabecalho">Preço un</th>
          <th class="danfe_item_cabecalho">Preço total</th>
          <th class="danfe_item_cabecalho">BC ICMS</th>
          <th class="danfe_item_cabecalho">Vlr.ICMS</th>
          <th class="danfe_item_cabecalho">Vlr.IPI</th>
          <th class="danfe_item_cabecalho">%ICMS</th>
          <th class="danfe_item_cabecalho">%IPI</th>
        </tr>
      `;
      
      item.items.forEach((i) => {
        html += `
          <tr class="danfe_item">
            <td align='left'>${i.product.cProd || ''}</td>
            <td align='left'>${i.product.xProd || ''}</td>
            <td>${i.product.NCM || ''}</td>
            <td>${i.tax.icms_CSOSN || ''}</td>
            <td>${i.product.CFOP || ''}</td>
            <td>${i.product.uCom || ''}</td>
            <td>${i.product.qCom || ''}</td>
            <td>${i.product.vUnCom || ''}</td>
            <td>${i.product.vProd || ''}</td>
            <td>0,00</td>
            <td>0,00</td>
            <td>0,00</td>
            <td>0,00</td>
            <td>0,00</td>
          </tr>
        `;
      })

      html += `
        <tr>
          <td class="danfe_item_ultimo" colspan='14'>&nbsp;</td>
        </tr>
      </table>
      `;
      return html;
    },
    makeInfoCompl(item) {
      let html = `
      <div class="info-compl">
        <div class="danfe_titulo_externo">Dados adicionais</div>
        <table class="danfe_tabelas" style="min-height:80px">
          <tr>
            <td class="danfe_celula_bordas" width="70%">
              <p class="danfe_celula_titulo">Observações</p>
              <p class="danfe_celula_valor">${item.infCpl || ''}</p>
            </td>
            <td class="danfe_celula_bordas">
              <p class="danfe_celula_titulo">Reservado ao fisco</p>
            </td>
          </tr>
        </table>
      </div>
      `;
      return html;
    },
  },
};

export default PrintDanfeNFe;
