<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="680"
  >
    <v-card>
      <v-card-title class="headline">
        Informações do Certificado Digital
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-progress-linear
              indeterminate
              color="primary darken-2"
              :active="loading" />
          </v-col>
        </v-row>
        <v-row mt="5">
          <v-col md="8">
            <h4>Razão Social:</h4>
            <small>{{ infoCertificate.name }}</small>
          </v-col>

          <v-col md="4">
            <h4>CNPJ:</h4>
            <small>{{ formatCNPJ(infoCertificate.cnpj) }}</small>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="3">
            <h4>Data Inicial:</h4>
            <small>{{ formatDate(infoCertificate.date_initial) }}</small>
          </v-col>

          <v-col md="3">
            <h4>Data Final:</h4>
            <small>{{ formatDate(infoCertificate.date_final) }}</small>
          </v-col>

          <v-col md="3">
            <h4>Situação:</h4>
            <small>{{ infoCertificate.expired }}</small>
          </v-col>
        </v-row>
        <v-divider mt="5"></v-divider>
      </v-card-text>

      <v-card-title class="headline">
        Novo Certificado Digital
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-file-input
              v-model="certificate"
              hide-details="auto"
              label="Certificado Digital">
            </v-file-input>
          </v-col>
          <v-col>
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              label="Senha"
              counter
              @click:append="show = !show"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small @click="$emit('close')">Fechar</v-btn>
        <v-btn small dark color="primary" @click="action()" :loading="loadingBtn">Enviar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { CompaniesCommands } from '../services/CompanyService';
import { messageErrors, formatCNPJ, formatDate } from '@/utils';

export default {
  name: 'Dialog',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: 'Deseja realmente excluir este registro?'
    }
  },
  data() {
    return {
      service: CompaniesCommands(),
      show: false,
      password: '',
      certificate: '',
      infoCertificate: {},
      formatDate: formatDate,
      formatCNPJ: formatCNPJ,
      loading: false,
      loadingBtn: false,
    }
  },
  mounted() {
  },
  watch: {
    dialog() {
      if(this.dialog)
        this.getInfoCertificate();
    }
  },
  methods: {
    action() {
      this.loadingBtn = true;
      const payload = {
        certificate: this.certificate,
        password: this.password,
      }
      
      const id = JSON.parse(localStorage.getItem('siscomge.user')).company_id;
      
      this.service.uploadCertificate(id, payload).then(() => {
        this.$noty.success('Atualizado com Sucesso!');
        this.loadingBtn = false;
        this.$emit('close');
      }).catch((err) => {
        this.$noty.error(messageErrors(err));
        this.loadingBtn = false;
        this.$emit('close');
      });
    },
    getInfoCertificate() {
      this.loading = true;
      this.service.infoCertificate().then((res) => {
        this.infoCertificate = res.data;
        this.loading = false;
      }).catch((err) => {
        this.$noty.error(messageErrors(err));
        this.loading = false;
      });
      // this.$emit('close');
    },
  }
}
</script>