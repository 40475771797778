const money_options = {
  locale: "pt-BR",
  prefix: "R$",
  suffix: "",
  length: 11,
  precision: 2
}

export default {
  domain: 'services',
  title: 'Serviços',
  description: 'Lista de todos os tipos de serviços cadastrados no sistema.',
  singleSelect: true,
  customSearch: true,
  routes: {
    list: {
      name: 'services',
      path: '/servicos',
    },
    create: {
      name: 'service-create',
      path: '/servicos/novo',
    },
    show: {
      name: 'service-show',
      path: '/servicos/:id',
    }
  },
  show: true,
  destroy: true,
  create: true,
  noBetween: true,
  noEquals: true,
  searchCol: 4,
  fields: [
    {
      id: 'id',
      type: 'number',
      name: 'id',
      label: 'Código',
      align: '',
      md: '2',
      list: true,
    },
    {
      id: 'id',
      type: 'select',
      name: 'category_id',
      formattedName: 'categories.description',
      label: 'Categoria',
      item_id: 'id',
      item_text: 'description',
      align: '',
      md: '3',
      list: true,
    },
    {
      id: 'id',
      type: 'select',
      name: 'subcategory_id',
      formattedName: 'subcategories.description',
      label: 'Sub Categoria',
      item_id: 'id',
      item_text: 'description',
      align: '',
      md: '3',
      list: true,
    },
    {
      id: 'id',
      type: 'text',
      name: 'name',
      label: 'Nome',
      md: '4',
      required: true,
      list: true,
    },
    {
      id: 'id',
      type: 'money',
      name: 'price_var',
      formattedName: 'price_var_formatted',
      label: 'Preço Varejo',
      money_options,
      md: '3',
      required: true,
      list: true,
    },
    {
      id: 'id',
      type: 'money',
      name: 'price_ata',
      formattedName: 'price_ata_formatted',
      label: 'Preço Atacado',
      money_options,
      md: '3',
      list: true,
    },
  ]
}