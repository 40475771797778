<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  name: 'bar',
  props: {
    labels: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: '#069'
    },
    values: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: '',
      require: true
    },
  },
  mounted () {
    this.renderChart({
      labels: this.labels,
      datasets: [
        {
          label: this.title,
          backgroundColor: this.color,
          data: this.values
        },
      ]
    }, {responsive: true, maintainAspectRatio: false})
  }
}
</script>

<style>

</style>