import DynamicService from '../../../service/DynamicService';
import { type_register } from '@/constants/options';
import { formatPhone, getText } from '@/utils';

const formatResponse = (item) => {
  item.type_register_formatted = getText(type_register, item.type_register);
  item.phone_formatted = formatPhone(item.phone);
  item.cell_phone_formatted = formatPhone(item.cell_phone);
}

const RegisterService = DynamicService('registers', {
  formatResponse
});

export default RegisterService ;