import { routes as products } from './Products';
import { routes as type_stocks } from './TypeStock';
import { routes as movements } from './Movements';
import { routes as variations } from './Variations';

export default [
  ...products,
  ...type_stocks,
  ...movements,
  ...variations,
]