import AccountPaymentSchema from './schemas/AccountPaymentSchema';

import AccountPayments from './pages/AccountPayments';
import AccountPaymentForm from './pages/AccountPaymentForm';

export default [
  {
    path: AccountPaymentSchema.routes.list.path,
    name: AccountPaymentSchema.routes.list.name,
    component: AccountPayments,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: AccountPaymentSchema.routes.create.path,
    name: AccountPaymentSchema.routes.create.name,
    component: AccountPaymentForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: AccountPaymentSchema.routes.show.path,
    name: AccountPaymentSchema.routes.show.name,
    component: AccountPaymentForm,
    meta:{
      requiresAuth: true,
    }
  },
]