<template>
  <div>
    <SimpleCard 
      :schema="schema">
      <v-row>
        <v-col>
          <div class="sale-order-header mt-2">
            <div class="d-flex">
              <h4 class="mx-2">Qtd Itens: {{ totalizers.qtdItems }}</h4>
              <h4 class="mx-2">Qtd Volumes: {{ totalizers.qtdVolumes }}</h4>
              <h4 class="mx-2">Valor Total: {{ utils.formatCurrency(totalizers.valueTotal) }}</h4>
              <h4 class="mx-2">Desconto: {{ utils.formatCurrency(totalizers.valueDiscount) }}</h4>
              <h4 class="mx-2">Valor Final:  {{ utils.formatCurrency(totalizers.valueFinal) }}</h4>
              <h4 class="mx-2">Total Pago:  {{ utils.formatCurrency(totalizers.valuePay) }}</h4>
              <h4 class="mx-2">Falta Pagar:  {{ utils.formatCurrency(totalizers.lackPay) }}</h4>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-card class="mt-3">
        <v-card-title>
          <h3>Dados Gerais</h3>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col md="2">
              <DataPick
                outlined
                dense
                no-icon
                model-name="orderDate"
                label="Data de Entrada"
                :model="orderService.orderDate"
                :readonly="orderServiceFinished()"
                @changeDataPick="changeDataPick" />
            </v-col>

            <v-col md="2">
              <DataPick
                outlined
                dense
                no-icon
                model-name="exitDate"
                label="Data de Entrega"
                :model="orderService.exitDate"
                :readonly="orderServiceFinished()"
                @changeDataPick="changeDataPick" />
            </v-col>

            <v-col md="2">
              <v-select 
                v-model="orderService.typeOrder" 
                hide-details="auto"
                label="Tipo"
                outlined
                dense
                :readonly="componentShow || orderServiceFinished()"
                :items="typeOrder">
              </v-select>
            </v-col>

            <v-col>
              <v-autocomplete
                v-model="orderService.customer"
                outlined
                dense
                hide-no-data
                item-text="text"
                item-value="id"
                label="Cliente"
                return-object
                :items="autocomletCustomer.items"
                :loading="autocomletCustomer.loading"
                :search-input.sync="autocomletCustomer.search"
                :readonly="orderServiceFinished()"
                @keyup="autoCompleteKeyUp({item: 'autocomletCustomer', domain: 'registers', type: 'customer'})" />
            </v-col>

            <v-col>
              <v-autocomplete
                v-model="orderService.seller"
                outlined
                dense
                hide-no-data
                item-text="text"
                item-value="id"
                label="Vendedor"
                return-object
                :items="autocomletSeller.items"
                :loading="autocomletSeller.loading"
                :search-input.sync="autocomletSeller.search"
                :readonly="orderServiceFinished()"
                @keyup="autoCompleteKeyUp({item: 'autocomletSeller', domain: 'registers', type: 'employee'})" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-3">
        <v-card-title>
          <h3>Itens</h3>
        </v-card-title>

        <v-card-text>
          <div class="d-flex justify-end">
            <v-btn 
            rounded 
            small 
            color="primary"
            :disabled="orderServiceFinished()"
            @click="dialogItem = true">
              <v-icon small class="mr-2">{{ icons.plus }}</v-icon>
              Novo Item
            </v-btn>
          </div>

          <v-data-table
            item-key="id"
            :headers="headersItems"
            :items="orderService.items"
            :hide-default-footer="true"
            :disable-pagination="true"
            dense
            class="elevation-1 mt-3"
          >
            <template v-slot:[`item.qtd`]="props">
              <div class="text-value-single">
                <vuetify-money
                  v-if="true"
                  v-model="props.item.qtd"
                  dense
                  :outlined="false"
                  :options="decimal_options"
                  :readonly="!props.item.newItem"
                  @input="changeQtd(props.index, $event)" />
                  <p v-else>{{ props.item.qtd }}</p>
              </div>
            </template>

            <template v-slot:[`item.value_single_formatted`]="props">
              <div class="text-value-single">
                <vuetify-money
                  v-if="true"
                  v-model="props.item.value_single"
                  dense
                  :outlined="false"
                  :options="money_options"
                  :readonly="!props.item.newItem"
                  @input="changeValueSingle(props.index, $event)" />
                  <p v-else>{{ props.item.value_single_formatted }}</p>
              </div>
            </template>

            <template v-slot:[`item.delete`]="props">
              <v-btn 
                class="mx-1 my-1" icon 
                fab 
                color="red" 
                x-small
                :disabled="orderServiceFinished()"
                @click="removeItem(props.index)">
                <v-icon dark>{{ icons.delete }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-card class="mt-3" v-if="orderService.payments.length">
        <v-card-title>
          <h3>Pagamentos</h3>
        </v-card-title>
        <v-card-text>
          <v-data-table
            item-key="id"
            :headers="headersPayments"
            :items="orderService.payments"
            :hide-default-footer="true"
            :disable-pagination="true"
            dense
            class="elevation-1 mt-3" />
        </v-card-text>
      </v-card>

      <v-card class="mt-3">
        <v-card-title>
          <h3>Desconto e Observações</h3>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col md="2">
              <vuetify-money
                label="Desconto"
                v-model="discount"
                dense
                outlined
                :readonly="orderServiceFinished()"
                :options="money_options" />
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col>
              <v-textarea
                v-model="observations"
                outlined
                dense
                label="Observações"
                :readonly="orderServiceFinished()" />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn 
                small
                rounded
                color="primary"
                :disabled="!orderService.items.length || orderServiceFinished()"
                @click="dialogSave = true">
                Salvar
              </v-btn>
              <v-btn 
                small
                rounded
                color="success" 
                class="ml-3"
                :disabled="!orderService.items.length || orderServiceFinished()"
                @click="dialogPayments = true">
                Pagamento
              </v-btn>
              <v-btn 
                small
                rounded
                color="secondary"
                class="ml-3"
                :disabled="!orderService.items.length || (totalizers.valuePay <= totalizers.lackPay) || orderServiceFinished()"
                @click="dialogFinish = true">
                Finalizar Pedido
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </SimpleCard>

    <DialogItem
      ref="dialogItem"
      :dialog="dialogItem"
      :typeOrder="orderService.typeOrder"
      @action="actionDialog"
      @close="dialogItem = false" />

    <DialogDestroy
      message='Deseja Realmente Salvar a Ordem de Serviço?'
      :dialog="dialogSave"
      :colorButtons="{noBtn: '', yesBtn: 'primary'}"
      :loading="loadingSave"
      :max-width="560"
      @noAction="dialogSave = false"
      @yesAction="saveOrder" />

    <DialogDestroy
      message='Deseja Realmente Finalizar a Ordem de Serviço?'
      :dialog="dialogFinish"
      :colorButtons="{noBtn: '', yesBtn: 'primary'}"
      :loading="loadingFinish"
      :max-width="570"
      @noAction="dialogFinish = false"
      @yesAction="finishOrder" />

      <DialogPayments 
        ref="dialogPayments"
        :dialog="dialogPayments"
        :totalizers="totalizers"
        :payments="orderService.payments"
        :saleOrderIsFinished="false"
        @mountPayment="mountPayment"
        @closeDialogPayments="dialogPayments = false" />
  </div>
</template>

<script>
/* eslint-disable */
import _ from 'lodash';
import { formatCurrency } from '@/utils';
import { type_sale, order_services_status } from '@/constants/options';
import { plus, destroy } from '@/constants/icons';
import SimpleCard from '@/components/SimpleCard';
import DataPick from '@/components/DataPick';
import DialogItem from '../components/DialogItem';
import AutoComplete from '@/mixins/AutoComplete'
import TypeComponents from '@/mixins/TypeComponents'
import OrderServiceSchema from '../schemas/OrderServiceSchema';
import DialogDestroy from '@/components/DialogDestroy';
import OrderServicesService from '../services/OrderServiceService'
import DialogPayments from '@/components/DialogPayments'

export default {
  name: 'CreateOrderService',
  components: {
    SimpleCard,
    DataPick,
    DialogItem,
    DialogDestroy,
    DialogPayments,
  },
  data () {
    return {
      icons: {
        plus: plus,
        delete: destroy,
      },
      utils: { 
        formatCurrency: formatCurrency,
      },
      typeOrder: type_sale,
      schema: OrderServiceSchema,
      service: OrderServicesService,
      orderService: {
        orderDate: new Date().toISOString().substr(0, 10),
        exitDate: new Date().toISOString().substr(0, 10),
        typeOrder: 'retail',
        customer: {},
        seller: {},
        removedItems: [],
        items: [],
        totalizers: {},
        observations: '',
        payments: [],
        finished: false,
      },
      autocomletCustomer: {
        loading: false,
        search: '',
        items: [],
      },
      autocomletSeller: {
        loading: false,
        search: '',
        items: [],
      },
      dialogItem: false,
      dialogSave: false,
      loadingSave: false,
      dialogFinish: false,
      loadingFinish: false,
      dialogPayments: false,
      headersItems: [
        { text: 'Nº Item', value: 'item_number', },
        { text: 'Produto', value: 'name', },
        { text: 'Qtd', value: 'qtd', },
        { text: 'Valor Uni.', value: 'value_single_formatted', },
        { text: 'Valor Total', value: 'value_total_formatted', },
        { text: 'Excluir', value: 'delete', },
      ],
      headersPayments: [
        {text: 'Data', value: 'payment_date_formatted'},
        {text: 'Forma de Pagamento', value: 'payment_method'},
        {text: 'Bandeira', value: 'flag'},
        {text: 'Valor Pago', value: 'payment_value_formatted'},
      ],
      discount: 0,
      observations: '',
      totalizers: {
        qtdItems: 0,
        qtdVolumes: 0,
        valueTotal: 0,
        valueDiscount: 0,
        valueFinal: 0,
        valuePay: 0,
        lackPay: 0,
      },
      money_options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2
      },
      decimal_options: {
        locale: "pt-BR",
        prefix: "",
        suffix: "",
        length: 11,
        precision: 2
      },
    }
  },
  watch: {
    discount: {
      handler() {
        this.totalizers.valueDiscount = parseFloat(this.discount);
        this.calculateTotalizers();
      },
      deep: true,
    },
    observations: {
      handler() {
        this.orderService.observations = this.observations;
      },
      deep: true,
    },
  },
  mounted() {
    if(this.componentShow)
      this.getOrderService()
  },
  mixins: [AutoComplete, TypeComponents],
  methods: {
    changeDataPick(data) {
      this.orderService[data.model] = data.date;
    },
    actionDialog(data) {
      this[data.action](data.params);
      this[data.close] = false
    },
    addItem(params) {
      const { item, newItem } = _.cloneDeep(params);

      if(newItem)
        this.$refs.dialogItem.resetItem();

      this.orderService.items.push({
        id: item.id,
        service_id: item.service_id,
        item_number: this.getNumberItem(),
        name: item.name,
        qtd: item.qtd,
        value_single: item.price_unity,
        value_single_formatted: formatCurrency(item.price_unity),
        value_total: item.total_value,
        value_total_formatted: formatCurrency(item.total_value),
        newItem
      })

      this.calculateTotalizers();
    },
    removeItem(item) {
      this.orderService.removedItems.push(this.orderService.items[item]);
      this.orderService.items.splice(item, 1);
      
      this.orderService.items.map((item, index) => {
        item.item_number = index + 1;
        return item;
      });
      console.log(item)
      console.log(this.orderService)
      this.calculateTotalizers();
    },
    changeQtd(index, qtd){
      this.orderService.items[index].value_total = qtd * this.orderService.items[index].value_single;
      this.orderService.items[index].value_total_formatted = formatCurrency(parseFloat(this.orderService.items[index].value_total));
      this.calculateTotalizers();
    },
    changeValueSingle(index, value){
      this.orderService.items[index].value_total = value * this.orderService.items[index].qtd;
      this.orderService.items[index].value_total_formatted = formatCurrency(parseFloat(this.orderService.items[index].value_total));
      this.calculateTotalizers();
    },
    calculateTotalizers() {
      this.resetTotalizers();
      this.orderService.items.forEach((item) => {
        this.totalizers.qtdItems++;
        this.totalizers.qtdVolumes += parseFloat(item.qtd);
        this.totalizers.valueTotal += parseFloat(item.value_total);
      });

      this.totalizers.valueFinal = parseFloat(this.totalizers.valueTotal)-parseFloat(this.totalizers.valueDiscount);
      this.totalizers.lackPay = parseFloat(this.totalizers.valueFinal)-parseFloat(this.totalizers.valuePay);
      this.orderService.totalizers = this.totalizers;
    },
    resetTotalizers() {
      this.totalizers.qtdItems = 0;
      this.totalizers.qtdVolumes = 0;
      this.totalizers.valueTotal = 0;
      this.totalizers.valueFinal = 0;
    },
    getNumberItem(valueInitial = true) {
      return parseFloat(this.orderService.items.length + 1);
    },
    getOrderService() {
      const { id } = this.$route.params;
      this.changeLoadings();

      this.service.show(id).then((res) => {
        res.items.forEach((item) => {
          this.orderService.orderDate = res.order_date;
          this.orderService.exitDate = res.delivery_date;
          this.orderService.typeOrder = res.type_order;
          this.discount = res.value_discount;
          this.observations = res.observations;
          this.orderService.order_service_status = res.order_service_status;

          this.changeItems(item);
        });

        res.payments.forEach((item) => {
          this.totalizers.valuePay += parseFloat(item.value_final);
          this.orderService.payments.push({...item, new: false});
        });

        this.$refs.dialogPayments.changePayments(this.orderService.payments);
        this.setCustomerAndSeller(res.customer, res.seller);
        this.changeLoadings();
      }).catch((err) => {
        this.changeLoadings();
        console.log(err)
      });
    },
    changeLoadings() {
      this.autocomletCustomer.loading = !this.autocomletCustomer.loading;
      this.autocomletSeller.loading = !this.autocomletSeller.loading;
    },
    setCustomerAndSeller(customer, seller) {
      this.autocomletCustomer.items = [{id: customer.id, text: customer.name}]
      this.orderService.customer = {id: customer.id, text: customer.name};
      this.autocomletSeller.items = [{id: seller.id, text: seller.name}]
      this.orderService.seller = {id: seller.id, text: seller.name};
    },
    saveOrder() {
      this.loadingSave = true;
      if(this.componentCreate) {
        this.createOrder();
      }else{
        this.updateOrder();
      }
    },
    createOrder() {
      this.service.create(this.orderService).then((res) => {
        this.$noty.success("Ordem Concluída com Sucesso.");
        this.loadingSave = false;
        this.dialogSave = false;
        this.$router.push({name: this.schema.routes.list.name});
      }).catch((err) => {
        this.loadingSave = false;
        this.dialogSave = false;
        this.$noty.error(err);
      });
    },
    updateOrder() {
      const { id } = this.$route.params;
      this.service.update(id, this.orderService).then((res) => {
        this.$noty.success("Ordem Atualizada com Sucesso");
        this.loadingSave = false;
        this.dialogSave = false;
        this.$router.push({name: this.schema.routes.list.name});
      }).catch((err) => {
        this.loadingSave = false;
        this.dialogSave = false;
        this.$noty.error(err);
      });
    },
    mountPayment(params) {
      this.dialogPayments = false
      this.orderService.payments = params.payments;
      this.calculatePayments();
    },
    finishOrder() {
      this.loadingFinish = true;
      this.orderService.finished = true;
      this.saveOrder();
    },
    calculatePayments() {
      let valueTotalPaid = 0;

      this.orderService.payments.forEach((item) => {
        valueTotalPaid += parseFloat(item.value_final);
      });
      
      this.totalizers.valuePay = valueTotalPaid;
      this.calculateTotalizers();
    },
    changeItems(item) {
      const param = {
        id: item.id,
        service_id: item.service.id,
        item_number: item.item_number,
        name: item.name,
        qtd: item.qtd,
        price_unity: item.value_single,
        total_value: item.value_total,
      }
      this.addItem({item: param, newItem: false});
    },
    orderServiceFinished() {
      return this.orderService.order_service_status == order_services_status[1].value || false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
