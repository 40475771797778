import AllTypes from '@/business/AllTypes';

export default {
  domain: 'all_types',
  business: AllTypes,
  title: 'Tipos Gerais',
  description: 'Nesta tela você pode cadastrar diversos tipos de dados.',
  singleSelect: true,
  routes: {
    list: {
      name: 'alltypes',
      path: '/tipos-gerais',
    },
    create: {
      name: 'alltype-create',
      path: '/tipos-gerais/novo',
    },
    show: {
      name: 'alltype-show',
      path: '/tipos-gerais/:id',
    }
  },
  show: true,
  destroy: true,
  create: true,
  noBetween: true,
  noEquals: false,
  searchCol: 4,
  equalsCol: 3,
  filtersParams: {
    ordering: {
      column: "description",
      value: "asc"
    },
    equals: { 
      type: 'equals',
      column: 'type'
    }
  },
  fields: [
    {
      type: 'select',
      name: 'type',
      formattedName: 'type_formatted',
      label: 'Tipo',
      md: '4',
      required: true,
      list: true,
    },
    {
      type: 'text',
      name: 'description',
      label: 'Descrição',
      md: '8',
      required: true,
      list: true,
    },
    {
      type: 'text',
      name: 'global',
      formattedName: 'global_formatted',
      label: 'Global',
      md: '8',
      required: true,
      list: true,
      noForm: true,
    },
  ]
}