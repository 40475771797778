<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600"
    class="dialog-custom"
  >
  <v-card>
      <v-card-title class="headline">
        Informações do Certificado Digital
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="mt-3">
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.series"
              label="Série"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="form.initial_number"
              label="Número Inicial"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="form.final_number"
              label="Número Final"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small @click="$emit('close', 'dialogDisableNumbersNFe')">Fechar</v-btn>
        <v-btn small dark color="primary" @click="$emit('disableNumbersNFe', form)" :loading="loadingBtn">Enviar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'DialogDisableNumbersNFe',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    loadingBtn: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: true,
      form: {
        series: '',
        initial_number: '',
        final_number: '',
      }
    }
  },
  watch: {
  },
  mounted() {
  },
}
</script>
<style scoped>
</style>