import axios from 'axios';
import DynamicService from '@/service/DynamicService';
import { formatCurrency, formatDate, getText } from '@/utils';
import { sale_order_status, type_sale } from '@/constants/options';

const formatResponse = (item) => {
  if(item.payments.length) {
    item.payments.forEach((payment) => {
      payment.payment_date_formatted = formatDate(payment.payment_date);
      payment.payment_method = payment.payment_method.description;
      payment.payment_value_formatted = formatCurrency(payment.value_final);
    });
  }

  item.order_date_formatted = formatDate(item.order_date);
  item.sale_order_status_formatted = getText(sale_order_status, item.sale_order_status);
  item.value_total_formatted = formatCurrency(item.value_total);
  item.value_discount_formatted = formatCurrency(item.value_discount);
  item.value_final_formatted = formatCurrency(item.value_final);
  item.value_paid = 0;
  item.value_paid_formatted = formatCurrency(0);
  item.value_pending = 0;
  item.value_pending_formatted = formatCurrency(0);
  item.type_sale_formatted = getText(type_sale, item.type_sale)
}

const formatRequest = (item) => {
  item.payments = item.payments.map((payment) => {
    return {
      ...payment,
      value_total: parseFloat(payment.value_total).toFixed(2),
      value_discount: parseFloat(payment.value_discount).toFixed(2),
      value_final: parseFloat(payment.value_final).toFixed(2),
    }
  })
  item.totalizers = {
    ...item.totalizers,
    valueTotal: parseFloat(item.totalizers.valueTotal).toFixed(2),
    valueDiscount: parseFloat(item.totalizers.valueDiscount).toFixed(2),
    valueFinal: parseFloat(item.totalizers.valueFinal).toFixed(2),
    valuePay: parseFloat(item.totalizers.valuePay).toFixed(2),
    lackPay: parseFloat(item.totalizers.lackPay).toFixed(2),
  }
}

const formatResponseGetItem = (data) => {
  return data.map((item) => {
    item.order_date_formatted = formatDate(item.order_date);
    item.value_single_formatted = formatCurrency(item.value_single);
    item.value_discount_formatted = formatCurrency(item.value_discount);
    item.value_total_formatted = formatCurrency(item.value_total);
    return item;
  });
}

const SaleOrderService = DynamicService('sale-orders', {
  formatResponse,
  formatRequest
});

const SaleOrderCommands = () => ({
  async makeInvoice(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`invoices`, payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async getItemByBarCodeAndCustomerId(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`sale-orders/get-items-by-bar-code-customer-id`, payload).then((res) => {
        resolve(formatResponseGetItem(res.data));
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
});

export default SaleOrderService;
export { SaleOrderService, SaleOrderCommands };
