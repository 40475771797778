<template>
  <div>
    <SimpleCard 
      :title="schema.title" 
      :schema="schema">

      <DynamicDetailsPage
        :schema="schema"
        :service="service"
        :type-page="typePage"
        :items-selects="{ bank_id: banks }"
        @save="save" />
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicDetailsPage from '@/components/DynamicDetailsPage'
import MovementHistorySchema from '../schemas/MovementHistorySchema';
import MovementHistoryService from '../services/MovementHistoryService';
import BankService from '@/pages/Financial/Banks/services/BankService';

export default {
  name: 'MovementHistoryForm',
  components: {
    SimpleCard,
    DynamicDetailsPage,
  },
  data () {
    return {
      schema: MovementHistorySchema,
      service: MovementHistoryService,
      bankService: BankService,
      typePage: this.$route.name === 'movementhistory-create' ? 'create' : 'update',
      banks: []
    }
  },
  mounted() {
    this.getBanks();
  },
  methods: {
    getBanks() {
      const params = {
        page: 1,
        totalItemsPerPage: -1,
      }

      this.bankService.index(params).then((res) => {
        this.banks = res.data.data;
      }).catch((err) => {
        console.log(err)
      });
    },
    save() {
      this.$noty.success('Registro salvo com sucesso!');
      this.$router.push({name: this.schema.routes.list.name});
    },
    mountParamsFilter(value) {
      const payload = {
        domain: 'all_types',
        search: '',
        ordering: {
          column: "description",
          value: "asc"
        },
        q: [{ 
          type: 'equals',
          column: 'type',
          value,
        }]
      }
      const paginate = 'page=1&per_page=-1';

      return {
        payload,
        paginate,
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
