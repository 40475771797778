import { formatCurrency } from '@/utils';

export default {
  beforeList: (formValue) => {
    let total_value = 0;
    let total_discount = 0;
    let total_final = 0;
    let total_paid = 0;
    let total_pending = 0;

    formValue.data.forEach((item) => {
      total_value += parseFloat(item.value_total);
      total_discount += parseFloat(item.value_discount);
      total_final += parseFloat(item.value_final);
      total_paid += parseFloat(item.value_paid);
      total_pending += parseFloat(item.value_pending);
      item.disableShow = true;
      item.disableDelete = true ;
    });

    formValue.total_value = formatCurrency(total_value);
    formValue.total_discount = formatCurrency(total_discount);
    formValue.total_final = formatCurrency(total_final);
    formValue.total_paid = formatCurrency(total_paid);
    formValue.total_pending = formatCurrency(total_pending);
  }
}