import { CompanyPreferenceParameterCommands } from '@/pages/Settings/services/CompanyPreferenceParameterService';
const GetCompanyPreferences = {
  data() {
    return {
      companyPreferenceCommands: CompanyPreferenceParameterCommands(),
    }
  },
  methods: {
    getCompanyPreferences(domains) {
      this.companyPreferenceCommands.get_all_by_domain({ domain: domains }).then((res) => {
        const user_logged = JSON.parse(localStorage.getItem('siscomge.user'))
        res.data.forEach((json) => {
          Object.keys(json).forEach((key) => {
            if(key == 'parameter') {
              if(json.user_id) {
                if(json.user_id === user_logged.id) {
                  this[json.function](json.value);
                }
              }else{
                this[json.function](json.value);
              }
            }
          });
        });
      }).catch((err) => {
        console.error(err)
      })
    }
  }
};

export default GetCompanyPreferences;
