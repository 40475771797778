import VariationSchema from './schemas/VariationSchema';

import Variations from './pages/Variations';
import VariationForm from './pages/VariationForm';

export default [
  {
    path: VariationSchema.routes.list.path,
    name: VariationSchema.routes.list.name,
    component: Variations,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: VariationSchema.routes.create.path,
    name: VariationSchema.routes.create.name,
    component: VariationForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: VariationSchema.routes.show.path,
    name: VariationSchema.routes.show.name,
    component: VariationForm,
    meta:{
      requiresAuth: true,
    }
  },
]