import AllTypeSchema from './schemas/AllTypeSchema';
import RegisterSchema from './schemas/RegisterSchema';

import AllTypes from './pages/AllTypes';
import AllTypeForm from './pages/AllTypeForm';
import Registers from './pages/Registers';
import RegisterForm from './pages/RegisterForm';

export default [
  {
    path: AllTypeSchema.routes.list.path,
    name: AllTypeSchema.routes.list.name,
    component: AllTypes,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: AllTypeSchema.routes.create.path,
    name: AllTypeSchema.routes.create.name,
    component: AllTypeForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: AllTypeSchema.routes.show.path,
    name: AllTypeSchema.routes.show.name,
    component: AllTypeForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: RegisterSchema.routes.list.path,
    name: RegisterSchema.routes.list.name,
    component: Registers,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: RegisterSchema.routes.create.path,
    name: RegisterSchema.routes.create.name,
    component: RegisterForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: RegisterSchema.routes.show.path,
    name: RegisterSchema.routes.show.name,
    component: RegisterForm,
    meta:{
      requiresAuth: true,
    }
  },
]