<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">

      <DynamicListPage
        id="content-data-list"
        :schema="schema"
        :service="service"
        :items-equals="itemsEquals"
        :init-item-equal="initItemEqual"
        @eventDataPick="eventDataPick">

        <template slot="custom-header">
          <v-row class="my-3">
            <v-col>
              <v-btn small @click="print()">
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </DynamicListPage>
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage';
import MovementHistorySchema from '../schemas/MovementHistorySchema';
import MovementHistoryService from '../services/MovementHistoryService';
import BankService from '@/pages/Financial/Banks/services/BankService';
import { movement_history_status } from '@/constants/options';
import ParamsEquals from '@/mixins/ParamsEquals'
import PrintMovementHistory from '@/mixins/PrintMovementHistory'

export default {
  name: 'Box',
  components: {
    SimpleCard,
    DynamicListPage,
  },
  watch: {
  },
  data () {
    return {
      schema: MovementHistorySchema,
      service: MovementHistoryService,
      dateInitial: new Date().toISOString().substr(0, 10),
      dateFinal: new Date().toISOString().substr(0, 10),
      bankService: BankService,
      itemsEquals: [],
      banks: [],
      movementHistoryStatus: movement_history_status,
      initItemEqual: [
        {
          name: 'bank_id',
          value: null,
        },
      ],
    }
  },
  mounted() {
    this.getItemsEquals();
  },
  mixins: [ParamsEquals, PrintMovementHistory],
  methods: {
    getItemsEquals() {
      this.bankService.index().then((res) => {
        this.itemsEquals.push({
          name: 'bank_id',
          label: 'Bancos',
          items: this.paramsEquals(res.data.data, 'description', 'id'),
        });

        this.itemsEquals.push({
          name: 'movement_history_status',
          label: 'Status',
          items: this.paramsEquals(this.movementHistoryStatus, 'text', 'value'),
        });
      }).catch((err) => {
        console.error(err);
      });
    },
    eventDataPick(data) {
      this[data.model] = data.date;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss';
</style>
