import { parseStringFloatDecimal } from '@/utils';

const rulesRequired = [
  value => !!value || 'Obrigatório.',
];

const rulesMoney = [
  value => parseStringFloatDecimal(value) || 'Digite valor maior que zero.',
];

const rulesBiggerZero = [
  value => value > 0 || 'Digite valor maior que zero.',
];

export { rulesMoney, rulesBiggerZero, rulesRequired }
