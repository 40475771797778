<template>
  <div>
    <SimpleCard 
      :title="schema.title" 
      :schema="schema">

      <DynamicDetailsPage
        :schema="schema"
        :service="service"
        :type-page="typePage"
        :items-selects="{
          product_variation_type: product_variations,
          stock_id: stocks,
          category_id: categories,
          subcategory_id: subcategories,
          unit_measure_id: unit_measures,
          provider_id: providers,
          }"
        :loading-select="loadingSelect"
        @save="save" />
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicDetailsPage from '@/components/DynamicDetailsPage'
import ProductSchema from '../schemas/ProductSchema';
import ProductService from '../services/ProductService';
import StockService from '../../TypeStock/services/StockService';
import AllTypeService from '../../../Registers/services/AllTypeService';
import RegisterService from '../../../Registers/services/RegisterService';
import MountParamsFilterEquals from '@/mixins/MountParamsFilterEquals';
import { product_variations } from '@/constants/options'

export default {
  name: 'ProductForm',
  components: {
    SimpleCard,
    DynamicDetailsPage,
  },
  data () {
    return {
      schema: ProductSchema,
      service: ProductService,
      stocksService: StockService,
      allTypeService: AllTypeService,
      registerService: RegisterService,
      typePage: this.$route.name === 'product-create' ? 'create' : 'update',
      stocks: [],
      categories: [],
      subcategories: [],
      unit_measures: [],
      product_variations: product_variations,
      providers: [],
      loadingSelect: true,
    }
  },
  mounted() {
    this.getStocks();
    this.getCategories();
    this.getSubCategories();
    this.getUnitMeasures();
    this.getProviders();
  },
  mixins: [MountParamsFilterEquals],
  methods: {
    getStocks() {
      this.stocksService.index().then((res) => {
        this.stocks = res.data.data;
        this.loadingSelect = false;
      }).catch(() => {
        this.loadingSelect = false;
      })
    },
    getCategories() {
      const params = this.paramsFilter('all_types', 'description', 'asc', 'type', 'categories');
      const { payload, paginate } = this.mountParamsFilterEquals(params);
      this.allTypeService.filters(payload, paginate).then((res) => {
        this.categories = res.data.data;
        this.categories.unshift({id: '', description: ''})
        this.loadingSelect = false;
      }).catch(() => {
        this.loadingSelect = false;
      });
    },
    getSubCategories() {
      const params = this.paramsFilter('all_types', 'description', 'asc', 'type', 'subcategories');
      const { payload, paginate } = this.mountParamsFilterEquals(params);
      this.allTypeService.filters(payload, paginate).then((res) => {
        this.subcategories = res.data.data;
        this.subcategories.unshift({id: '', description: ''})
        this.loadingSelect = false;
      }).catch(() => {
        this.loadingSelect = false;
      });
    },
    getUnitMeasures() {
      const params = this.paramsFilter('all_types', 'description', 'asc', 'type', 'unit-measure');
      const { payload, paginate } = this.mountParamsFilterEquals(params);
      this.allTypeService.filters(payload, paginate).then((res) => {
        this.unit_measures = res.data.data;
        this.loadingSelect = false;
      }).catch(() => {
        this.loadingSelect = false;
      });
    },
    getProviders() {
      const params = this.paramsFilter('registers', 'name', 'asc', 'type_register', 'provider');
      const { payload, paginate } = this.mountParamsFilterEquals(params);
      this.registerService.filters(payload, paginate).then((res) => {
        this.providers = res.data.data;
        this.providers.unshift({id: '', name: ''})
        this.loadingSelect = false;
      }).catch(() => {
        this.loadingSelect = false;
      });
    },
    save() {
      this.$noty.success('Registro salvo com sucesso!');
      this.$router.push({name: this.schema.routes.list.name});
    },
    paramsFilter(domain, ordering_column, ordering_value, equals_column, equals_value) {
      return { domain, ordering_column, ordering_value, equals_column, equals_value }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
