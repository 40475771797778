import OrderServiceSchema from './schemas/OrderServiceSchema';
import CreateOrderService from './pages/CreateOrderService';
import OrderServices from './pages/OrderServices';

export default [
  {
    path: OrderServiceSchema.routes.list.path,
    name: OrderServiceSchema.routes.list.name,
    component: OrderServices,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: OrderServiceSchema.routes.create.path,
    name: OrderServiceSchema.routes.create.name,
    component: CreateOrderService,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: OrderServiceSchema.routes.show.path,
    name: OrderServiceSchema.routes.show.name,
    component: CreateOrderService,
    meta:{
      requiresAuth: true,
    }
  },
]