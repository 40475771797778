<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
    class="dialog-custom"
  >
    <v-card class="">
      <v-card-title>
        <h3>Selecione o Vendedor</h3>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <AutoCompleteSearch 
          label='Vendedores'
          :config="config"
          auto-focus
          @actionAutoComplete="actionAutoComplete" />
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small dark color="red" @click="$emit('close')">Cancelar (ESC)</v-btn>
        <v-btn small dark color="primary" @click="action()">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AutoCompleteSearch from '@/components/AutoCompleteSearch';

export default {
  name: 'DialogSeller',
  components: {
    AutoCompleteSearch,
  },
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      this.changeActionKeyUp(e.keyCode);
    });
  },
  data() {
    return {
      config: {
        domain: 'registers',
        ordering: {
          column: 'name',
          value: 'asc'
        },
        q: [{ 
          type: 'equals',
          column: 'type_register',
          value: 'employee'
        }],
      },
      seller: {},
    }
  },
  methods: {
    changeActionKeyUp(keyCode) {
      if(keyCode == 27) {
        this.$emit('close')
      }
    },
    actionAutoComplete(value) {
      this.seller = value;
      this.action();
    },
    action() {
      this.$emit('action', {action: 'changeSellerSelected', params: {seller: this.seller}});
    }
  }
}
</script>
<style scoped>
</style>