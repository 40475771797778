<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    class="dialog-custom"
  >
    <v-card class="">
      <v-form 
        ref="form"
        v-model="valid"
        lazy-validation>
        <v-card-title>
          <h3>Estoques</h3>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-row class="mt-1">
            <v-col>
              <h3>Produto Selecionado: {{ productName }}</h3>
            </v-col>
          </v-row>

          <v-row class="mt-1">
            <v-col>
              <v-select 
                v-model="form.type" 
                hide-details="auto"
                label="Tipo Operação" 
                item-text="text" 
                item-value="value"
                required
                :rules="rules"
                :items="typeStockMove">
                <v-icon slot="prepend">mdi-format-list-bulleted-square</v-icon>
              </v-select>
            </v-col>

            <v-col>
              <v-select 
                v-model="form.stock_id" 
                hide-details="auto"
                label="Estoques" 
                item-text="description" 
                item-value="id"
                required
                :rules="rules" 
                :items="stocks">
                <v-icon slot="prepend">mdi-format-list-bulleted-square</v-icon>
              </v-select>
            </v-col>
            <v-col>
              <div class="content-money">
                <div class="momey-icon"><v-icon>mdi-numeric</v-icon></div>
                <vuetify-money
                  v-model="form.qtd"
                  label="Quant."
                  class="mt-5 money-content"
                  dense
                  required
                  :rules="rulesMoney" 
                  :outlined="false"
                  :options="money_options" />
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small dark color="red" @click="destroy(true)">Fechar</v-btn>
          <v-btn small dark color="primary" @click="save()" :loading="loading">Salvar</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { type_stock_move } from '@/constants/options';
import StockService from '../../TypeStock/services/StockService';

export default {
  name: 'DialogAddStock',
  components: {
  },
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    productName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      valid: true,
      serviceStock: StockService,
      typeStockMove: type_stock_move,
      stocks: [],
      form: {
        type: null,
        stock_id: null,
        qtd: 0,
      },
      rules: [
        value => !!value || 'Obrigatório.',
      ],
      rulesMoney: [
        value => this.parseStringFloatDecimal(value) || 'Digite valor maior que zero.',
      ],
      money_options: {
        locale: "pt-BR",
        prefix: "",
        suffix: "",
        length: 11,
        precision: 4
      }
    }
  },
  mounted() {
    this.getStocks();
  },
  methods: {
    destroy() {
      this.$emit('closeDialog', 'dialogAdd');
    },
    save() {
      if(!this.$refs.form.validate())
        return;

      this.$emit('saveStock', this.form);
    },
    parseStringFloatDecimal(value) {
      value = value.replace('.', '')
      value = value.replace(',', '.')
      return value > 0;
    },
    getStocks() {
      this.stocks = [];
      this.serviceStock.index().then((res) => {
        res.data.data.forEach((item) => {
          this.stocks.push({
            id: item.id,
            description: item.description,
          });
        })
      }).catch(() => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content-money {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .momey-icon {
    margin-right: 10px;
  }

  .money-content {
    width: 100%;
  }
}
</style>