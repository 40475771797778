<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">

      <DynamicListPage
        :schema="schema"
        :service="service"
        :items-equals="itemsEquals">
      </DynamicListPage>
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import AllTypeSchema from '../schemas/AllTypeSchema';
import AllTypeService from '../services/AllTypeService';
import { all_types } from '@/constants/options'
import ParamsEquals from '@/mixins/ParamsEquals'

export default {
  name: 'AllTypes',
  components: {
    SimpleCard,
    DynamicListPage,
  },
  data () {
    return {
      schema: AllTypeSchema,
      service: AllTypeService,
      items: {},
      typeRegisterSelected: '',
      page: 1,
      pageLength: 1,
      totalItemsPagination: 0,
      totalItemsPerPage: 30,
      allTypes: all_types,
      itemsEquals: [],
    }
  },
  watch: {
  },
  mounted() {
    this.mountItemsEquals();
  },
  mixins: [ParamsEquals],
  methods: {
    mountItemsEquals() {
      this.itemsEquals.push({
        name: 'type',
        label: 'Tipo',
        items: this.paramsEquals(this.allTypes, 'text', 'value'),
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
