const type_account_payment_status = [
  {id: 1, text: 'Pendente', value: 'pending'},
  {id: 2, text: 'Pago', value: 'paid'},
];

const yes_no = [
  {id: 1, text: 'Sim', value: 'yes'},
  {id: 2, text: 'Não', value: 'no'},
];

const product_variations = [
  {id: 1, text: 'Único', value: 'single'},
  {id: 2, text: 'Com Variações', value: 'with_variations'},
];

const all_types = [
  {id: 1, text: 'Categorias', value: 'categories', color: 'orange'},
  {id: 2, text: 'Sub-Categorias', value: 'subcategories', color: 'primary'},
  {id: 3, text: 'Tamanhos', value: 'sizes', color: 'green'},
  {id: 4, text: 'Cores', value: 'colors', color: 'green'},
  {id: 5, text: 'Forma de Pagamento', value: 'payment-methods', color: 'green'},
  {id: 6, text: 'Tipos de Contas a Pagar', value: 'account-payment-types', color: 'green'},
  {id: 7, text: 'Bandeiras', value: 'flags', color: 'green'},
  {id: 8, text: 'Unidades de Medidas', value: 'unit-measure', color: 'green'},
];

const type_users = [
  {id: 1, text: 'Administrador', value: 'A'},
  {id: 2, text: 'Gerente', value: 'M'},
  {id: 3, text: 'Padrão', value: 'D'},
];

const type_person = [
  {id: 1, text: 'Pessoa Física', value: 1},
  {id: 2, text: 'Pessoa Jurídica', value: 2},
];

const sale_order_status = [
  {id: 1, text: 'Pendente', value: 'pending'},
  {id: 2, text: 'Finalizado', value: 'finished'},
  {id: 3, text: 'Entregue', value: 'delivered'},
  {id: 4, text: 'Cancelado', value: 'canceled'},
];

const order_services_status = [
  {id: 1, text: 'Pendente', value: 'pending'},
  {id: 2, text: 'Finalizado', value: 'finished'},
  {id: 3, text: 'Entregue', value: 'delivered'},
  {id: 4, text: 'Cancelado', value: 'canceled'},
];

const tax_regime_code = [
  {id: 1, text: 'Simples Nacional', value: '1'},
  {id: 2, text: 'Simples Nacional, excesso sublimite de receita bruta', value: '2'},
  {id: 3, text: 'Regime Normal.', value: '3'},
];

const movement_history_status = [
  {id: 1, text: 'Pendente', value: 'pending'},
  {id: 2, text: 'Feito', value: 'done'},
];

const type_amb = [
  {id: 1, text: 'Produção', value: '1'},
  {id: 2, text: 'Homologação', value: '2'},
];

const nfe_status = [
  {id: 1, text: 'Autorizada', value: '100'},
  {id: 2, text: 'Cancelamento homologado', value: '101'},
  {id: 3, text: 'Cancelamento Registrado', value: '135'},
];

const type_sale = [
  {id: 1, text: 'Varejo', value: 'retail'},
  {id: 2, text: 'Atacado', value: 'wholesale'},
];

const type_register = [
  {id: 1, text: 'Cliente', value: 'customer', color: 'orange'},
  {id: 2, text: 'Fornecedor', value: 'provider', color: 'primary'},
  {id: 3, text: 'Funcionário', value: 'employee', color: 'green'},
];

const type_items = [
  {id: 1, text: 'Produtos', value: 'product', class: 'App\\Models\\Product'},
  {id: 2, text: 'Serviços', value: 'service', class: 'App\\Models\\Service'},
];

const type_origin_boxes = [
  {id: 1, text: 'Pedido de Venda', value: 'App\\Models\\SaleOrder'},
  {id: 1, text: 'Contas a Pagar', value: 'App\\Models\\AccountPayment'},
  {id: 1, text: 'Ordem de Serviço', value: 'App\\Models\\OrderService'},
];

const type_stock_move = [
  {id: 1, text: 'Entrada', value: 'add'},
  {id: 2, text: 'Saída', value: 'rem'},
];

const origin_type_product_stock = [
  {id: 1, text: 'Manual', value: 'App\\Models\\ProductStock'},
  {id: 2, text: 'Pedido de Venda', value: 'App\\Models\\SaleOrder'},
]

const pagarme_transaction_status = [
  {id: 1, text: 'Processando', value: 'processing'},
  {id: 2, text: 'Autorizado', value: 'authorized'},
  {id: 3, text: 'Pago', value: 'paid'},
  {id: 4, text: 'Estornada', value: 'refunded'},
  {id: 5, text: 'Aguardando Pagamento', value: 'waiting_payment'},
  {id: 6, text: 'Aguardando Estorno', value: 'pending_refund'},
  {id: 7, text: 'Recusada', value: 'refused'},
  {id: 8, text: 'Em Protesto', value: 'chargedback'},
  {id: 9, text: 'Análise', value: 'analyzing'},
  {id: 10, text: 'Aguardando Revisão', value: 'pending_review'},
]

const pagarme_payment_link_status = [
  {id: 1, text: 'Ativo', value: 'active'},
  {id: 2, text: 'Cancelado', value: 'canceled'},
  {id: 3, text: 'Expirado', value: 'expired'},
]

const type_boxes = [
  {id: 1, text: 'Entrada', value: 'input'},
  {id: 2, text: 'Saída', value: 'output'}
]

export { 
  type_account_payment_status,
  yes_no,
  product_variations,
  all_types,
  type_amb,
  nfe_status,
  type_users,
  type_person,
  sale_order_status,
  order_services_status,
  tax_regime_code,
  type_sale,
  type_register,
  type_items,
  type_origin_boxes,
  movement_history_status,
  type_stock_move,
  origin_type_product_stock,
  pagarme_transaction_status,
  pagarme_payment_link_status,
  type_boxes,
}