import ReportsSaleOrderSchema from './schemas/ReportsSaleOrderSchema';
import SaleOrders from './pages/SaleOrders';

export default [
  {
    path: ReportsSaleOrderSchema.routes.list.path,
    name: ReportsSaleOrderSchema.routes.list.name,
    component: SaleOrders,
    meta:{
      requiresAuth: true,
    }
  },
]