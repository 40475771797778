
import ReportsSaleOrders from '@/business/ReportsSaleOrders';
import SaleOrderSchema from '@/pages/Operations/SaleOrders/schemas/SaleOrderSchema';

const newSaleOrderSchema = { ...SaleOrderSchema };

newSaleOrderSchema.routes = {
  list: {
    name: 'reportssaleorders',
    path: '/relatorios/pedidos-vendas',
  },
  create: {
  },
  show: {
  },
};
newSaleOrderSchema.business = ReportsSaleOrders;

export default newSaleOrderSchema;
