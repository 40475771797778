<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600"
    class="dialog-custom"
  >
  <v-card>
      <v-card-title class="headline">
        Download dos XML
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="mt-3">
        <v-row>
          <v-col cols="12" sm="12">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="form.date"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.date"
                  label="Selecione o Ano e Mês"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              
              <v-date-picker v-model="form.date" type="month" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancelar
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(form.date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small @click="$emit('close', 'dialogDownloadXML')">Fechar</v-btn>
        <v-btn small dark color="primary" @click="$emit('downloadXML', form)" :loading="loadingBtn">Download</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogDownloadXML',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    loadingBtn: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: true,
      form: {
        date: new Date().toISOString().substr(0, 7),
      },
      menu: false,
      modal: false,
    }
  },
  watch: {
  },
  mounted() {
  },
}
</script>
<style scoped>
</style>