import MovementHistorySchema from './schemas/MovementHistorySchema';
import MovementHistories from './pages/MovementHistories';
import MovementHistoryForm from './pages/MovementHistoryForm';

export default [
  {
    path: MovementHistorySchema.routes.list.path,
    name: MovementHistorySchema.routes.list.name,
    component: MovementHistories,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: MovementHistorySchema.routes.create.path,
    name: MovementHistorySchema.routes.create.name,
    component: MovementHistoryForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: MovementHistorySchema.routes.show.path,
    name: MovementHistorySchema.routes.show.name,
    component: MovementHistoryForm,
    meta:{
      requiresAuth: true,
    }
  },
]