import Stocks from '@/business/Stocks';
import { check } from '@/constants/icons';

export default {
  title: 'Estoques',
  description: 'Lista dos estoques cadastrados.',
  business: Stocks,
  singleSelect: true,
  routes: {
    list: {
      name: 'stocks',
      path: '/estoques',
    },
    create: {
      name: 'stock-create',
      path: '/estoques/novo',
    },
    show: {
      name: 'stock-show',
      path: '/estoques/:id',
    },
    back: {
      name: 'products',
      path: '/produtos',
    }
  },
  show: true,
  destroy: true,
  create: true,
  noBetween: true,
  noEquals: true,
  searchCol: 4,
  moreActions: [
    {
      name: 'setStockDefault',
      label: 'Estoque Padrão',
      icon: check,
      action: 'setStockDefault'
    }
  ],
  fields: [
    {
      type: 'text',
      name: 'description',
      label: 'Descrição',
      md: '12',
      required: true,
      list: true,
    },
    {
      type: 'text',
      name: 'default',
      formattedName: 'default_formatted',
      label: 'Padrão',
      md: '12',
      list: true,
      noForm: true,
    },
  ]
}