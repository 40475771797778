import DynamicService from '@/service/DynamicService';
import { formatCurrency } from '@/utils';

const formatResponse = (item) => {
  item.price_var_formatted = formatCurrency(item.price_var);
  item.price_ata_formatted = formatCurrency(item.price_ata);

  if(!item.categories){
    item.categories = {
      description: '-'
    }
  }

  if(!item.subcategories){
    item.subcategories = {
      description: '-'
    }
  }

  if(item.category){
    item.categories = {
      description: item.category
    }
  }

  if(item.subcategory){
    item.subcategories = {
      description: item.subcategory
    }
  }
}

const ServiceService = DynamicService('services', {
  formatResponse
});

export default ServiceService ;