/* eslint-disable */
const MountParamsFilterEqualsAllTypes = {
  methods: {
    mountParamsFilterEqualsPaymentMethodSettings(value) {
      const payload = {
        domain: 'payment_method_settings',
        search: '',
        ordering: {
          column: "id",
          value: "asc"
        },
        q: [{ 
          type: 'equals',
          column: 'payment_method_id',
          value,
        }]
      }
      const paginate = 'page=1&per_page=-1';

      return {
        payload,
        paginate,
      }
    },
  },
};

export default MountParamsFilterEqualsAllTypes;
