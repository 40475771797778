// import Companies from '@/business/Companies';

export default {
  domain: 'imports',
  // business: Companies,
  title: 'Importação de dados',
  description: 'Nesta tela você pode realizar importação de vários tipos de dados',
  singleSelect: true,
  routes: {
    list: {
      name: 'imports',
      path: '/importacoes',
    },
  },
  show: true,
  destroy: true,
  create: false,
  noSearch: false,
  noBetween: true,
  noEquals: true,
  fields: [
  ]
}