<template>
  <div>
    <SimpleCard 
      :title="schema.title" 
      :schema="schema">

      <DynamicDetailsPage
        :schema="schema"
        :service="service"
        :type-page="typePage"
        :items-selects="{account_payment_type_id: accountPaymentTypes, status: typeAccountPaymentStatus, 
          payment_method_id: paymentMethods, bank_id: banks}"
        @save="save">
      </DynamicDetailsPage>
    </SimpleCard>
  </div>
</template>

<script>
/* eslint-disable */
import SimpleCard from '@/components/SimpleCard'
import DynamicDetailsPage from '@/components/DynamicDetailsPage'
import AccountPaymentSchema from '../schemas/AccountPaymentSchema';
import AccountPaymentService from '../services/AccountPaymentService';
import AllTypeService from '../../Registers/services/AllTypeService';
import { type_account_payment_status } from '@/constants/options';
import MountParamsFilterEqualsAllTypes from '@/mixins/MountParamsFilterEqualsAllTypes';
import BankService from '@/pages/Financial/Banks/services/BankService';

export default {
  name: 'AccountPaymentForm',
  components: {
    SimpleCard,
    DynamicDetailsPage,
  },
  data () {
    return {
      schema: AccountPaymentSchema,
      service: AccountPaymentService,
      allTypeService: AllTypeService,
      bankService: BankService,
      typePage: this.$route.name === 'accountpayment-create' ? 'create' : 'update',
      accountPaymentTypes: [],
      paymentMethods: [],
      banks: [],
      typeAccountPaymentStatus: type_account_payment_status,
    }
  },
  mounted() {
    this.getAccountPaymentTypes();
    this.getPaymentMethods();
    this.getBanks();
  },
  mixins: [MountParamsFilterEqualsAllTypes],
  methods: {
    getAccountPaymentTypes() {
      const { payload, paginate } = this.mountParamsFilterEqualsAllTypes('account-payment-types');
      this.allTypeService.filters(payload, paginate).then((res) => {
        this.accountPaymentTypes = res.data.data;
      }).catch((err) => {
        console.log(err)
      });
    },
    getPaymentMethods() {
      const { payload, paginate } = this.mountParamsFilterEqualsAllTypes('payment-methods');
      this.allTypeService.filters(payload, paginate).then((res) => {
        this.paymentMethods = res.data.data;
      }).catch((err) => {
        console.log(err)
      });
    },
    getBanks() {
      const params = {
        page: 1,
        totalItemsPerPage: -1,
      }

      this.bankService.index(params).then((res) => {
        this.banks = res.data.data;
      }).catch((err) => {
        console.log(err)
      });
    },
    save(item) {
      console.log(item)
      this.$noty.success('Registro salvo com sucesso!');
      this.$router.push({name: this.schema.routes.list.name});
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
