import { dataRegisters, address, security } from '@/constants/icons';
import Companies from '@/business/Companies';

export default {
  domain: 'companies',
  business: Companies,
  title: 'Empresas',
  description: 'Nesta tela você pode visualizar sua empresas',
  singleSelect: true,
  routes: {
    list: {
      name: 'companies',
      path: '/empresas',
    },
    create: {
      name: 'company-create',
      path: '/empresas/novo',
    },
    show: {
      name: 'company-show',
      path: '/empresas/:id',
    }
  },
  show: true,
  destroy: true,
  create: false,
  noSearch: false,
  noBetween: true,
  noEquals: true,
  fields: [
    {
      icon: dataRegisters,
      title: 'Dados Cadastrais',
      items: [
        {
          type: 'text',
          name: 'created_at',
          formattedName: 'created_at_formatted',
          label: 'Data Criação',
          md: '3',
          required: true,
          noFilter: false,
          list: true,
          noForm: true,
        },
        {
          type: 'text',
          name: 'name',
          label: 'Razão Social',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'social_name',
          label: 'Nome Fantasia',
          md: '4',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'cnpj',
          formattedName: 'cnpj_formatted',
          label: 'CNPJ',
          mask: '##.###.###/####-##',
          md: '3',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'ie',
          formattedName: 'ie',
          label: 'Insc. Estadual',
          md: '2',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'im',
          formattedName: 'im',
          label: 'Insc. Municipal',
          md: '2',
          required: false,
          noFilter: false,
          list: false,
        },
        {
          type: 'text',
          name: 'cnae',
          formattedName: 'cnae',
          label: 'CNAE',
          md: '3',
          required: false,
          noFilter: false,
          list: false,
        },
        {
          type: 'text',
          name: 'email',
          formattedName: 'email',
          label: 'E-mail',
          md: '3',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'phone',
          formattedName: 'phone',
          label: 'Telefone',
          mask: '(##)#####-####',
          md: '2',
          required: true,
          noFilter: false,
          list: true,
        },
      ],
    },
    {
      icon: address,
      title: 'Dados de Endereço',
      items: [
        {
          type: 'text',
          name: 'address.zip_code',
          label: 'CEP',
          md: '2',
          input: 'zip_code'
        },
        {
          type: 'text',
          name: 'address.state',
          label: 'UF',
          readonly: true,
          md: '2',
        },
        {
          type: 'text',
          name: 'address.city',
          label: 'Cidade',
          readonly: true,
          md: '4',
        },
        {
          type: 'text',
          name: 'address.neighborhood',
          label: 'Bairro',
          readonly: true,
          md: '4',
        },
        {
          type: 'text',
          name: 'address.street',
          label: 'Endereço',
          readonly: true,
          md: '6',
        },
        {
          type: 'text',
          name: 'address.number',
          label: 'Nº',
          md: '2',
        },
        {
          type: 'text',
          name: 'address.complement',
          label: 'Complemento',
          md: '4',
        },
      ],
    },
    {
      icon: security,
      title: 'Dados para Sefaz',
      items: [
        {
          type: 'text',
          name: 'series',
          label: 'Série NF-e Produção',
          md: '3',
        },
        {
          type: 'text',
          name: 'last_number_homo_nfe',
          label: 'Ultima NF-e Homologação',
          md: '3',
        },
        {
          type: 'text',
          name: 'last_number_homo_nfce',
          label: 'Ultima NFC-e Homologação',
          md: '3',
        },
        {
          type: 'text',
          name: 'last_number_prod_nfe',
          label: 'Ultima NF-e Produção',
          md: '3',
        },
        {
          type: 'text',
          name: 'last_number_prod_nfce',
          label: 'Ultima NFC-e Produção',
          md: '3',
        },
        {
          type: 'text',
          name: 'id_token_homo',
          label: 'Cid Token Homologação',
          md: '2',
        },
        {
          type: 'text',
          name: 'id_token_prod',
          label: 'Cid Token Produção',
          md: '2',
        },
        {
          type: 'text',
          name: 'csc_homo',
          label: 'CSC Homologação',
          md: '5',
        },
        {
          type: 'text',
          name: 'csc_prod',
          label: 'CSC Produção',
          md: '6',
        },
        {
          type: 'select',
          name: 'tax_regime_code',
          label: 'Regime Tributário',
          md: '3',
          required: true,
          noFilter: false,
        },
        {
          type: 'select',
          name: 'environment_current',
          label: 'Ambiente Sefaz',
          md: '3',
          required: true,
          noFilter: false,
        },
      ],
    }
  ]
}