const TypeComponents = {
  computed: {
    componentCreate() {
      return this.$route.name.split('-')[2] === 'create' || false;
    },
    componentShow() {
      return this.$route.name.split('-')[2] === 'show' || false;
    },
  },
};

export default TypeComponents;
