import Devolutions from '@/business/Devolutions';
import { dataRegisters } from '@/constants/icons';

export default {
  domain: 'devolutions',
  business: Devolutions,
  title: 'Devoluções',
  description: 'Listagens das Devoluções',
  icon: 'mdi-point-of-sale',
  singleSelect: true,
  routes: {
    list: {
      name: 'devolutions',
      path: '/devolucoes',
    },
    create: {
      name: 'devolution-create',
      path: '/devolucoes/novo',
    },
    show: {
      name: 'devolution-show',
      path: '/devolucoes/:id',
    },
  },
  create: true,
  show: true,
  destroy: true,
  destroyDisabled: false,
  noBetween: false,
  noEquals: false,
  searchCol: 4,
  betweenCol: 2,
  moreActions: null,
  filtersParams: null,
  fields: [
    {
      icon: dataRegisters,
      title: 'Dados do Produto',
      items: [
        {
          type: 'text',
          name: 'id',
          label: 'Código',
          align: '',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'devolution_date',
          formattedName: 'devolution_date_formatted',
          label: 'Data',
          align: '',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'customer_id',
          formattedName: 'customer.name',
          label: 'Cliente',
          align: '',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'user.name',
          label: 'Usuário',
          align: '',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'qtd_items',
          label: 'Qtd. Itens',
          align: '',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'text',
          name: 'value_total',
          formattedName: 'value_total_formatted',
          label: 'Valor Total',
          align: '',
          md: '5',
          required: true,
          noFilter: false,
          list: true,
        },
      ],
    }
  ],
  footer: [
    {
      name: 'total_devolutions',
      label: 'Valor Devolvido',
      class: 'custom-footer-value',
      md: 2,
    },
  ]
}