<template>
  <div>
    <v-card class="mt-3">
      <v-card-title>
        <h3>Itens</h3>
      </v-card-title>

      <v-card-text>
        <div class="d-flex justify-end">
          <v-row>
            <v-col md="3">
              <TextField 
                v-model="barCode"
                label="Código de Barras"
                dense
                @keyup.enter="searchByBarCode" />
            </v-col>

            <v-col class="d-flex justify-end">
              <Button label="Novo Item" rounded small color="primary" :icon="icons.plus" @click="dialogItem = true" />
            </v-col>
          </v-row>
        </div>

        <v-data-table
          item-key="id"
          :headers="headersItems"
          :items="devolution.items"
          :hide-default-footer="true"
          :disable-pagination="true"
          dense
          class="elevation-1 mt-3">
          <template v-slot:[`item.actions`]="{ item }">
            <Button 
              text 
              fab 
              rounded 
              x-small 
              color="red" 
              dark 
              class="my-1" 
              :icon="icons.destroy" 
              @click="$emit('removeItem', item)" />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <Dialog :dialog="dialogSales" :max-width="1000">
      <h3 slot="title">Pedidos de Vendas</h3>

      <div slot="content">
        <v-data-table
          item-key="id"
          :headers="headersItems"
          :items="saleOderItems"
          :disable-pagination="true"
          dense
          class="elevation-2">
          <template v-slot:[`item.actions`]="{ item }">
            <Button text fab rounded x-small color="success" class="my-1" 
              :icon="icons.checkCircle" @click="$emit('addItem', item), dialogSales = false" />
          </template>
        </v-data-table>
      </div>

      <div slot="actions">
        <Button label="Cancelar" rounded small color="red" dark 
          :loading="false" @click="dialogSales = false, saleOderItems = []" />
      </div>
    </Dialog>
  </div>
</template>

<script>
import { plus, checkCircle, destroy } from '@/constants/icons';
import TextField from '@/components/vuetify/TextField';
import Dialog from '@/components/vuetify/Dialog';
import Button from '@/components/vuetify/Button';
import { SaleOrderCommands } from '@/pages/Operations/SaleOrders/services/SaleOrderService';

export default {
  name: 'ListItems',
  components: {
    Button,
    TextField,
    Dialog
  },
  props: {
    devolution: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      icons: {
        plus: plus,
        checkCircle: checkCircle,
        destroy: destroy,
      },
      serviceSaleOrderCommands: SaleOrderCommands(),
      dialogItem: false,
      dialogSales: false,
      headersItems: [
        { text: 'Cod. Venda', value: 'sale_order_id', },
        { text: 'Data Venda', value: 'order_date_formatted', },
        { text: 'Nº Item', value: 'item_number', },
        { text: 'Produto', value: 'product_name', },
        { text: 'Qtd', value: 'qtd', },
        { text: 'Valor Uni.', value: 'value_single_formatted', },
        { text: 'Desconto', value: 'value_discount_formatted', },
        { text: 'Valor Total', value: 'value_total_formatted', },
        { text: 'Ações', value: 'actions', },
      ],
      saleOderItems: [],
      barCode: '',
      productSelected: {},
    }
  },
  methods: {
    searchByBarCode() {
      const payload = {
        bar_code: '7897654001858', //this.barCode, //7897654001858
        customer_id: this.devolution.customer.id, //this.devolution.customer_id, //"11097"
      }

      this.serviceSaleOrderCommands.getItemByBarCodeAndCustomerId(payload).then((res) => {
        if(res.length <= 0) {
          this.$noty.error('Produto não localizado.')
          this.barCode = '';
        }else{
          this.saleOderItems = res;
          this.barCode = '';
          this.dialogSales = true;
        }
      }).catch((err) => {
        console.log(err)
      })
    },
  }
}
</script>

<style>

</style>