// import axios from 'axios';
import DynamicService from '@/service/DynamicService';
import { limitCharacters, formatCurrency, formatDate } from '@/utils';

const originType = {
  ['App\\Models\\Devolution']: 'Devolução'
}

const status = {
  available: 'Disponível'
}

const formatResponse = (item) => {
    item.created_at_formatted = formatDate(item.created_at);
    item.customer_formatted = limitCharacters(item.customer.name, 20);
    item.origin_type_formatted = originType[item.origin_type];
    item.value_total_formatted = formatCurrency(item.value_total);
    item.value_total_used_formatted = formatCurrency(item.value_total_used);
    item.status_formatted = status[item.status]
}

const formatRequest = (item) => {
  item
}

const CreditLetterService = DynamicService('credit-letters', {
  formatResponse,
  formatRequest
});

const CreditLetterCommands = () => ({
  async test() {
  },
});

export default CreditLetterService;
export { CreditLetterService, CreditLetterCommands };