<template>
  <div>
    <SimpleCard :schema="schema">
      <GeneralData :devolution="devolution" />
      <ListItems :devolution="devolution" @addItem="addItem" @removeItem="removeItem" />
      <DiscountObservations :devolution="devolution" @save="save" />

      <div class="d-flex justify-start mt-3">
        <Button label="Salvar e Finalizar" class="ml-2" rounded small color="secondary" 
          :loading="loadingDevolutionFinished" :icon="icons.save" @click="dialogSave = true, devolution.finished = true" />
      </div>
    </SimpleCard>

    <DialogDestroy message='Deseja Realmente Salvar a Devolução?'
      :dialog="dialogSave" :color-buttons="{noBtn: '', yesBtn: 'primary'}" :loading="loadingSave" :max-width="560"
      @noAction="dialogSave = false, loadingSave = false" @yesAction="save" />
  </div>
</template>

<script>
import { numeric, save } from '@/constants/icons';
import SimpleCard from '@/components/SimpleCard'
import Button from '@/components/vuetify/Button';
import { rulesRequired } from '@/constants/masks';
import DevolutionSchema from '../schemas/DevolutionSchema';
import DevolutionsService from '../services/DevolutionsService';
import GeneralData from '../components/GeneralData';
import ListItems from '../components/ListItems';
import DiscountObservations from '../components/DiscountObservations';
import DialogDestroy from '@/components/DialogDestroy';

export default {
  name: 'CreateDevolution',
  components: {
    SimpleCard,
    Button,
    GeneralData,
    ListItems,
    DiscountObservations,
    DialogDestroy,
  },
  data() {
    return {
      schema: DevolutionSchema,
      service: DevolutionsService,
      icons: {
        numeric: numeric,
        save: save
      },
      rules: {
        required: rulesRequired,
      },
      devolution: {
        devolution_date: new Date().toISOString().substr(0, 10),
        customer: {},
        qtd_items: 0,
        difference: 0,
        value_total: 0,
        discount: 0,
        finished: false,
        items: [],
        removedItems: [],
      },
      dialogSave: false,
      loadingSave: false,
      loadingDevolutionSave: false,
      loadingDevolutionFinished: false,
    }
  },
  watch: {
    ['devolution.discount']() {
      this.calculateTotalizers();
    }
  },
  methods: {
    save() {
      this.createOrUpdate();
    },
    createOrUpdate() {
      this.service.create(this.devolution).then(() => {
        this.$noty.success('Registrado com sucesso.')
        this.$router.push({ name: 'devolutions' })
      }).catch((err) => {
        this.$noty.error(err)
      });
      this.dialogSave = false;
      this.loadingSave = false;
      this.loadingDevolutionSave = false;
      this.loadingDevolutionFinished = false;
    },
    addItem(item) {
      this.devolution.items.push(item)
      this.calculateTotalizers();
    },
    removeItem(item) {
      this.devolution.removedItems.push(this.devolution.items[item]);
      this.devolution.items.splice(item, 1);
      this.calculateTotalizers();
    },
    calculateTotalizers() {
      this.devolution.qtd_items = 0;
      this.devolution.value_total = 0;
      this.devolution.items.forEach((item) => {
        this.devolution.qtd_items++;
        this.devolution.value_total += parseFloat(item.value_total)
      });
      this.devolution.value_total = parseFloat(this.devolution.value_total) - parseFloat(this.devolution.discount);
    }
  }
}
</script>

<style>

</style>