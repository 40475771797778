<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1100"
    class="dialog-custom"
  >
    <v-card class="">
      <v-card-title>
        <h3>Nova Variação</h3>
      </v-card-title>

      <v-form ref="form1" v-model="validForm1" lazy-validation>
        <v-card-text>
          <v-row class="mt-1">
            <v-col>
              <v-select 
                v-model="form.variation_id" 
                hide-details="auto"
                label="Tipo de Variação" 
                item-text="description" 
                item-value="id"
                :items="selVariations"
                @change="changeVariation">
                <v-icon slot="prepend">mdi-format-list-bulleted-square</v-icon>
              </v-select>
            </v-col>

            <v-col>
              <v-select 
                v-model="form.variation_value" 
                hide-details="auto"
                label="Variação" 
                item-text="description" 
                item-value="id"
                :items="selVariationValues"
                @change="changeVariationValue">
                <v-icon slot="prepend">mdi-format-list-bulleted-square</v-icon>
              </v-select>
            </v-col>
            <v-col md="2">
              <div style="height: 100%; display: flex; align-items: center; justify-content: flex-end">
                <v-btn small dark color="success" @click="add()">Adiciona</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>

      <v-card-text>
        <v-data-table
          item-key="id"
          :id="'id'"
          :headers="headers"
          :items="resumeVariation"
          hide-default-footer
          class="elevation-1">

          <template v-slot:[`item.actions`]="props">
            <v-btn icon class="my-1" fab color="red" x-small @click="resumeVariation.splice(props.index, 1)">
              <v-icon dark>{{ icons.destroy }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>

      <v-form ref="form2" v-model="validForm2" lazy-validation>
        <v-card-text>
          <v-row class="mt-0">
            <v-col>
              <v-select 
                v-model="form.stock_id" 
                hide-details="auto"
                label="Estoque" 
                item-text="description" 
                item-value="id"
                required
                :rules="rules"
                :items="stocks">
                <v-icon slot="prepend">mdi-format-list-bulleted-square</v-icon>
              </v-select>
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.code"
                label="Código"
              >
              <v-icon slot="prepend">mdi-format-list-bulleted-square</v-icon>
              </v-text-field>
            </v-col>
            <v-col>
              <div class="content-money">
                <div class="momey-icon"><v-icon>mdi-numeric</v-icon></div>
                <vuetify-money
                  v-model="form.price_cost"
                  label="Preço Custo"
                  class="mt-5 money-content"
                  dense
                  readonly
                  :rules="rulesMoney" 
                  :outlined="false"
                  :options="money_options" />
              </div>
            </v-col>
            <v-col>
              <div class="content-money">
                <div class="momey-icon"><v-icon>mdi-numeric</v-icon></div>
                <vuetify-money
                  v-model="form.price_var"
                  label="Preço Varejo"
                  class="mt-5 money-content"
                  dense
                  required
                  :rules="rulesMoney" 
                  :outlined="false"
                  :options="money_options"
                  @input="setPricesValues('price_var')" />
              </div>
            </v-col>
            <v-col>
              <div class="content-money">
                <div class="momey-icon"><v-icon>mdi-numeric</v-icon></div>
                <vuetify-money
                  v-model="form.price_var_profit"
                  label="Lucro Varejo"
                  class="mt-5 money-content"
                  dense
                  required
                  :rules="rulesMoney" 
                  :outlined="false"
                  :options="money_options"
                  @input="setPricesValues('price_var_profit')" />
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col>
              <div class="content-money">
                <div class="momey-icon"><v-icon>mdi-numeric</v-icon></div>
                <vuetify-money
                  v-model="form.price_ata"
                  label="Preço Atacado"
                  class="mt-5 money-content"
                  dense
                  :rules="rulesMoney" 
                  :outlined="false"
                  :options="money_options"
                  @input="setPricesValues('price_ata')" />
              </div>
            </v-col>
            <v-col>
              <div class="content-money">
                <div class="momey-icon"><v-icon>mdi-numeric</v-icon></div>
                <vuetify-money
                  v-model="form.price_ata_profit"
                  label="Lucro Atacado"
                  class="mt-5 money-content"
                  dense
                  :rules="rulesMoney" 
                  :outlined="false"
                  :options="money_options"
                  @input="setPricesValues('price_ata_profit')" />
              </div>
            </v-col>
            <v-col>
              <div class="content-money">
                <div class="momey-icon"><v-icon>mdi-numeric</v-icon></div>
                <vuetify-money
                  v-model="form.qtd"
                  label="Quant."
                  class="mt-5 money-content"
                  dense
                  required
                  :rules="rulesMoney" 
                  :outlined="false"
                  :options="qtd_options" />
              </div>
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.bar_code"
                label="Código de Barras"
                counter="13"
                v-mask="'#############'"
              >
              <v-icon slot="prepend">mdi-format-list-bulleted-square</v-icon>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.sku"
                label="SKU"
              >
              <v-icon slot="prepend">mdi-format-list-bulleted-square</v-icon>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small dark color="red" @click="destroy()">Fechar</v-btn>
          <v-btn small dark color="primary" :loading="loading" @click="save()">Salvar</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import VariationService from '../../Variations/services/VariationService';
import StockService from '../../TypeStock/services/StockService';
import { ProductsCommands } from '../services/ProductService';
import { moneyOptions } from '@/utils';
import { mask } from "vue-the-mask";
import { destroy } from '@/constants/icons';
import { messageErrors } from '@/utils';
import { setValueProfits } from '@/business/Products';

export default {
  name: 'DialogAddVariations',
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    productSelected: {
      type: Object,
      default: () => {}
    }
  },
  directives: {
    mask,
  },
  data() {
    return {
      variationService: VariationService,
      serviceStock: StockService,
      productsCommands: ProductsCommands(),
      icons: {
        destroy: destroy,
      },
      validForm1: true,
      validForm2: true,
      loading: false,
      headers: [
        {text: 'Variação', value: 'variation'},
        {text: 'Valor', value: 'variation_value'},
        {text: 'Excluir', value: 'actions', align: 'end'},
      ],
      resumeVariation: [],
      form: {
        product_id: null,
        variation_id: null,
        variation_value: null,
        qtd: 0,
        price_cost: 0,
        price_var: 0,
        price_var_profit: 0,
        price_ata: 0,
        price_ata_profit: 0,
        stock_id: 0,
        sku: null,
        bar_code: null,
        code: null
      },
      variations: [],
      stocks: [],
      selVariations: [],
      selVariationValues: [],
      variationSelected: {},
      variationValueSelected: {},
      rules: [
        value => !!value || 'Obrigatório.',
      ],
      rulesMoney: [
        value => this.parseStringFloatDecimal(value) || 'Digite valor maior que zero.',
      ],
    }
  },
  watch: {
    productSelected: {
      handler() {
        this.form.price_cost = this.productSelected.price_cost;
        this.form.price_var = this.productSelected.price_var;
        this.form.price_var_profit = this.productSelected.price_var_profit;
        this.form.price_ata = this.productSelected.price_ata;
        this.form.price_ata_profit = this.productSelected.price_ata_profit;
      },
      deep: true
    },
  },
  mounted() {
    this.getVariations();
    this.getStocks();
  },
  computed: {
    money_options() {
      return moneyOptions();
    },
    qtd_options() {
      return moneyOptions(3);
    }
  },
  methods: {
    destroy() {
      this.$emit('closeDialog', 'dialogAddVariations');
    },
    getVariations() {
      this.variations = [];
      this.selVariations = [];
      this.variationService.index().then((res) => {
        this.variations = res.data.data;
        res.data.data.forEach((item) => {
          this.selVariations.push({
            id: item.id,
            description: item.description,
          });
        })
      }).catch(() => {
      })
    },
    getStocks() {
      this.stocks = [];
      this.serviceStock.index().then((res) => {
        res.data.data.forEach((item) => {
          this.stocks.push({
            id: item.id,
            description: item.description,
          });
        })
      }).catch(() => {
      })
    },
    changeVariation(item) {
      this.variations.forEach((variation) => {
        if(variation.id == item) {
          this.selVariationValues = variation.values;
          this.variationSelected = variation;
        }
      })
    },
    changeVariationValue(item) {
      this.variationSelected.values.forEach((variationValue) => {
        if(variationValue.id == item) {
          this.variationValueSelected = variationValue;
        }
      })
    },
    parseStringFloatDecimal(value) {
      value = value.replace('.', '')
      value = value.replace(',', '.')
      return value > 0;
    },
    add() {
      if(!this.$refs.form1.validate())
        return;

      let err = false;
      let msgErr = '';
      this.resumeVariation.forEach((item) => {
        if(item.variation_id == this.form.variation_id) {
          err = true;
          msgErr = 'Esta variação já foi inserida';
        }
      });

      if (err) {
        this.$noty.error(msgErr);
        return;
      }

      this.msgErro = '';
      this.resumeVariation.push({
        variation_id: this.form.variation_id,
        variation: this.variationSelected.description,
        variation_value_id: this.form.variation_value,
        variation_value: this.variationValueSelected.description,
      });

      this.$refs.form1.reset();
    },
    save() {
      if(!this.$refs.form2.validate())
        return;

      if(this.resumeVariation.length <= 0) {
        this.$noty.error("É preciso ter pelo menos uam variação adicionada");
        return;
      }

      this.loading = true;
      this.form.variations_values = this.resumeVariation;
      this.form.product_id = this.productSelected.id;

      delete this.form['variation_id'];
      delete this.form['variation_value'];
      this.productsCommands.storeProductVariation(this.form).then(() => {
        this.resumeVariation = [];
        this.loading = false;
        this.$noty.success('Operação Realizada com Sucesso!');
        this.destroy();
      }).catch((err) => {
        this.$noty.error(messageErrors(err));
        this.loading = false;
      });
    },
    setPricesValues(name) {
      setValueProfits({ name }, this.form);
    },
  }
}
</script>

<style lang="scss" scoped>
.content-money {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .momey-icon {
    margin-right: 10px;
  }

  .money-content {
    width: 100%;
  }
}
</style>