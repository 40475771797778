<template>
  <div>
    <SimpleCard 
      :title="schema.title" 
      :schema="schema"
      @actionBtn="actionBtn">

      <DynamicDetailsPage
        :schema="schema"
        :service="service"
        :type-page="typePage"
        @save="save" />
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicDetailsPage from '@/components/DynamicDetailsPage'
import StockSchema from '../schemas/StockSchema';
import StockService from '../services/StockService';

export default {
  name: 'StockForm',
  components: {
    SimpleCard,
    DynamicDetailsPage,
  },
  data () {
    return {
      schema: StockSchema,
      service: StockService,
      typePage: this.$route.name === 'stock-create' ? 'create' : 'update',
    }
  },
  mounted() {
  },
  methods: {
    show() {},
    actionBtn() {
    },
    save() {
      this.$noty.success('Registro salvo com sucesso!');
      this.$router.push({name: this.schema.routes.list.name});
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
