import StoreFrontSchema from './schemas/StoreFrontSchema';
import StoreFront from './pages/StoreFront';

export default [
  {
    path: StoreFrontSchema.routes.list.path,
    name: StoreFrontSchema.routes.list.name,
    component: StoreFront,
    meta:{
      requiresAuth: true,
    }
  },
]