<template>
  <div class="store-front">
    <v-row>
      <v-col md="7">
        <v-card>
          <div class="header-vcard">
            <h3>
              Usuário: {{ sale.seller.name || 'Não selecionado' }} | 
              Cliente: {{ sale.customer.name || 'Não Selecionado' }} |
              Tipo Venda: {{ getTypeSale(sale.typeSale) }} |
              Estoque: {{ stockSelected.description || 'Não selecionado' }}</h3>
          </div>

          <v-card-text>
            <div class="img-logo">
              <img src="@/assets/images/Logo-MGE-2.png" width="250" alt="">
            </div>
          </v-card-text>

          <div class="bottom-vcard">
            <h3></h3>
          </div>
        </v-card>

        <v-card class="mt-3">
          <div class="header-vcard">
            <h3>Registrar Item</h3>
          </div>

          <v-card-text>
            <form action="">
              <v-row>
                <v-col md="4">
                  <v-label for="qtd">(F2) Quantidade</v-label>
                  <v-text-field
                    v-model="qtd"
                    class="textfiled-current-item"
                    id="qtd"
                    dense
                    outlined
                    ref="inputQtd"
                    readonly
                    @click="dialogQtd = true" />
                </v-col>

                <v-col md="8">
                  <v-label for="bar_code_search">(F5) Código de Barras</v-label>
                  <v-text-field
                    v-model="bar_code_search"
                    class="textfiled-current-item"
                    id="bar_code_search"
                    dense
                    outlined
                    onClick="this.select();"
                    ref="inputBarCode"
                    v-on:keyup.enter="getProductByBarCode()"
                    :loading="loadingBarCode" />
                </v-col>
              </v-row>

              <v-row>
                <v-col md="8">
                  <div class="d-flex">
                    <div>
                      <p class="mt-3 mb-0">Produto</p>
                      <h1>{{ current_item.product.name }}</h1>
                    </div>
                  </div>
                </v-col>

                <v-col md="4">
                  <div class="d-flex justify-end">
                    <div>
                      <p class="mt-3 mb-0">Valor Unitário</p>
                      <h1>{{ formatCurrency(current_item.unitary_value) }}</h1>
                    </div>

                    <div class="mx-5">
                      <p class="mt-3 mb-0">Quant.</p>
                      <h1>{{ current_item.qtd }}</h1>
                    </div>

                    <div>
                      <p class="mt-3 mb-0">Valor Total</p>
                      <h1>{{ formatCurrency(current_item.amount) }}</h1>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </form>
          </v-card-text>

          <div class="bottom-vcard d-flex justify-space-between">
            <v-btn class="my-2" color="red" :dark="sale.initiated" small :disabled="!sale.initiated" @click="dialogCancelSale = true">
              Cancelar Venda (F9)
            </v-btn>

            <v-btn class="my-2" color="success" :dark="sale.initiated" small :disabled="!sale.initiated" @click="finish()">
              Finalizar Venda (F10)
            </v-btn>
          </div>
        </v-card>

        <v-card class="mt-3">
          <div class="header-vcard">
            <h3>Atalhos</h3>
          </div>

          <v-card-text>
            <div class="d-flex flex-wrap">
              <v-btn
                class="mb-3 mr-3"
                v-for="(btn, index) in btns" :key="index"
                small
                :disabled="btn.disabled"
                @click="changeActionBtn(btn.action)">
                <v-icon class="btn-icon">{{ btn.icon }}</v-icon>
                {{ btn.label }}
              </v-btn>
            </div>
          </v-card-text>

          <div class="bottom-vcard">
          </div>
        </v-card>
      </v-col>

      <v-col md="5">
        <v-card>
          <div class="header-vcard">
            <h3>Lista de Produtos</h3>
          </div>

          <v-card-text class="px-0 py-0">
            <div class="store-front--content---right">
              <div class="store-front--content---right----list-products">
                <v-divider class="my-0" color="black"></v-divider>
                <div class="grid grid-template-columns-1">
                  <table width="100%">
                    <tr>
                      <td width="10%"><p class="mb-1 header">Item</p></td>
                      <td width="20%"><p class="mb-1 header">Cod. Barras</p></td>
                      <td width="40%"><p class="mb-1 header">Produto</p></td>
                      <td width="4%"><p class="mb-1 header">Qtd.</p></td>
                      <td width="13%"><p class="mb-1 header">Vlr. Unit.</p></td>
                      <td width="13%"><p class="mb-1 header">Vlr. Total</p></td>
                    </tr>
                  </table>
                </div>
                <v-divider class="mt-0 mb-5" color="black"></v-divider>

                <div class="grid grid-template-columns-1 mt-1" >
                  <table width="100%">
                    <tr v-for="(item, index) in sale.items" :key="index">
                      <td width="10%"><p class="mb-1 items">{{ item.number }}</p></td>
                      <td width="20%"><p class="mb-1 items">{{ item.product.bar_code }}</p></td>
                      <td width="40%"><p class="mb-1 items">{{ item.product.name }}</p></td>
                      <td width="10%"><p class="mb-1 items">{{ item.qtd }}</p></td>
                      <td width="10%"><p class="mb-1 items">{{ item.unitary_value_formatted }}</p></td>
                      <td width="10%"><p class="mb-1 items">{{ item.amount_formatted }}</p></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </v-card-text>

          <div class="bottom-vcard d-flex justify-space-between">
            <div>
              <h4>Quantidade: <span class="font-weight-light">{{ sale.qtd_items }} itens</span></h4>
              <h4>Volumes: <span class="font-weight-light">{{ sale.qtd_volumes }} itens</span></h4>
              <h4>Subtotal: <span class="font-weight-light">{{ formatCurrency(sale.sub_total_value) }}</span></h4>
              <h4>Desconto: <span class="font-weight-light">{{ formatCurrency(sale.discount) }}</span></h4>
            </div>

            <div>
              <p class="my-0">Total</p>
              <h1>{{ formatCurrency(sale.total_value) }}</h1>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <div class="dialogs">
      <DialogQtd
        :dialog="dialogQtd"
        @action="actionDialog"
        @close="dialogQtd = false" />

      <DialogTypeSale
        :dialog="dialogTypeSale"
        :type-sales="typeSale"
        @action="actionDialog"
        @close="dialogTypeSale = false" />

      <DialogRemoveItem 
        :dialog="dialogRemoveItem"
        @action="actionDialog"
        @close="dialogRemoveItem = false" />

      <DialogCustomer 
        :dialog="dialogCustomer"
        @action="actionDialog"
        @close="dialogCustomer = false" />

        <DialogStocks 
        :dialog="dialogStocks"
        @action="actionDialog"
        @update:dialogStocks="dialogStocks = $event" />

      <DialogSeller
        :dialog="dialogSeller"
        @action="actionDialog"
        @close="dialogSeller = false" />

      <DialogProducts
        :dialog="dialogProduct"
        :stock-default-id="stockSelected.id"
        @action="actionDialog"
        @close="dialogProduct = false" />

      <DialogPayments 
        ref="dialogPayments"
        :dialog="dialogPayments"
        :totalizers="totalizers"
        :payments="sale.payments"
        :saleOrderIsFinished="false"
        @mountPayment="mountPayment"
        @closeDialogPayments="dialogPayments = false" />

      <DialogIssueNFCe
        :dialog="dialogIssueNFCe"
        :loading-issue-nfce="loadingIssueNfce"
        :loading-issue-nfce-print="loadingIssueNfcePrint"
        :loading-issue="loadingIssue"
        @action="actionDialog"
        @close="dialogIssueNFCe = false" />

      <DialogDestroy 
        message="Reseja Realmente Cancelar a Venda?"
        :dialog="dialogCancelSale" 
        @noAction="dialogCancelSale = false"
        @yesAction="resetSale(), dialogCancelSale = false" />
    </div>
  </div>
</template>

<script>
import { dataRegisters } from '@/constants/icons';
import DialogQtd from '../components/DialogQtd'
import DialogTypeSale from '../components/DialogTypeSale'
import DialogRemoveItem from '../components/DialogRemoveItem'
import DialogCustomer from '../components/DialogCustomer'
import DialogStocks from '../components/DialogStocks'
import DialogSeller from '../components/DialogSeller'
import DialogProducts from '../components/DialogProducts'
import DialogIssueNFCe from '../components/DialogIssueNFCe'
import DialogPayments from '@/components/DialogPayments'
import DialogDestroy from '@/components/DialogDestroy';
import SaleOrderService from '@/pages/Operations/SaleOrders/services/SaleOrderService'
import ProductService from '@/pages/Stock/Products/services/ProductService';
import { ProductsCommands } from '@/pages/Stock/Products/services/ProductService';
import RegisterService from '@/pages/Registers/services/RegisterService';
import StockService from '@/pages/Stock/TypeStock/services/StockService';
import { InvoicesCommands } from '@/pages/NFe/Invoices/services/InvoiceService';
import PrintDanfeNFCe from '@/mixins/PrintDanfeNFCe';
import GetCompanyPreferences from '@/mixins/GetCompanyPreferences';
import { formatCurrency, getText } from '@/utils';
import { type_sale } from '@/constants/options';

export default {
  components: {
    DialogQtd,
    DialogTypeSale,
    DialogRemoveItem,
    DialogCustomer,
    DialogStocks,
    DialogSeller,
    DialogProducts,
    DialogPayments,
    DialogIssueNFCe,
    DialogDestroy,
  },
  data() {
    return {
      formatCurrency: formatCurrency,
      typeSale: type_sale,
      saleOrderService: SaleOrderService,
      productService: ProductService,
      registerService: RegisterService,
      stockService: StockService,
      serviceCommands: InvoicesCommands(),
      productCommands: ProductsCommands(),
      btns: [
        {
          icon: dataRegisters,
          label: 'Clientes (F3)',
          action: 'openCustomer',
          disabled: false,
        },
        {
          icon: dataRegisters,
          label: 'Vendedor (F4)',
          action: 'openSeller',
          disabled: false,
        },
        {
          icon: dataRegisters,
          label: 'Estoques',
          action: 'openStocks',
          disabled: false,
        },
        {
          icon: dataRegisters,
          label: 'Produtos (F5)',
          action: 'openProducts',
          disabled: false,
        },
        {
          icon: dataRegisters,
          label: 'Remover Item (F6)',
          action: 'openRemoveItem',
          disabled: false,
        },
        {
          icon: dataRegisters,
          label: 'Desconto',
          action: 'seller',
          disabled: false,
        },
        {
          icon: dataRegisters,
          label: 'Tipo de Venda',
          action: 'openDialogTypeSale',
          disabled: false,
        },
        {
          icon: dataRegisters,
          label: 'Sair do PDV',
          action: 'exit',
          disabled: false,
        },
        {
          icon: dataRegisters,
          label: 'Suprimento',
          action: 'supply',
          disabled: false,
        },
        {
          icon: dataRegisters,
          label: 'Sangria',
          action: 'bleeding',
          disabled: false,
        },
      ],
      sale: {
        initiated: false,
        orderDate: new Date().toISOString().substr(0, 10),
        exitDate: new Date().toISOString().substr(0, 10),
        typeSale: 'retail',
        customer: {
          id: null,
          name: null,
        },
        seller: {
          id: null,
          name: null,
        },
        qtd_items: 0,
        qtd_volumes: 0,
        discount: 0,
        sub_total_value: 0,
        total_value: 0,
        items: [],
        payments: [],
        totalizers: {},
        removedItems: [],
        observations: '',
        issueNFCe: false,
        finished: true,
      },
      totalizers: {
        qtdItems: 0,
        qtdVolumes: 0,
        valueTotal: 0,
        valueDiscount: 0,
        valueFinal: 0,
        valuePay: 0,
        lackPay: 0,
      },
      current_item: {
        product: {
          id: null,
          name: null,
          bar_code: null,
          price: 0
        },
        qtd: 1,
        unitary_value: 0,
        amount: 0,
      },
      customer_default: {},
      seller_default: {},
      dialogQtd: false,
      dialogTypeSale: false,
      dialogCustomer: false,
      dialogStocks: false,
      dialogSeller: false,
      dialogProduct: false,
      dialogRemoveItem: false,
      dialogPayments: false,
      dialogIssueNFCe: false,
      dialogCancelSale: false,
      qtd: 1,
      bar_code_search: '',
      loadingBarCode: false,
      loadingIssueNfce: false,
      loadingIssueNfcePrint: false,
      loadingIssue: false,
      stockSelected: {}
    };
  },
  mounted() {
    this.getCompanyPreferences(['store_front', 'stock']);
    this.reset_input_bar_code_and_qtd();
    window.addEventListener('keydown', (e) => {
      this.changeActionKeyUp(e.keyCode);
    });
  },
  watch: {
    sale: {
      handler() {
        this.sale.items.length > 0 ? this.sale.initiated = true : this.sale.initiated = false;

        this.btns.forEach((btn) => {
          this.sale.initiated && btn.action === 'openDialogTypeSale' ? btn.disabled = true : btn.disabled = false;
        });
      },
      deep: true
    }
  },
  mixins: [PrintDanfeNFCe, GetCompanyPreferences],
  methods: {
    reset_input_bar_code_and_qtd() {
      this.bar_code_search = '';
      this.qtd = 1;
      this.$refs.inputBarCode.focus();
    },
    setCustomerDefault(id) {
      this.registerService.show(id).then((res) => {
        this.customer_default = { id: res.id, name: res.name};
        this.sale.customer.name = res.name;
        this.sale.customer.id = res.id;
      }).catch(() => {
      })
    },
    setSellerDefault(id) {
      this.registerService.show(id).then((res) => {
        this.seller_default = { id: res.id, name: res.name};
        this.sale.seller.id = res.id;
        this.sale.seller.name = res.name;
      }).catch(() => {
      })
    },
    setStockDefault(id) {
      this.stockService.show(id).then((res) => {
        this.stockSelected = res;
      }).catch(() => {
      })
    },
    getTypeSale(value) {
      return getText(this.typeSale, value);
    },
    changeActionKeyUp(keyCode) {
      if(keyCode == 112) {
        this.$refs.inputBarCode.focus();
        this.$refs.inputBarCode.value = '';
      }else if(keyCode == 113) {
        this.dialogQtd = true;
      }else if(keyCode == 114) {
        this.openCustomer();
      }else if(keyCode == 115) {
        this.openSeller();
      }else if(keyCode == 116) {
        this.openProducts ();
      }else if(keyCode == 117) {
        this.openRemoveItem ();
      }else if(keyCode == 121) {
        this.finish ();
      }
    },
    changeActionBtn(action) {
      this[action]();
    },
    openCustomer() {
      this.dialogCustomer = true
    },
    openStocks() {
      this.dialogStocks = true
    },
    openSeller() {
      this.dialogSeller = true
    },
    openProducts() {
      this.dialogProduct = true
    },
    openRemoveItem() {
      this.dialogRemoveItem = true
    },
    exit() {
      this.$router.push({name: 'home'})
    },
    actionDialog(data) {
      this[data.action](data.params);
    },
    changeQtd(params) {
      this.qtd = params.qtd;
      this.$refs.inputBarCode.focus();
      this.dialogQtd = false
    },
    changeTypeSale(params) {
      this.sale.typeSale = params.type_sale;
      this.dialogTypeSale = false
    },
    add(data) {
      const { productVariation, stock_id } = data;
      if(!productVariation){
        this.reset_input_bar_code_and_qtd();
        return;
      }

      const unitary_value = this.sale.typeSale === this.typeSale[0].value ? productVariation.price_var : productVariation.price_ata;
      this.sale.items.push({
        number: this.get_item_number(),
        product: {id: productVariation.product_id, bar_code: productVariation.bar_code, name: productVariation.product.name.toUpperCase()},
        qtd: this.qtd,
        unitary_value: unitary_value,
        unitary_value_formatted: formatCurrency(unitary_value, {type: 'decimal', presision: 2}),
        amount: this.qtd * unitary_value,
        amount_formatted: formatCurrency(this.qtd * unitary_value, {type: 'decimal', presision: 2}),
        id: 1,
        product_id: productVariation.product_id,
        product_variation_id: productVariation.id,
        stock_id: stock_id || this.stockSelected.id,
        item_number: this.get_item_number(),
        name: productVariation.product.name,
        value_single: unitary_value,
        value_single_formatted: formatCurrency(unitary_value),
        value_total: this.qtd * unitary_value,
        value_total_formatted: formatCurrency(this.qtd * unitary_value),
        newItem: true
      });

      this.current_item.product.name = productVariation.product.name;
      this.current_item.unitary_value = unitary_value;
      this.current_item.qtd = this.qtd;
      this.current_item.amount = this.current_item.qtd * unitary_value;

      this.calculateTotal();
      this.calculateTotalizers();
      this.reset_input_bar_code_and_qtd();
    },
    calculateTotal() {
      this.sale.qtd_items = this.sale.items?.length;
      this.sale.qtd_volumes += parseFloat(this.qtd);
      this.sale.discount = 0;
      
      this.sale.sub_total_value = 0;
      this.sale.items.forEach((item) => {
        this.sale.sub_total_value += parseFloat(item.amount);
      })
      this.sale.total_value = parseFloat(this.sale.sub_total_value - this.sale.discount);
    },
    getProductByBarCode() {
      if(!this.bar_code_search)
        return;

      if(Object.keys(this.stockSelected).length === 0) {
        this.$noty.error('Selecione o Estoque!');
        return;
      }

      this.loadingBarCode = true;

      this.productCommands.getProductVariationByBarCode(this.bar_code_search).then((res) => {
        const { data } = res.data;

        if(data.length > 1) {
          this.$noty.error('Código de barras duplicado');
          return;
        }

        this.changeProductSelected({ productVariation: data[0] });
        this.reset_input_bar_code_and_qtd();
        this.loadingBarCode = false;
      }).catch(() => {
        this.$noty.error('Erro ao localizar o Produto.');
        this.loadingBarCode = false;
        this.reset_input_bar_code_and_qtd();
      })
    },
    get_item_number() {
      if(this.sale.items?.length == 0)
        return 1;

      return parseFloat(this.sale.items.slice(-1)[0].number) + parseFloat(1);
    },
    focus_bar_code() {
      this.$refs.inputBarCode.focus();
    },
    changeCustomerSelected(data) {
      this.dialogCustomer = false;
      this.sale.customer.id = data.customer.id
      this.sale.customer.name = data.customer.text
    },
    changeStockSelected(data) {
      const { stock } = data;
      this.stockSelected = stock;
      this.dialogStocks = false;
    },
    changeSellerSelected(data) {
      this.dialogSeller = false;
      this.sale.seller.id = data.seller.id
      this.sale.seller.name = data.seller.text
    },
    changeProductSelected(data) {
      this.dialogProduct = false;

      if(!this.validateStockProduct(data))
        return;
      
      this.add(data);
      return true;
    },
    changeRemoveItem(data) {
      this.dialogRemoveItem = false;

      let error = true;
      this.sale.items.forEach((item, index) => {
        if(item.number == data.item){
          this.sale.items.splice(index, 1);
          error = false;
        }
      })

      this.calculateTotal();

      if(error)
        this.$noty.error('Item não localizado.');
    },
    validateStockProduct(data) {
      const qtd_stock_current = this.calculateStockProduct(data);

      let qtd = parseFloat(0);
      this.sale.items.forEach((item) => {
        if(item.product.id == data.productVariation.product_id){
          qtd += parseFloat(item.qtd)
        }
      })

      qtd += parseFloat(this.qtd);
      if((qtd_stock_current < this.qtd) || (qtd_stock_current < qtd)){
        this.$noty.error('Produto não possui estoque.');
        this.reset_input_bar_code_and_qtd();
        return false;
      }
      
      return true;
    },
    calculateStockProduct(data) {
      let qtd_stock_current = 0;
      const stockSelectId = data.stock_id ? data.stock_id : this.stockSelected.id
      
      data.productVariation.variation_stocks.forEach((item) => {
        if(item.stock_id === stockSelectId) {
          qtd_stock_current += parseFloat(item.qtd);
        }
      });

      return qtd_stock_current;
    },
    finish() {
      this.dialogPayments = true;
    },
    mountPayment(params) {
      this.dialogPayments = false
      this.sale.payments = params.payments;
      this.calculatePayments();
    },
    calculatePayments() {
      let valueTotalPaid = 0;

      this.sale.payments.forEach((item) => {
        valueTotalPaid += parseFloat(item.value_final);
      });
      
      this.totalizers.valuePay = valueTotalPaid;
      this.calculateTotalizers();
      this.dialogIssueNFCe = true;
    },
    calculateTotalizers() {
      this.resetTotalizers();

      this.totalizers.qtdItems = parseFloat(this.sale.qtd_items);
      this.totalizers.qtdVolumes = parseFloat(this.sale.qtd_volumes);
      this.totalizers.valueTotal = parseFloat(this.sale.total_value);
      this.totalizers.valueFinal = parseFloat(this.totalizers.valueTotal)-parseFloat(this.totalizers.valueDiscount);
      this.totalizers.lackPay = parseFloat(0);
      this.sale.totalizers = this.totalizers;
    },
    resetTotalizers() {
      this.totalizers.qtdItems = 0;
      this.totalizers.qtdVolumes = 0;
      this.totalizers.valueTotal = 0;
      this.totalizers.valueFinal = 0;
    },
    issueNFCe(params) {
      this.sale.issueNFCe = params.issueNFCe;

      if(params.issueNFCe  && !params.print)
        this.loadingIssueNfce = true;
      
      if(params.issueNFCe && params.print)
        this.loadingIssueNfcePrint = true;

      if(!params.issueNFCe  && !params.print)
        this.loadingIssue = true;

      this.createSale()
    },
    createSale() {
      this.saleOrderService.create(this.sale).then(() => {
        this.$noty.success("Venda Concluída com Sucesso.");
        this.resetSale();
        this.printDANFE();
      }).catch((err) => {
        this.loadingIssueNfce = false;
        this.loadingIssueNfcePrint = false;
        this.loadingIssue = false;
        this.dialogIssueNFCe = false;
        this.$noty.error(err);
      });
    },
    resetSale() {
      this.sale = {
        orderDate: new Date().toISOString().substr(0, 10),
        exitDate: new Date().toISOString().substr(0, 10),
        typeSale: 'retail',
        customer: {
          id: this.customer_default.id,
          name: this.customer_default.name,
        },
        seller: {
          id: this.seller_default.id,
          name: this.seller_default.name,
        },
        qtd_items: 0,
        qtd_volumes: 0,
        discount: 0,
        sub_total_value: 0,
        total_value: 0,
        items: [],
        payments: [],
        totalizers: {},
        removedItems: [],
        observations: '',
        issueNFCe: false,
        finished: true,
      };
      this.totalizers = {
        qtdItems: 0,
        qtdVolumes: 0,
        valueTotal: 0,
        valueDiscount: 0,
        valueFinal: 0,
        valuePay: 0,
        lackPay: 0,
      };
      this.current_item = {
        product: {
          id: null,
          name: null,
          bar_code: null,
          price: 0
        },
        qtd: 1,
        unitary_value: 0,
        amount: 0,
      };
    },
    printDANFE(item) {
      this.serviceCommands.getBarCode({value: 'http://nfce.set.rn.gov.br/consultarNFCe.aspx?p=24210305425972000141650010000023061000004164|2|1|1|9A145E94F89605B394DAD577F11C8FFF77304339', type: 'QRCODE'}).then((res) => {
        this.loadingIssueNfce = false;
        this.loadingIssueNfcePrint = false;
        this.loadingIssue = false;
        this.dialogIssueNFCe = false;
        this.print(item, res.data.bar_code);
      }).catch(() => {
        this.$noty.error('');
      });
    },
    openDialogTypeSale() {
      this.dialogTypeSale = true;
    },
  }
}
</script>

<style lang="scss">
@import '../style.scss'
</style>