<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
    class="dialog-custom"
  >
    <v-card class="">
      <form @submit.prevent="submit">
        <v-card-title>
          <h3>Emitir NFC-e</h3>
        </v-card-title>
        <v-divider></v-divider>

        <!-- <v-card-text class="mt-5">
          <vuetify-money
            label="Qtds"
            v-model="qtd"
            dense
            outlined
            :options="money_options"
            v-on:keyup.enter="action()" />
        </v-card-text>
        <v-divider></v-divider> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loadingIssueNfce" small dark color="primary" @click="action({issueNFCe: true, print: false})">Emitir NFC-e</v-btn>
          <v-btn :loading="loadingIssueNfcePrint" small dark color="success" @click="action({issueNFCe: true, print: true})">Emitir e Imprimir NFC-e</v-btn>
          <v-btn :loading="loadingIssue" small dark color="secondary" @click="action({issueNFCe: false, print: false})">Concluir</v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'DialogIssueNFCe',
  components: {
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    loadingIssueNfce: {
      type: Boolean,
      default: false
    },
    loadingIssueNfcePrint: {
      type: Boolean,
      default: false
    },
    loadingIssue: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      this.changeActionKeyUp(e.keyCode);
    });
  },
  data() {
    return {
    }
  },
  methods: {
    changeActionKeyUp(keyCode) {
      if(keyCode == 27) {
        this.$emit('close')
      }
    },
    action(params) {
      this.$emit('action', {action: 'issueNFCe', params: {issueNFCe: params.issueNFCe, print: params.print}});
    }
  }
}
</script>
<style scoped>
</style>