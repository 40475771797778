<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">

      <DynamicListPage
        :schema="schema"
        :service="service"
        :items-equals="itemsEquals"
        :no-initial-between="true">
      </DynamicListPage>
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import ReportsSaleOrderSchema from '../schemas/ReportsSaleOrderSchema';
import SaleOrderService from '@/pages/Operations/SaleOrders/services/SaleOrderService';
import DynamicListPage from '@/components/DynamicListPage';
import { sale_order_status } from '@/constants/options';
import ParamsEquals from '@/mixins/ParamsEquals'

export default {
  name: 'Reports',
  components: {
    SimpleCard,
    DynamicListPage,
  },
  watch: {
    page() {
      this.getItems();
    },
    totalItemsPerPage() {
      this.getItems()
    },
    dateInitial() {
      this.getItems()
    },
    dateFinal() {
      this.getItems()
    }
  },
  data () {
    return {
      schema: ReportsSaleOrderSchema,
      service: SaleOrderService,
      saleOrderStatus: sale_order_status,
      itemsEquals: []
    }
  },
  mounted() {
    this.mountItemsEquals();
  },
  mixins: [ParamsEquals],
  methods: {
    mountItemsEquals() {
      this.itemsEquals.push({
        name: 'sale_order_status',
        label: 'Status',
        items: this.paramsEquals(this.saleOrderStatus, 'text', 'value'),
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
