<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">
      
      <DynamicListPage
        :schema="schema"
        :service="service"
        :items-equals="itemsEquals" />
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import AccountPaymentSchema from '../schemas/AccountPaymentSchema';
import AccountPaymentService from '../services/AccountPaymentService';
import AllTypeService from '../../Registers/services/AllTypeService';
import MountParamsFilterEqualsAllTypes from '@/mixins/MountParamsFilterEqualsAllTypes';
import ParamsEquals from '@/mixins/ParamsEquals';
import { type_account_payment_status } from '@/constants/options';

export default {
  name: 'AccountPayments',
  components: {
    SimpleCard,
    DynamicListPage,
  },
  data () {
    return {
      schema: AccountPaymentSchema,
      service: AccountPaymentService,
      allTypeService: AllTypeService,
      itemsEquals: [],
      status: type_account_payment_status,
    }
  },
  mounted() {
    this.getAccountPaymentTypes();
  },
  mixins: [MountParamsFilterEqualsAllTypes, ParamsEquals],
  methods: {
    getAccountPaymentTypes() {
      const { payload, paginate } = this.mountParamsFilterEqualsAllTypes('account-payment-types');
      this.allTypeService.filters(payload, paginate).then((res) => {
        this.itemsEquals.push({
          name: 'account_payment_type_id',
          label: 'Tipo do Registro',
          items: this.paramsEquals(res.data.data, 'description', 'id'),
        });

        this.itemsEquals.push({
          name: 'status',
          label: 'Status',
          items: this.paramsEquals(this.status, 'text', 'value'),
        });
      }).catch((err) => {
        console.log(err)
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
