import DynamicService from '@/service/DynamicService';
import { origin_type_product_stock } from '@/constants/options';
import { formatDatePT, getText, formatCurrency } from '@/utils';

const formatResponse = (item) => {
  const origin_id = item.origin_type == 'App\\Models\\ProductStock' ? '' : item.origin_id;
  item.origin = `${getText(origin_type_product_stock, item.origin_type)} ${origin_id}`;
  item.created_at = formatDatePT(item.created_at);
  item.qtd_formatted = formatCurrency(item.qtd, {type: 'decimal', precision: 4});

  if(item.product_stock){
    if(item.product_stock.stock){
      item.stock_description = item.product_stock.stock.description;
    }

    if(item.product_stock.product){
      item.product = item.product_stock.product.name;
    }
  }

  if(item.user) {
    item.user_name = item.user.name;
  }
}

const StockService = DynamicService('product-variation-stock-moves', {
  formatResponse,
});

export default StockService ;