<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema">

      <DynamicListPage
        :schema="schema"
        :service="service"
        :items-equals="itemsEquals">
      </DynamicListPage>
      
      <v-row>
        <v-col>
          <h3>Status Sefaz: <v-chip class="ma-2" :color="sefazStatus.color" text-color="white">{{ sefazStatus.message }}</v-chip></h3>
        </v-col>
      </v-row>
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard';
import DynamicListPage from '@/components/DynamicListPage';
import CompanySchema from '../schemas/CompanySchema';
import CompanyService from '../services/CompanyService';
import { type_register } from '@/constants/options';
import ParamsEquals from '@/mixins/ParamsEquals';
import { CompaniesCommands } from '../services/CompanyService';

export default {
  name: 'Companies',
  components: {
    SimpleCard,
    DynamicListPage,
  },
  data () {
    return {
      schema: CompanySchema,
      service: CompanyService,
      serviceCommands: CompaniesCommands(),
      filterSelected: 0,
      filtersStatus: type_register,
      items: {},
      page: 1,
      pageLength: 1,
      totalItemsPagination: 0,
      totalItemsPerPage: 30,
      typeRegister: type_register,
      itemsEquals: [],
      sefazStatus: {
        message: 'Verificando...',
        color: 'green',
      }
    }
  },
  mounted() {
    this.mountItemsEquals();
    this.getSefazStatus();
  },
  mixins: [ParamsEquals],
  methods: {
    mountItemsEquals() {
      this.itemsEquals.push({
        name: 'type_register',
        label: 'Tipo do Registro',
        items: this.paramsEquals(this.typeRegister, 'text', 'value'),
      });
    },
    getSefazStatus(){
      this.serviceCommands.sefazStatus().then((res) => {
        this.sefazStatus = {
          message: res.data.xMotivo,
          color: 'green'
        }
      }).catch(() => {
        this.sefazStatus = {
          message: 'Fora de Operação.',
          color: 'red'
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
