import SaleOrderSchema from './schemas/SaleOrderSchema';
import CreateSaleOrder from './pages/CreateSaleOrder';
import SaleOrders from './pages/SaleOrders';

export default [
  {
    path: SaleOrderSchema.routes.list.path,
    name: SaleOrderSchema.routes.list.name,
    component: SaleOrders,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: SaleOrderSchema.routes.create.path,
    name: SaleOrderSchema.routes.create.name,
    component: CreateSaleOrder,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: SaleOrderSchema.routes.show.path,
    name: SaleOrderSchema.routes.show.name,
    component: CreateSaleOrder,
    meta:{
      requiresAuth: true,
    }
  },
]