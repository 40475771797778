<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">

      <DynamicListPage
        :schema="schema"
        :service="service" />
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import PaymentMethodSettingsSchema from '../schemas/PaymentMethodSettingsSchema';
import PaymentMethodSettingsService from '../services/PaymentMethodSettingsService';

export default {
  name: 'PaymentMethodSettings',
  components: {
    SimpleCard,
    DynamicListPage,
  },
  data () {
    return {
      schema: PaymentMethodSettingsSchema,
      service: PaymentMethodSettingsService,
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
