import ImportSchema from './schemas/ImportSchema';

import Imports from './pages/Imports';

export default [
  {
    path: ImportSchema.routes.list.path,
    name: ImportSchema.routes.list.name,
    component: Imports,
    meta:{
      requiresAuth: true,
    }
  },
]