<template>
  <div>
    <SimpleCard 
      :title="schema.title" 
      :schema="schema"
      @actionBtn="actionBtn">

      <DynamicDetailsPage
        :schema="schema"
        :service="service"
        :type-page="typePage"
        :items-selects="{type_person_id: type_person, type_register: type_register}"
        @save="save" />
    </SimpleCard>
    
  </div>
</template>

<script>
/* eslint-disable */
import SimpleCard from '@/components/SimpleCard'
import DynamicDetailsPage from '@/components/DynamicDetailsPage'
import RegisterSchema from '../schemas/RegisterSchema';
import RegisterService from '../services/RegisterService';
import { type_person, type_register } from '@/constants/options';

export default {
  name: 'RegisterForm',
  components: {
    SimpleCard,
    DynamicDetailsPage,
  },
  data () {
    return {
      schema: RegisterSchema,
      service: RegisterService,
      typePage: this.$route.name === 'register-create' ? 'create' : 'update',
      type_person: type_person,
      type_register: type_register,
    }
  },
  mounted() {
  },
  methods: {
    actionBtn() {
    },
    save(item) {
      this.$noty.success('Registro salvo com sucesso!');
      this.$router.push({name: this.schema.routes.list.name});
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
