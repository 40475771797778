export default {
  domain: 'users',
  title: 'Usuários',
  description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
  singleSelect: true,
  routes: {
    list: {
      name: 'users',
      path: '/usuarios',
    },
    create: {
      name: 'user-create',
      path: '/usuarios/novo',
    },
    show: {
      name: 'user-show',
      path: '/usuarios/:id',
    }
  },
  show: true,
  destroy: true,
  create: true,
  noBetween: true,
  noEquals: true,
  searchCol: 4,
  fields: [
    {
      type: 'text',
      name: 'name',
      label: 'Nome',
      align: '',
      md: '3',
      required: true,
      noFilter: false,
      list: true,
    },
    {
      type: 'email',
      name: 'email',
      label: 'E-mail',
      md: '3',
      required: true,
      list: true,
    },
    {
      type: 'select',
      name: 'type',
      formattedName: 'type_formatted',
      label: 'Tipo',
      required: true,
      list: true,
      md: '3',
    },
    {
      type: 'password',
      name: 'password',
      label: 'Senha',
      required: true,
      md: '3',
    },
    {
      type: 'password',
      name: 'confirm_password',
      label: 'Confirma Senha',
      md: '3',
      required: true,
    },
  ]
}