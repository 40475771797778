<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
    class="dialog-custom"
  >
    <v-card class="">
      <v-card-title>
        <h3>Remover Item</h3>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-text-field
          v-model="item"
          type="number"
          hide-details="auto" 
          label="digite o nº do item"
          autofocus
          v-on:keyup.enter="action()" />
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small dark color="red" @click="$emit('close')">Cancelar (ESC)</v-btn>
        <v-btn small dark color="primary" @click="action()">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'DialogRemoveItem',
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      this.changeActionKeyUp(e.keyCode);
    });
  },
  data() {
    return {
      item: null,
    }
  },
  methods: {
    changeActionKeyUp(keyCode) {
      if(keyCode == 27) {
        this.$emit('close')
      }
    },
    action() {
      this.$emit('action', {action: 'changeRemoveItem', params: {item: this.item}});
    }
  }
}
</script>
<style scoped>
</style>