import axios from 'axios';
import DynamicService from '../../../service/DynamicService';
import { formatCNPJ, formatDate } from '@/utils';

const formatResponse = (item) => {
  item.created_at_formatted = formatDate(item.created_at);
  item.cnpj_formatted = formatCNPJ(item.cnpj);

  item.address = {
    zip_code: item.zip_code,
    code_state: item.code_state,
    state: item.state,
    country: item.country,
    code_city: item.code_city,
    city: item.city,
    neighborhood: item.neighbourhood,
    street:   item.street,
    number:   item.number,
    complement:   item.complement,
  }
}

const CompanyService = DynamicService('companies', {
  formatResponse
});

const CompaniesCommands = () => ({
  async uploadCertificate(id, params) {
    return new Promise((resolve, reject) => {
      let payload = new FormData();
      payload.append('file', true);
      payload.append('certificate', params.certificate);
      payload.append('certificate_password', params.password);
      
      axios.post(`companies/${id}?_method=PUT`, payload).then(() => {
        resolve(true);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async sefazStatus() {
    return new Promise((resolve, reject) => {
      axios.get(`sefaz-status`).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
  async infoCertificate() {
    return new Promise((resolve, reject) => {
      axios.get(`info-certificate`).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err.response);
      });
    })
  },
});

export default CompanyService;
export { CompanyService, CompaniesCommands };