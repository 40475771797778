<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1200"
      class="dialog-custom"
    >
    <v-card class="">
      <v-card-title>
        <h3 small>{{ productSelected.name }}</h3>
      </v-card-title>

      <v-card-text>
        <v-divider></v-divider>
        <h3 small class="my-2">Variações</h3>
        <v-data-table
          item-key="id"
          :id="'id'"
          :headers="headers"
          :items="items"
          :loading="loadingVariations"
          hide-default-footer
          class="elevation-1">

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn class="my-1" color="success" x-small @click="$emit('selectVariation', item)">
              Selecionar
            </v-btn>
          </template>
        </v-data-table>

          <v-row class="mt-5">
            <v-col>
              <h3>Total: </h3>
            </v-col>
          </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small dark color="red" @click="$emit('update:dialogViewVariations', false)">Fechar</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { check } from '@/constants/icons';

export default {
  name: 'DialogViewVariations',
  components: {
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    productId: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loadingVariations: false,
      dialogDelete: false,
      stockTotal: 0,
      icons: {
        check: check,
      },
      headers: [
        {text: 'Código', value: 'code'},
        {text: 'Descrição', value: 'description'},
        {text: 'Preço Custo', value: 'price_cost_formatted'},
        {text: 'Preço Varejo', value: 'price_var_formatted'},
        {text: 'Preço Atacado', value: 'price_ata_formatted'},
        {text: 'Sku', value: 'sku'},
        {text: 'Cod. Barras', value: 'bar_code'},
        {text: 'Quant.', value: 'qtd_formatted'},
        {text: 'Excluir', value: 'actions'},
      ],
      productSelected: {},
      productVariationSelected: {},
      stocks: [],
      productNameSelected: '',
    }
  },
  methods: {
  }
}
</script>