import MovimentationStockSchema from './schemas/MovimentationStockSchema';

import MovimentationsStocks from './pages/MovimentationsStocks';

export default [
  {
    path: MovimentationStockSchema.routes.list.path,
    name: MovimentationStockSchema.routes.list.name,
    component: MovimentationsStocks,
    meta:{
      requiresAuth: true,
    }
  },
]