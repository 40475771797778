<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000"
    class="dialog-custom"
  >
    <v-card class="">
      <v-card-title>
        <h3>Alterar Registro</h3>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-row class="mt-5">
          <v-col v-for="(item, index) in fields" :key="index" :md="item.md">
            <v-text-field 
              v-if="(item.type === 'text' || item.type === 'password' || item.type === 'email' || item.type === 'number')" 
              hide-details="auto" 
              :type="item.type" 
              :label="item.label" 
              :rules="item.required ? rules : []"
              :readonly="item.readonly" 
              :value="getValueField(item)"
              @input="fieldChange(item.name, $event)">
              <v-icon slot="prepend">mdi-format-text-rotation-none</v-icon>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small @click="$emit('close')">Não</v-btn>
        <v-btn small dark color="red" @click="save()">Sim</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'DynamicDetailsPageDialogFormItem',
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: true,
      selected: [],
      localItem: this.item,
      fields: []
    }
  },
  watch: {
    initItemEqual() {
    },
    item: {
      handler () {
        this.setFields(this.item);
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    save() {
      this.$emit('save', this.localItem)
    },
    getValueField(item) {
      return _.get(this.localItem, item.name);
    },
    fieldChange(name, value) {
      _.set(this.localItem, name, value);
    },
    setFields(item) {
      this.fields = item.schema.fields[item.index].items
      this.localItem = item.item;
    }
  }
}
</script>
<style scoped>
</style>