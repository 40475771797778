<template>
  <v-card class="mt-3">
    <v-card-title>
      <h3>Dados Gerais</h3>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col md="2">
          <DataPicker 
            v-model="saleOrder.orderDate"
            label="Data da Ordem"
            :disabled="saleOrderFinished" />
        </v-col>

        <v-col md="2">
          <DataPicker 
            v-model="saleOrder.exitDate"
            label="Data de Entrega"
            :disabled="saleOrderFinished" />
        </v-col>

        <v-col md="2">
          <v-select 
            v-model="typeSale" 
            hide-details="auto"
            label="Tipo"
            outlined
            dense
            :rules="rules.required"
            :readonly="componentShow || saleOrderFinished"
            :items="type_sale">
          </v-select>
        </v-col>

        <v-col md="2">
          <v-select 
            v-model="stock" 
            hide-details="auto"
            label="Estoque"
            return-object
            outlined
            dense
            :rules="rules.required"
            :readonly="componentShow || saleOrderFinished"
            :items="stocks">
          </v-select>
        </v-col>

        <v-col>
          <v-autocomplete
            v-model="customer"
            outlined
            dense
            hide-no-data
            item-text="text"
            item-value="id"
            label="Cliente"
            return-object
            :rules="rules.required"
            :items="autocomletCustomer.items"
            :loading="autocomletCustomer.loading"
            :search-input.sync="autocomletCustomer.search"
            :readonly="saleOrderFinished"
            @keyup="autoCompleteKeyUp({item: 'autocomletCustomer', domain: 'registers', type: 'customer'})" />
        </v-col>

        <v-col>
          <v-autocomplete
            v-model="seller"
            outlined
            dense
            hide-no-data
            item-text="text"
            item-value="id"
            label="Vendedor"
            return-object
            :rules="rules.required"
            :items="autocomletSeller.items"
            :loading="autocomletSeller.loading"
            :search-input.sync="autocomletSeller.search"
            :readonly="saleOrderFinished"
            @keyup="autoCompleteKeyUp({item: 'autocomletSeller', domain: 'registers', type: 'employee'})" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { rulesRequired } from '@/constants/masks';
import TypeComponents from '@/mixins/TypeComponents'
import { type_sale } from '@/constants/options';
import AutoComplete from '@/mixins/AutoComplete'
import DataPicker from '@/components/vuetify/DataPicker';

export default {
  name: 'GeneralData',
  props: {
    saleOrder: {
      type: Object,
      default: () => {},
    },
    saleOrderFinished: {
      type: Boolean,
      default: false,
    },
    stocks: {
      type: Array,
      default: () => [],
    },
    stockSelected: {
      type: Object,
      default: () => {},
    },
  },
  components: { DataPicker },
  data() {
    return {
      rules: {
        required: rulesRequired,
      },
      type_sale: type_sale,
      autocomletCustomer: {
        loading: false,
        search: '',
        items: [],
      },
      autocomletSeller: {
        loading: false,
        search: '',
        items: [],
      },
    }
  },
  watch: {
    saleOrder: {
      handler() {
        this.autocomletCustomer.items = [{id: this.customer.id || '', text: this.customer.text || ''}]
        this.autocomletSeller.items = [{id: this.seller.id || '', text: this.seller.text || ''}]
      },
      deep: true
    }
  },
  computed: {
    typeSale: {
      get() {
        return this.saleOrder.typeSale;
      },
      set(value) {
        this.$emit('setGeneralData', { type: 'typeSale', value });
      }
    },
    stock: {
      get() {
        return this.stockSelected;
      },
      set(value) {
        this.$emit('setGeneralData', { type: 'stockSelected', value });
      }
    },
    customer: {
      get() {
        return this.saleOrder.customer;
      },
      set(value) {
        this.$emit('setGeneralData', { type: 'customer', value });
      }
    },
    seller: {
      get() {
        return this.saleOrder.seller;
      },
      set(value) {
        this.$emit('setGeneralData', { type: 'seller', value });
      }
    },
  },
  mixins: [TypeComponents, AutoComplete],
}
</script>

<style>

</style>