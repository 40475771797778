import DynamicService from '@/service/DynamicService';
import { formatCurrency, formatDate, getText } from '@/utils';
import { order_services_status } from '@/constants/options';

const formatResponse = (item) => {
  if(item.payments.length) {
    item.payments.forEach((payment) => {
      payment.payment_date_formatted = formatDate(payment.payment_date);
      payment.payment_method = payment.payment_method.description;
      payment.payment_value_formatted = formatCurrency(payment.value_final);
    });
  }

  item.order_date_formatted = formatDate(item.order_date);
  item.order_services_status_formatted = getText(order_services_status, item.order_service_status);
  item.value_total_formatted = formatCurrency(item.value_total);
  item.value_discount_formatted = formatCurrency(item.value_discount);
  item.value_final_formatted = formatCurrency(item.value_final);
  item.value_paid = 0;
  item.value_paid_formatted = formatCurrency(0);
  item.value_pending = 0;
  item.value_pending_formatted = formatCurrency(0);
}

const OrderServiceService = DynamicService('order-services', {
  formatResponse,
});

export default OrderServiceService;
export { OrderServiceService };