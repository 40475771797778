import DynamicService from '../../../service/DynamicService';
import { formatCurrency, formatDatePT, getText } from '@/utils';
import { type_origin_boxes } from '@/constants/options';

const formatResponse = (data) => {
  let origin_description = '';
  // data.origin.description ? origin_description = `- ${data.origin.description}` : origin_description = '';

  data.value_formatted = formatCurrency(data.value);
  data.registration_date_formatted = formatDatePT(data.registration_date);
  data.origin_type_formatted = `Code: ${data.origin_id} - ${getText(type_origin_boxes, data.origin_type)} ${origin_description}`;
  
  return data;
}

const BoxService = DynamicService('boxes', {
  formatResponse
});

export default BoxService;