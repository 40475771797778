const ParamsEquals = {
  methods: {
    paramsEquals(items, nameText, nameValue, firstEmpty = true) {
      const params = items.map((item) => {
        return {
          id: item.id,
          text: item[nameText],
          value: item[nameValue],
        }
      });

      if(firstEmpty)
        params.unshift({id: 0, text: '', value: 0});
      
      params.sort(function (a, b) {
        if (a.text > b.text) {
          return 1;
        }
        if (a.text < b.text) {
          return -1;
        }

        return 0;
      });
      return params;
    },
  },
};

export default ParamsEquals;
