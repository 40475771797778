import CompanySchema from './schemas/CompanySchema';

import Companies from './pages/Companies';
import CompanyForm from './pages/CompanyForm';

export default [
  {
    path: CompanySchema.routes.list.path,
    name: CompanySchema.routes.list.name,
    component: Companies,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: CompanySchema.routes.create.path,
    name: CompanySchema.routes.create.name,
    component: CompanyForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: CompanySchema.routes.show.path,
    name: CompanySchema.routes.show.name,
    component: CompanyForm,
    meta:{
      requiresAuth: true,
    }
  },
]