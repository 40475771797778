<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">

      <DynamicListPage
        :schema="schema"
        :service="service"
        :items-equals="itemsEquals"
        @moreActions="moreActions">
      </DynamicListPage>
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import OrderServiceSchema from '../schemas/OrderServiceSchema';
import OrderServiceService from '../services/OrderServiceService';
import { order_services_status } from '@/constants/options';
import ParamsEquals from '@/mixins/ParamsEquals'

export default {
  name: 'OrderServices',
  components: {
    SimpleCard,
    DynamicListPage,
  },
  data () {
    return {
      schema: OrderServiceSchema,
      service: OrderServiceService,
      orderServiceStatus: order_services_status,
      itemsEquals: [],
      itemSelected: {},
      dialog: false,
      loading: false,
    }
  },
  mounted() {
    this.mountItemsEquals();
  },
  mixins: [ParamsEquals],
  methods: {
    mountItemsEquals() {
      this.itemsEquals.push({
        name: 'order_service_status',
        label: 'Status',
        items: this.paramsEquals(this.orderServiceStatus, 'text', 'value'),
      });
    },
    moreActions(value) {
      this[value.action](value.item);
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
