<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="900"
    class="dialog-custom"
  >
    <v-card class="">
      <v-form 
        ref="form"
        v-model="valid"
        lazy-validation>
        <v-card-title>
          <h3>Variação: {{ variationDescription }}</h3>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-row class="mt-1">
            <v-col>
              <v-text-field
                v-model="form.description"
                hide-details="auto" 
                type="text" 
                label="Descrição"
                v-on:keyup.enter="save()">
                <v-icon slot="prepend">mdi-format-text-rotation-none</v-icon>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small dark color="red" @click="$emit('update:dialogEdit', false)">Fechar</v-btn>
          <v-btn small dark color="primary" @click="edit()" :loading="loading">Salvar</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ProductsCommands } from '../../../Products/services/ProductService';
import { messageErrors } from '@/utils';

export default {
  name: 'DialogEditItemVariation',
  components: {
  },
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    variationId: {
      type: Number,
      default: 0
    },
    variationDescription: {
      type: String,
      default: ''
    },
    itemVariationSelected: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      valid: true,
      productsCommands: ProductsCommands(),
      stocks: [],
      form: {
        variation_id: 0,
        id: this.itemVariationSelected.id,
        description: this.itemVariationSelected.description,
      },
      rules: [
        value => !!value || 'Obrigatório.',
      ],
      loading: false,
    }
  },
  watch: {
    itemVariationSelected: {
      handler() {
        this.form = this.itemVariationSelected;
      },
      deep: true
    }
  },
  methods: {
    edit() {
      if(!this.$refs.form.validate())
        return;

      this.loading = true;
      this.form.variation_id = this.variationId;
      this.productsCommands.updateVariationValues(this.form.id, this.form).then(() => {
        this.loading = false;
        this.$noty.success('Inserido com sucesso!');
        this.$emit('changeList');
        this.$emit('update:dialogEdit', false);
      }).catch((err) => {
        this.$noty.error(messageErrors(err));
        this.loading = false;
      });
    },
  }
}
</script>

<style lang="scss" scoped>
</style>