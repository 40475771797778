<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
    class="dialog-custom"
  >
    <v-card class="">
      <form @submit.prevent="submit">
        <v-card-title>
          <h3>Tipo de Venda</h3>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="mt-1">
          <v-select
            v-model="type_sale"
            item-text="text"
            item-value="value"
            label="Tipo de Venda"
            dense
            outlined
            :items="typeSales"
            @change="action()" />
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small dark color="red" @click="$emit('close')">Cancelar (ESC)</v-btn>
          <v-btn small dark color="primary" @click="action()" type="submit">Confirmar</v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'DialogTypeSale',
  components: {
  },
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    typeSales: {
      type: Array,
      default: () => []
    },
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      this.changeActionKeyUp(e.keyCode);
    });
  },
  data() {
    return {
      type_sale: 'retail',
    }
  },
  methods: {
    changeActionKeyUp(keyCode) {
      if(keyCode == 27) {
        this.$emit('close')
      }
    },
    action() {
      this.$emit('action', {action: 'changeTypeSale', params: {type_sale: this.type_sale}});
    }
  }
}
</script>
<style scoped>
</style>