<template>
  <div>
    <SimpleCard 
      :title="schema.title" 
      :schema="schema">

      <DynamicDetailsPage
        :schema="schema"
        :service="service"
        :type-page="typePage"
        :items-selects="{ payment_method_id: payment_methods, bank_id: banks }"
        @save="save" />
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicDetailsPage from '@/components/DynamicDetailsPage'
import PaymentMethodSettingsSchema from '../schemas/PaymentMethodSettingsSchema';
import PaymentMethodSettingsService from '../services/PaymentMethodSettingsService';
import AllTypeService from '@/pages/Registers/services/AllTypeService';
import BankService from '@/pages/Financial/Banks/services/BankService';
import { yes_no } from '@/constants/options';

export default {
  name: 'PaymentMethodSettingsForm',
  components: {
    SimpleCard,
    DynamicDetailsPage,
  },
  data () {
    return {
      schema: PaymentMethodSettingsSchema,
      service: PaymentMethodSettingsService,
      allTypeService: AllTypeService,
      bankService: BankService,
      typePage: this.$route.name === 'paymentmethodsettings-create' ? 'create' : 'update',
      yes_no: yes_no,
      payment_methods: [],
      banks: [],
    }
  },
  mounted() {
    this.getPaymentMethods();
    this.getBanks();
  },
  methods: {
    getPaymentMethods() {
      const { payload, paginate } = this.mountParamsFilter('payment-methods');
      this.allTypeService.filters(payload, paginate).then((res) => {
        this.payment_methods = res.data.data;
      }).catch((err) => {
        console.log(err)
      });
    },
    getBanks() {
      const params = {
        page: 1,
        totalItemsPerPage: -1,
      }

      this.bankService.index(params).then((res) => {
        this.banks = res.data.data;
      }).catch((err) => {
        console.log(err)
      });
    },
    mountParamsFilter(value) {
      const payload = {
        domain: 'all_types',
        search: '',
        ordering: {
          column: "description",
          value: "asc"
        },
        q: [{ 
          type: 'equals',
          column: 'type',
          value,
        }]
      }
      const paginate = 'page=1&per_page=-1';

      return {
        payload,
        paginate,
      }
    },
    save() {
      this.$noty.success('Registro salvo com sucesso!');
      this.$router.push({name: this.schema.routes.list.name});
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
