<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
      class="dialog-custom"
    >
      <v-card class="">
        <v-card-title>
          <h3>Adicionar ou Alterar Item</h3>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="mt-3">
          <h3>Pesquisar Item:</h3>
          <v-form ref="form" v-model="validForm" lazy-validation>
            <v-row class="mt-1">
              <v-col md="8">
                <v-autocomplete
                  v-model="productSelected"
                  outlined
                  dense
                  hide-no-data
                  item-text="text"
                  item-value="id"
                  label="Pesquise o item"
                  return-object
                  :items="autocompletProducts.items"
                  :loading="autocompletProducts.loading"
                  :search-input.sync="autocompletProducts.search"
                  @keyup="autoCompleteKeyUp({item: 'autocompletProducts', domain: 'products', type: ''})" />
              </v-col>

              <v-col md="4">
                <v-select
                  v-model="productSelected.stock_id" 
                  hide-details="auto"
                  outlined
                  dense
                  label="Estoque" 
                  item-text="text" 
                  item-value="id"
                  :rules="rules.required"
                  :loading="loadingStock"
                  :items="productStocks" />
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="col-12 col-md-3 mt-0">
                <v-text-field
                  v-model="values.qtd"
                  dense
                  outlined
                  label="Quantidade"
                  :rules="rules.biggerZero" />
              </v-col>

              <v-col cols="col-12 col-md-3 mt-0">
                <div class="content-money">
                  <vuetify-money
                    v-model="values.discount"
                    class="money-content"
                    outlined
                    dense
                    label="Desconto"
                    :options="money_options" />
                </div>
              </v-col>

              <v-col cols="col-12 col-md-3 mt-0">
                <div class="content-money">
                  <vuetify-money
                    v-model="values.unity_value"
                    class="money-content"
                    outlined
                    dense
                    label="Valor Unitário"
                    readonly
                    :rules="rules.money" 
                    :options="money_options" />
                </div>
              </v-col>

              <v-col cols="col-12 col-md-3 mt-0">
                <div class="content-money">
                  <vuetify-money
                    v-model="values.total_value"
                    class="money-content"
                    outlined
                    dense
                    label="Valor Total"
                    readonly
                    :rules="rules.money" 
                    :options="money_options" />
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="col-12 col-md-3">
              </v-col>
            </v-row>

            <v-row class="mt-0" v-if="showVariationSelected">
              <v-col>
                <p>
                  Variação Selecionada: {{ productVariationSelected.description }}
                  <v-btn class="my-0" color="primary" x-small @click="dialogViewVariations = true">Alterar</v-btn>
                </p>
                
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col>
                <v-progress-linear v-if="loadingForm"
                  :indeterminate="loadingForm"
                  rounded
                  color="primary"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small dark rounded color="red" @click="resetItem(), $emit('close')">Cancelar (ESC)</v-btn>
          <v-btn small dark rounded color="primary" @click="action()" v-on:keyup.enter="action()">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DialogViewVariations
      :items=productVariations
      :dialog="dialogViewVariations"
      @selectVariation="selectVariation"
      @update:dialogViewVariations="dialogViewVariations = $event" />
  </div>
</template>

<script>
import AutoComplete from '@/mixins/AutoComplete'
import ProductService from '@/pages/Stock/Products/services/ProductService';
import { ProductsCommands } from '@/pages/Stock/Products/services/ProductService';
import { mask } from "vue-the-mask";
import { rulesMoney, rulesBiggerZero, rulesRequired } from '@/constants/masks';
import DialogViewVariations from '../components/DialogViewVariations';
import { verifyItemSigleVariation } from '@/utils';

export default {
  name: 'DialogItem',
  components: {
    DialogViewVariations,
  },
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    typeOrder: {
      type: String,
      default: 'retail',
      required: true,
    },
    stockDefaultId: {
      type: Number,
      default: 0,
    }
  },
  mounted() {
    // window.addEventListener('keydown', (e) => {
    //   this.changeActionKeyUp(e.keyCode);
    // });
  },
  data() {
    return {
      productCommandsService: ProductsCommands(),
      validForm: true,
      productSelected: {},
      productVariationSelected: {},
      values: {
        product_id: null,
        qtd: 1,
        discount: 0,
        unity_value: 0,
        total_value: 0,
      },
      item: {},
      autocompletProducts: {
        loading: false,
        search: '',
        items: [],
      },
      money_options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2
      },
      rules: {
        money: rulesMoney,
        biggerZero: rulesBiggerZero,
        required: rulesRequired,
      },
      loadingForm: false,
      loadingStock: false,
      productStocks: [],
      dialogViewVariations: false,
      productVariations: [],
      showVariationSelected: false,
    }
  },
  directives: {
    mask,
  },
  watch: {
    productSelected: {
      handler() {
        this.getProduct();
      },
      deep: true,
    },
    ['values.qtd']: {
      handler() {
        this.calculateTotalValue();
      },
      deep: true,
    },
    ['values.discount']: {
      handler() {
        this.calculateTotalValue();
      },
      deep: true,
    },
  },
  mixins: [AutoComplete],
  methods: {
    changeActionKeyUp(keyCode) {
      if(keyCode == 27) {
        this.$emit('close')
      }else if(keyCode == 13) {
        this.action();
      }
    },
    action() {
      if(!this.$refs.form.validate())
        return;

      this.item.stock_id = this.productSelected.stock_id;
      this.$emit('action', 
        { 
          action: 'addItem',
          close: 'dialogItem',
          params: {item: this.item, newItem: true}
        }
      );
    },
    async getProduct() {
      if(!this.productSelected.id)
        return;
        
      this.loadingForm = true;
      this.showVariationSelected = false;
      ProductService.show(this.productSelected.id).then( async (item) => {
        if(item.variations.length === 1) {
          if(verifyItemSigleVariation(item.variations[0])) {
            this.productVariationSelected = item.variations[0];
            const i = {
              id: item.id,
              product_variation_id: item.variations[0].id,
              name: item.name,
              price_var: item.price_var,
              price_ata: item.price_ata,
            }
            await this.selectProduct(i);
          }else{
            await this.changeVariations(item);
          }
        }else if(item.variations.length > 1) {
            await this.changeVariations(item);
        }else{
          this.$noty.error('Item sem Variações Cadastrada.');
          this.loadingForm = false;
          this.resetItem();
        }
      }).catch(() => {
        this.loadingForm = false;
      });
    },
    async getProductStock() {
      this.loadingStock = true;
      this.productStocks = [];
      this.productCommandsService.getProductStocksByProductId(this.productVariationSelected.id).then((res) => {
        res.data.data.forEach((item) => {
          this.productStocks.push({
            id: item.stock_id,
            text: item.stock_description
          });
        })
        this.productSelected.stock_id = this.stockDefaultId
        this.loadingStock = false;
      }).catch(() => {
        this.loadingStock = false;
      })
    },
    calculateTotalValue() {
      this.values.total_value = (parseFloat(this.values.unity_value) * parseFloat(this.values.qtd) - this.values.discount);

      this.item.qtd = this.values.qtd;
      this.item.discount = this.values.discount;
      this.item.price_unity = this.values.unity_value;
      this.item.total_value = (parseFloat(this.item.price_unity) * parseFloat(this.values.qtd) - this.values.discount);
    },
    resetItem() {
      this.$refs.form.resetValidation();
      this.productSelected = {};
      this.productStocks = [];
      this.values.qtd = 1;
      this.values.discount = 0;
      this.values.unity_value = 0;
      this.values.total_value = 0;
      this.showVariationSelected = false;
    },
    async selectProduct(item) {
      this.values.unity_value = this.typeOrder === 'retail' ? item.price_var : item.price_ata;
        
      this.item.id = null;
      this.item.product_id = item.id;
      this.item.product_variation_id = item.product_variation_id;
      this.item.name = item.name;
      this.calculateTotalValue();
      await this.getProductStock();
      this.loadingForm = false;
    },
    changeVariations(item) {
      this.productVariations = item.variations_formatted;
      this.dialogViewVariations = true;
    },
    async selectVariation(item) {
      this.dialogViewVariations = false;
      this.productVariationSelected = item
      this.showVariationSelected = true;
      await this.selectProduct(this.setInfoItem(item, true));
    },
    setInfoItem(item, isVariation = false) {
      return {
        id: item.product_id,
        product_variation_id: isVariation ? item.id : item.variations[0].id,
        name: isVariation ? `${item.product.name} - ${item.description}` : item.name,
        price_var: item.price_var,
        price_ata: item.price_ata,
      }
    }
  }
}
</script>
<style scoped>
</style>