import DevolutionSchema from './schemas/DevolutionSchema';
import CreateDevolution from './pages/CreateDevolution';
import Devolutions from './pages/Devolutions';

export default [
  {
    path: DevolutionSchema.routes.list.path,
    name: DevolutionSchema.routes.list.name,
    component: Devolutions,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: DevolutionSchema.routes.create.path,
    name: DevolutionSchema.routes.create.name,
    component: CreateDevolution,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: DevolutionSchema.routes.show.path,
    name: DevolutionSchema.routes.show.name,
    component: CreateDevolution,
    meta:{
      requiresAuth: true,
    }
  },
]