import DynamicService from '../../../service/DynamicService';
import { formatCurrency, formatDate, getText } from '@/utils';
import { type_account_payment_status } from '@/constants/options';

const formatResponse = (item) => {
  item.registration_date_formatted = formatDate(item.registration_date);
  item.value_formatted = formatCurrency(item.value);
  item.type_account_payment_status_formatted = getText(type_account_payment_status, item.status);

}

const formatRequest = () => {
}

const AccountPaymentService = DynamicService('account-payments', {
  formatResponse,
  formatRequest,
});

export default AccountPaymentService ;