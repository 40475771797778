<template>
  <div>
    <SimpleCard 
      :title="schema.title" 
      :schema="schema"
      @actionBtn="actionBtn">

      <DynamicDetailsPage
        :schema="schema"
        :service="service"
        :type-page="typePage"
        :items-selects="{tax_regime_code: tax_regime_code, environment_current: type_amb}"
        @save="save" />
    </SimpleCard>
  </div>
</template>

<script>
/* eslint-disable */
import SimpleCard from '@/components/SimpleCard'
import DynamicDetailsPage from '@/components/DynamicDetailsPage'
import CompanySchema from '../schemas/CompanySchema';
import CompanyService from '../services/CompanyService';
import { tax_regime_code, type_amb } from '@/constants/options';

export default {
  name: 'CompanyForm',
  components: {
    SimpleCard,
    DynamicDetailsPage,
  },
  data () {
    return {
      schema: CompanySchema,
      service: CompanyService,
      typePage: this.$route.name === 'company-create' ? 'create' : 'update',
      tax_regime_code: tax_regime_code,
      type_amb: type_amb,
    }
  },
  mounted() {
  },
  methods: {
    actionBtn() {
    },
    save(item) {
      this.$noty.success('Registro salvo com sucesso!');
      this.$router.push({name: this.schema.routes.list.name});
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
