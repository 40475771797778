<template>
  <div>
    <SimpleCard 
      :schema="schema" 
      :route-create="schema.routes.create.name">

      <DynamicListPage
        id="content-data-list"
        :schema="schema"
        :service="service"
        @moreActions="moreActions"
        :items-equals="itemsEquals">

        <template slot="custom-header">
          <v-row class="mb-3">
            <v-col>
              <v-btn small @click="print()">
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </DynamicListPage>
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage';
import BoxSchema from '../schemas/BoxSchema';
import BoxService from '../services/BoxService';
import PrintBox from '@/mixins/PrintBox'
import AllTypeService from '@/pages/Registers/services/AllTypeService';
import MountParamsFilterEqualsAllTypes from '@/mixins/MountParamsFilterEqualsAllTypes';
import MountParamsFilterEqualsPaymentMethodSettings from '@/mixins/MountParamsFilterEqualsPaymentMethodSettings';
import ParamsEquals from '@/mixins/ParamsEquals'
import { type_boxes } from '@/constants/options';

const ORIGIN_TYPE = Object.freeze({
  'App\\Models\\SaleOrder': 'sale-order-show'
})

export default {
  name: 'Box',
  components: {
    SimpleCard,
    DynamicListPage,
  },
  data () {
    return {
      schema: BoxSchema,
      service: BoxService,
      allTypeService: AllTypeService,
      itemsEquals: [],
      payment_methods: [],
      type_boxes: type_boxes,
    }
  },
  mounted() {
    this.getPaymentMethods();
  },
  mixins: [
    PrintBox,
    MountParamsFilterEqualsAllTypes,
    MountParamsFilterEqualsPaymentMethodSettings,
    ParamsEquals],
  methods: {
    moreActions(data) {
      this[data.action](data.item)
    },
    getPaymentMethods() {
      const { payload, paginate } = this.mountParamsFilterEqualsAllTypes('payment-methods');
      this.allTypeService.filters(payload, paginate).then((res) => {
        this.payment_methods = res.data.data;
        
        this.itemsEquals.push({
          name: 'type_box',
          label: 'Tipo',
          items: this.paramsEquals(this.type_boxes, 'text', 'value'),
        });

        this.itemsEquals.push({
          name: 'payment_method_id',
          label: 'Forma de Pagamento',
          items: this.paramsEquals(this.payment_methods, 'description', 'id'),
        });
      }).catch((err) => {
        console.log(err)
      });
    },
    origin(item) {
      window.open(this.$router.resolve({name: ORIGIN_TYPE['App\\Models\\SaleOrder'], params: {id: item.origin_id}}).href, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss';
</style>
