import StockSchema from './schemas/StockSchema';

import Stocks from './pages/Stocks';
import StockForm from './pages/StockForm';

export default [
  {
    path: StockSchema.routes.list.path,
    name: StockSchema.routes.list.name,
    component: Stocks,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: StockSchema.routes.create.path,
    name: StockSchema.routes.create.name,
    component: StockForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: StockSchema.routes.show.path,
    name: StockSchema.routes.show.name,
    component: StockForm,
    meta:{
      requiresAuth: true,
    }
  },
]