import DynamicService from '../../../../service/DynamicService';
import { formatCurrency, formatDate } from '@/utils';

const formatResponse = (item) => {
  item.balance_formatted = formatCurrency(item.balance);
  item.opening_date_formatted = formatDate(item.opening_date);
  item.default_formatted = item.default === 'yes' ? 'Sim' : 'Não';
}

const BankService = DynamicService('banks', {
  formatResponse
});

export default BankService ;