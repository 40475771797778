<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    class="dialog-custom"
  >
  <v-card class="">
    <v-card-title>
      <h3>Estoques</h3>
    </v-card-title>
    <v-card-text>
      <v-data-table
        item-key="id"
        :id="'id'"
        :headers="[{text: 'Estoque', value: 'stock.description'}, {text: 'Quantidade', value: 'qtd_formatted'}]"
        :items="items"
        hide-default-footer
        class="elevation-1" />
      <v-row class="mt-5">
        <v-col>
          <h3>Total: {{stockTotal}}</h3>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small dark color="red" @click="destroy(true)">Fechar</v-btn>
      </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
import { formatCurrency } from '@/utils';

export default {
  name: 'DialogViewStock',
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: [],
      stockTotal: 0,
    }
  },
  watch: {
    items() {
      let stockTotalTmp = 0;
      this.stockTotal = 0;
      this.items.forEach((item) => {
        stockTotalTmp += parseFloat(item.qtd);
      });
      this.stockTotal = formatCurrency(stockTotalTmp, {type: 'decimal', precision: 4});
    }
  },
  mounted() {
  },
  methods: {
    destroy() {
      this.$emit('closeDialog', 'dialogView');
    }
  }
}
</script>
<style scoped>
</style>