<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema">

      <DynamicListPage
        :schema="schema"
        :service="service"
        :items-equals="itemsEquals"
        @moreActions="moreActions" />
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import MovimentationStockSchema from '../schemas/MovimentationStockSchema';
import MovementService from '../services/MovementService';
import StockService from '../../TypeStock/services/StockService';
import ParamsEquals from '@/mixins/ParamsEquals'
import { type_stock_move } from '@/constants/options';

export default {
  name: 'MovimentationsStocks',
  components: {
    SimpleCard,
    DynamicListPage,
  },
  data () {
    return {
      schema: MovimentationStockSchema,
      service: MovementService,
      serviceStock: StockService,
      itemsEquals: [],
      type_stock_move: type_stock_move,
    }
  },
  mounted() {
    this.mountItemsEquals();
  },
  mixins: [ParamsEquals],
  methods: {
    mountItemsEquals() {
      this.serviceStock.index().then((res) => {
        this.itemsEquals.push({
          name: 'stock_id',
          label: 'Tipos Estoque',
          items: this.paramsEquals(res.data.data, 'description', 'id'),
        });
      }).catch(() => {
      });

      this.itemsEquals.push({
        name: 'type_stock_move',
        label: 'Tipo Movimentação',
        items: this.paramsEquals(this.type_stock_move, 'text', 'value'),
      });
    },
    moreActions() {
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
