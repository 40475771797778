<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1200"
    class="dialog-custom"
  >
    <v-card class="">
      <v-card-title>
        <h3>Pagamento</h3>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="mt-3">
        <v-row class="mb-5">
          <v-col>
            <h3>Total: {{ formatCurrency(totalizers.valueTotal) }}</h3>
          </v-col>
          <v-col>
            <h3>Desconto: {{ formatCurrency(totalizers.valueDiscount) }}</h3>
          </v-col>
          <v-col>
            <h3>Total Final: {{ formatCurrency(totalizers.valueFinal) }}</h3>
          </v-col>
          <v-col>
            <h3>Total Pago: {{ formatCurrency(totalPay) }}</h3>
          </v-col>
          <v-col>
            <h3>Falta Pagar: {{ formatCurrency(lackPay) }}</h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="3">
            <v-select
              v-model="payment_method_id"
              item-text="description"
              item-value="id"
              label="Forma de Pagamento"
              dense
              outlined
              :readonly="saleOrderIsFinished"
              :items="payment_methods"
              @change="changeBankDefault()"
            ></v-select>
          </v-col>

          <v-col md="2">
            <v-select
              v-model="flag_id"
              item-text="description"
              item-value="id"
              label="Bandeiras"
              dense
              outlined
              :readonly="saleOrderIsFinished"
              :items="flags"
            ></v-select>
          </v-col>

          <v-col md="2">
            <v-select
              v-model="bank_id"
              item-text="description"
              item-value="id"
              label="Banco"
              dense
              outlined
              :readonly="saleOrderIsFinished"
              :items="banks"
            ></v-select>
          </v-col>

          <v-col md="2">
            <vuetify-money
              v-model="payment_value"
              dense
              :readonly="saleOrderIsFinished"
              :outlined="true"
              :options="money_options"
              @keyup.enter="pay()" />
          </v-col>

          <v-col md="2">
            <DataPick
              outlined
              dense
              no-icon
              model-name="payment_date"
              :model="payment_date"
              @changeDataPick="changeDataPick" />
          </v-col>

          <v-col md="1">
            <v-btn color="primary" @click="pay()" :disabled="saleOrderIsFinished">Pagar</v-btn>
          </v-col>
        </v-row>

        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="items"
          hide-default-footer
          class="elevation-1">

          <template v-slot:[`item.delete`]="props">
            <v-btn icon color="red" x-small @click="removePay(props.index)" :disabled="!props.item.new">
              <v-icon dark>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <v-row class="mt-3">
          <v-col md="12">
            <v-btn small rounded color="success" @click="finished(false)" :disabled="saleOrderIsFinished || !items.length">Salvar</v-btn>
            <!-- <v-btn small rounded class="ml-2" color="primary" :disabled="(totalPay < totalizers.valueFinal) || saleOrderIsFinished" @click="finished(true)">Finalizar Pedido</v-btn> -->
            <v-btn small rounded class="ml-2" @click="$emit('closeDialogPayments')">Fechar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatCurrency, formatDate } from '@/utils';
import DataPick from '@/components/DataPick';
import MountParamsFilterEqualsAllTypes from '@/mixins/MountParamsFilterEqualsAllTypes';
import MountParamsFilterEqualsPaymentMethodSettings from '@/mixins/MountParamsFilterEqualsPaymentMethodSettings';
import AllTypeService from '@/pages/Registers/services/AllTypeService';
import BankService from '@/pages/Financial/Banks/services/BankService';
import PaymentMethodSettingsService from '@/pages/Financial/PaymentMethodSettings/services/PaymentMethodSettingsService';

export default {
  name: 'DialogPayments',
  components: {
    DataPick
  },
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    saleOrderIsFinished: {
      type: Boolean,
      default: true
    },
    totalizers: {
      type: Object,
      default: () => {}
    },
    payments: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      allTypeService: AllTypeService,
      bankService: BankService,
      paymentMethodSettingsService: PaymentMethodSettingsService,
      loading: false,
      headers: [
        {text: 'Data', value: 'payment_date_formatted'},
        {text: 'Forma de Pagamento', value: 'payment_method'},
        {text: 'Bandeira', value: 'flag'},
        {text: 'Valor Pago', value: 'payment_value_formatted'},
        {text: 'Excluir', value: 'delete', align: 'end'},
      ],
      items: [],
      formatCurrency: formatCurrency,
      payment_methods: [],
      flags: [],
      banks: [],
      payment_method_id: 0,
      flag_id: 0,
      bank_id: 0,
      payment_value: 0,
      payment_date: new Date().toISOString().substr(0, 10),
      money_options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2
      },
      totalPay: 0,
    }
  },
  mounted() {
    this.getPaymentMethods();
    this.getFlags();
    this.getBanks();
  },
  computed: {
    lackPay() {
      return this.totalizers.valueFinal - this.totalPay
    }
  },
  watch: {
    items() {
      this.changeTotalPay();
    },
  },
  mixins: [
    MountParamsFilterEqualsAllTypes,
    MountParamsFilterEqualsPaymentMethodSettings],
  methods: {
    changePayments(payments) {
      payments.forEach((item) => {
        if(!item.new) {
          this.items.push({
            payment_method_id: item.payment_method_id,
            flag_id: item.flag_id,
            payment_method: item.payment_method.description,
            flag: item.flag,
            value_total: item.value_total,
            value_discount: item.value_discount,
            value_final: item.value_final,
            registration_date: item.payment_date,
            payment_date_formatted: formatDate(item.payment_date),
            payment_value_formatted: formatCurrency(item.value_total),
            new: false,
          });
        }
      });
      this.changeTotalPay();
    },
    getPaymentMethods() {
      const { payload, paginate } = this.mountParamsFilterEqualsAllTypes('payment-methods');
      this.allTypeService.filters(payload, paginate).then((res) => {
        this.payment_methods = res.data.data;
      }).catch((err) => {
        console.log(err)
      });
    },
    getFlags() {
      const { payload, paginate } = this.mountParamsFilterEqualsAllTypes('flags');
      this.allTypeService.filters(payload, paginate).then((res) => {
        this.flags = res.data.data;
      }).catch((err) => {
        console.log(err)
      });
    },
    getBanks() {
      this.bankService.index().then((res) => {
        this.banks = res.data.data;
      }).catch((err) => {
        console.error(err);
      });
    },
    pay() {
      if(this.payment_method_id == 0){
        this.$noty.error('Selecione uma forma de pagamento!');
        return;
      }

      if(this.payment_value == 0){
        this.$noty.error('Digite o valor a ser pago!');
        return;
      }

      if(this.bank_id == 0){
        this.$noty.error('Selecione o banco!');
        return;
      }

      let payment_method = '';
      this.payment_methods.forEach((item) => {
        if(item.id === this.payment_method_id){
          payment_method = item.description;
        }
      });

      let flag = '';
      this.flags.forEach((item) => {
        if(item.id === this.flag_id){
          flag = item.description;
        }
      });
      
      this.items.push({
        payment_method_id: this.payment_method_id,
        flag_id: this.flag_id ? this.flag_id : null,
        bank_id: this.bank_id ? this.bank_id : null,
        payment_method,
        flag,
        value_total: this.payment_value,
        value_discount: 0,
        value_final: this.payment_value,
        registration_date: this.payment_date,
        payment_date_formatted: formatDate(this.payment_date),
        payment_value_formatted: formatCurrency(this.payment_value),
        new: true,
      })

      this.payment_method_id = 0;
      this.flag_id = 0;
      this.payment_value = 0;
      this.bank_id = 0;
    },
    finished(finishedSale) {
      if((this.totalPay < this.totalizers.valueFinal) && finishedSale){
        this.$noty.error('O Valor Pago não pode ser menor que o Valor Final do Pedido!');
        return;
      }

      const data = {
        payments: this.items,
        finishedSale
      }
      
      this.$emit('mountPayment', data);
    },
    changeTotalPay() {
      let total = 0
      this.items.forEach((item) => {
        total += parseFloat(item.value_total);
      })

      this.totalPay = total;
    },
    removePay(index) {
      this.items.splice(index, 1);
    },
    changeDataPick(data) {
      this.payment_date = data.date;
    },
    changeBankDefault() {
      const { payload, paginate } = this.mountParamsFilterEqualsPaymentMethodSettings(this.payment_method_id);
      this.paymentMethodSettingsService.filters(payload, paginate).then((res) => {
        this.flag_id = 0;
        if(res.data.data.length){
          this.bank_id = res.data.data[0].bank_id;
        }else{
          this.bank_id = 0;
        }
      }).catch((err) => {
        console.log(err)
      });
    },
  }
}
</script>

<style>

</style>