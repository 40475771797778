<template>
  <div class="dynamic-details-page">
    <v-row>
      <v-col class="mb-5">
        <v-btn 
          small
          class="primary mr-2" 
          v-for="(item, index) in schema.routes.create.children" :key="index" 
          :to="item.path">
          {{item.label}}
          </v-btn>
      </v-col>
    </v-row>
    <v-form 
      ref="form"
      v-model="valid"
      lazy-validation>
      <v-row>
        <v-col md="12">
          <v-card>
            <v-expansion-panels
              v-model="panel"
              multiple>
            <v-expansion-panel v-for="(form, index) in fields" :key="index">
              <v-expansion-panel-header>
                <h3>
                  <v-icon class="mr-2">{{ form.icon }}</v-icon> {{ form.title }}
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                
                <v-row v-if="form.type != 'list'">
                  <v-col v-for="(item, index) in form.items" :key="index" :md="getMd(item)" sm="12" xs="12">
                    <v-text-field 
                      v-if="(item.type === 'text' || item.type === 'password' || item.type === 'email' || item.type === 'number') 
                      && !noForm(item) && !item.mask" 
                      hide-details="auto" 
                      :id="item.id"
                      :type="item.type" 
                      :label="item.label" 
                      :rules="item.required ? rules : []"
                      :readonly="item.readonly || localItem.readOnlyGlobal" 
                      :value="getValueField(item)"
                      :counter="item.counter"
                      :ref="item.ref"
                      :disabled="item.disabled"
                      @input="fieldChange(item, $event), item.input === 'zip_code' ? cep($event) : ''">
                      <v-icon slot="prepend">mdi-format-text-rotation-none</v-icon>
                    </v-text-field>

                    <v-textarea 
                      v-if="(item.type === 'textarea')  && !noForm(item)"
                      outlined
                      hide-details="auto" 
                      :id="item.id"
                      :type="item.type" 
                      :label="item.label" 
                      :rules="item.required ? rules : []" 
                      :readonly="item.readonly || localItem.readOnlyGlobal" 
                      :value="getValueField(item)"
                      @input="fieldChange(item, $event), item.input === 'zip_code' ? cep($event) : ''">
                      <v-icon slot="prepend">mdi-format-text-rotation-none</v-icon>
                    </v-textarea>

                    <v-text-field 
                      v-if="item.type === 'text' && !noForm(item) && item.mask" 
                      hide-details="auto" 
                      v-mask="item.mask"
                      :id="item.id"
                      :type="item.type" 
                      :label="item.label" 
                      :rules="item.required ? rules : []" 
                      :readonly="item.readonly || localItem.readOnlyGlobal" 
                      :value="getValueField(item)"
                      :counter="item.counter"
                      :disabled="item.disabled"
                      :ref="item.ref"
                      @input="fieldChange(item, $event)">
                      <v-icon slot="prepend">mdi-format-text-rotation-none</v-icon>
                    </v-text-field>

                    <div class="content-money" v-if="item.type === 'money' && !noForm(item)">
                      <div class="momey-icon"><v-icon>mdi-numeric</v-icon></div>
                      <vuetify-money
                        v-model="localItem[item.name]"
                        :class="`mt-5 money-content`"
                        dense
                        :id="item.id"
                        :rules="item.required ? rulesMoney : []" 
                        :label="item.label"
                        :readonly="item.readonly || localItem.readOnlyGlobal"
                        :outlined="false"
                        :options="item.money_options"
                        :disabled="item.disabled"
                        @input="fieldChange(item, $event)" />
                    </div>

                    <DataPick 
                      v-if="item.type === 'date' && !noForm(item)"
                      :id="item.id"
                      :model="localItem[item.name]"
                      :model-name="item.name"
                      :label="item.label"
                      :rules="item.required ? [v => !!v || 'Obrigatório'] : []"
                      :readonly="item.readonly || localItem.readOnlyGlobal"
                      @change="fieldChange(item, $event)"
                      @changeDataPick="changeDataPick" />

                    <v-select 
                      v-if="item.type === 'select' && !noForm(item)" 
                      v-model="localItem[item.name]" 
                      hide-details="auto"
                      :id="item.id"
                      :loading="loadingSelect"
                      :items="itemsSelects[item.name]" 
                      :label="item.label" 
                      :item-text="getItemText(item)" 
                      :item-value="getItemId(item)"
                      :rules="item.required ? [v => !!v || 'Obrigatório'] : []"
                      :readonly="item.readonly || localItem.readOnlyGlobal"
                      :disabled="item.disabled"
                      @change="selectChange(item, localItem[item.name])">
                      <v-icon slot="prepend">mdi-format-list-bulleted-square</v-icon>
                    </v-select>

                    <v-file-input
                      v-if="item.type === 'file' && !noForm(item)" 
                      v-model="localItem[item.name]" 
                      hide-details="auto"
                      :id="item.id"
                      :label="item.label"
                      :rules="item.required ? [v => !!v || 'Obrigatório'] : []"
                      :readonly="item.readonly || localItem.readOnlyGlobal"
                      @change="fileChange(item, localItem[item.name])">
                    </v-file-input>

                    <div v-if="item.type === 'autocomplete' && !noForm(item)">
                      <v-autocomplete
                        v-model="localItem[item.name]"
                        :id="item.id"
                        :items="localItem[item.items]"
                        :loading="localItem[item.loading]"
                        :search-input.sync="localItem[item.search]"
                        :readonly="item.readonly || localItem.readOnlyGlobal"
                        hide-no-data
                        item-text="text"
                        item-value="id"
                        :label="localItem[item.label]"
                        placeholder="pesquise o cliente"
                        prepend-icon="mdi-format-list-bulleted-square"
                        return-object
                        @change="autoCompleteChange(item.name, item)"
                        @keyup="autoCompleteKeyUp(item)"></v-autocomplete>
                    </div>
                  </v-col>
                </v-row>

                <v-row v-if="form.type === 'list'">
                  <v-col>
                    <v-data-table
                      item-key="id"
                      :id="'id'"
                      :loading="false"
                      :headers="getHeaders(form)"
                      :items="localItem[form.nameData]"
                      hide-default-footer
                      class="elevation-1">

                      <template v-slot:[`item.actions`]="props">
                        <v-btn :disabled="localItem.readOnlyGlobal" icon class="my-1" fab color="primary" x-small 
                          @click="openDialogFormItem(form.nameData, props, index)">
                          <v-icon dark>{{ icons.view }}</v-icon>
                        </v-btn>
                        
                        <v-btn :disabled="localItem.readOnlyGlobal"  icon class="my-1" fab color="red" x-small>
                          <v-icon dark>{{ icons.destroy }}</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          </v-card>
        </v-col>
        
      </v-row>

      <v-row>
        <v-col>
          <slot name="content-internal" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn :loading="loadingSave" :disabled="localItem.readOnlyGlobal"  small color="primary" @click.prevent="save()">
            <v-icon small class="mr-2">mdi-content-save</v-icon>
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <DynamicDetailsPageDialogFormItem
      :dialog="dialog"
      :item="formItemSelected"
      @close="dialog = false"
      @save="saveFormItem" />
  </div>
</template>

<script>
/* eslint-disable */
import _ from 'lodash';
import cep from 'cep-promise'
import { mask } from "vue-the-mask";
import { Money } from "v-money";
import DataPick from '@/components/DataPick';
import { view, destroy } from '@/constants/icons'
import DynamicDetailsPageDialogFormItem from '@/components/DynamicDetailsPageDialogFormItem';

export default {
  name: 'DynamicDetailsPage',
  components: {
    Money,
    DataPick,
    DynamicDetailsPageDialogFormItem,
  },
  props: {
    schema: {
      type: Object,
      default: () => {},
    },
    service: {
      type: Object,
    },
    obj: {
      type: Object,
      default: () => {},
    },
    itemsSelects: {
      type: Object,
      default: () => {},
    },
    typePage: {
      type: String,
      default: 'create',
    },
    loadingSelect: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      icons: {
        view: view,
        destroy: destroy,
      },
      valid: true,
      rules: [
        value => !!value || 'Obrigatório.',
      ],
      rulesMoney: [
        value => this.parseStringFloatDecimal(value) || 'Digite valor maior que zero.',
      ],
      panel: [0],
      localItem: {},
      address_id: 0,
      dateNow: new Date().toISOString().substr(0, 10),
      options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2
      },
      dialog: false,
      formItemSelected: {
        name: null,
        item: {}
      },
      loadingSave: false,
    }
  },
  computed: {
    fields() {
      const res = [];
      if(this.schema.fields[0].title){
        this.schema.fields.forEach((form) => {
          let arrForm = [];
          const arrFormItems = [];
          form.items.forEach((item) => {
            if(!this.noForm(item)){
              arrFormItems.push(item);
            }
          });
          arrForm = {
            icon: form.icon,
            title: form.title,
            type: form.type,
            nameData: form.nameData,
            noActions: form.noActions,
            items: arrFormItems
          };
          res.push(arrForm);
        });
      }else{
        // só existe para manter a compatibilidade com os antigos Schemas
        // é preciso refatorar todos os Schemas para o padrão do CompanySchema
        // depois de refatorar pode ser excluído
        let arrForm = [];
        const arrFormItems = [];
        this.schema.fields.forEach((item) => {
          if(!this.noForm(item)){
            arrFormItems.push(item);
          }
        });

        arrForm = {
          icon: 'mdi-clipboard-text-outline',
          title: 'Dados Cadastrais',
          type: '',
          nameData: '',
          noActions: '',
          items: arrFormItems
        };
        res.push(arrForm);
      }

      return res;
    },
  },
  watch: {
  },
  mounted() {
    this.typePage === 'update' ? this.show(this.$route.params.id) : '';
    if(this.schema.business?.beforeFormMounted)
      this.schema.business.beforeFormMounted({
        fields: this.fields,
        selects: this.itemsSelects,
        models: this.localItem,
        route: this.$route
      })
  },
  directives: {
    mask,
  },
  methods: {
    getHeaders(form) {
      const headers = [];
      form.items.forEach(item => {
        if(item.listFormItem){
          headers.push(
            {
              text: item.label,
              value: item.formattedName ? item.formattedName : item.name,
              align: item.align ? item.align : 'start',
              sortable: item.sortable ? item.sortable : true,
            }
          );
        }
      });

      if(!form.noActions)
        headers.push({ text: 'Ações', value: 'actions', sortable: false, align: 'end'});

      return headers;
    },
    show() {
      const { id } = this.$route.params;
      this.service.show(id).then((res) => {
        this.localItem = res;

        this.schema.fields.forEach((items) => {
          if(items.items?.length) {
            items.items.forEach((item) => {
              if(item.type === 'autocomplete'){
                this.$set(this.localItem, item.label, res[item.name]);
                this.$set(this.localItem, item.name, {id: res[item.name].id, text: res[item.name]});
              }
            });
          }
        });

        if(this.schema.business){
          if ( typeof this.schema.business.afterShow === 'function' )
            this.schema.business.afterShow({formValue: this.localItem, fields: this.fields})
        }
          
      }).catch((err) => {
        this.$noty.error(err);
        this.$router.push({name: this.schema.routes.list.name});
      });
    },
    parseStringFloatDecimal(value) {
      value = value.replace('.', '')
      value = value.replace(',', '.')
      return value > 0;
    },
    save() {
      if(!this.$refs.form.validate())
        return;

      this.loadingSave = true;
      const { id } = this.$route.params;

      if(this.schema.business){
        if ( typeof this.schema.business.beforeSave === 'function' )
          this.schema.business.beforeSave(this.localItem)
      }

      this.typePage === 'create' ? this.create(this.localItem) : this.update(id, this.localItem);
    },
    create(item){
      this.service.create(item).then(() => {
        this.$noty.success('Registro salvo com sucesso!');
        this.$router.push({name: this.schema.routes.list.name});
        this.loadingSave = false;
      }).catch((err) => {
        this.loadingSave = false;
        this.$noty.error(err);
      });
    },
    update(id, item) {
      this.service.update(id, item).then(() => {
        this.$noty.success('Registro alterado com sucesso!');
        this.$router.push({name: this.schema.routes.list.name});
        this.loadingSave = false;
      }).catch((err) => {
        this.$noty.error(err);
        this.loadingSave = false;
      });
    },
    getValueField(item) {
      return _.get(this.localItem, item.name);
    },
    fieldChange(item, value) {
      _.set(this.localItem, item.name, value);
      if(this.schema.business != undefined) {
        if(item.keyUp) {
          this.schema.business.keyUp(this.localItem, item, value, this.fields);
        }
      }
    },
    cep(value) {
      this.address_id = this.localItem.address.id;
      if(value.length === 8){
        cep(value).then((cep) => {
          this.setAddress(this.address_id, cep);
        })
      }else{
        this.setAddress(this.address_id, {});
      }
    },
    setAddress(id, address) {
      this.localItem.address = address;
      this.localItem.address.id = id;
      this.localItem.address.zip_code = address.cep;
      this.$set(this.localItem, 'address.state', address.state);
      this.$set(this.localItem, 'address.city', address.city);
      this.$set(this.localItem, 'address.neighborhood', address.neighborhood);
      this.$set(this.localItem, 'address.street', address.street);
    },
    getItemId(item) {
      return item.item_id || 'value'
    },
    getItemText(item) {
      return item.item_text || 'text'
    },
    changeDataPick(data) {
      _.set(this.localItem, data.model, data.date);
    },
    selectChange(item, value) {
      if(this.schema.business != undefined) {
        if(item.selectChange) {
          this.schema.business.selectChange(this.localItem, item, value, this.fields);
        }
      }
    },
    fileChange(item, value) {
      console.log(item)
      console.log(value)
    },
    noForm(item) {
      if(item.noForm)
        return true;
      
      if(this.typePage === 'update' && item.noFormUpdate)
        return true;

      return false;
    },
    getMd(item) {
      if(item.mdComplement) {
        return (parseInt(item.md) + parseInt(item.mdComplement))
      }else if(item.mdComplementCreate) {
        return this.typePage === 'create' ? (parseInt(item.md) + parseInt(item.mdComplementCreate)) : item.md; 
      }else if(item.mdComplementUpdate) {
        return this.typePage === 'update' ? (parseInt(item.md) + parseInt(item.mdComplementUpdate)) : item.md; 
      }else{
        return item.md;
      }
    },
    openDialogFormItem(name, item, index) {
      this.dialog = true;
      this.formItemSelected = {
        name,
        item: item.item,
        schema: this.schema,
        index
      }
    },
    saveFormItem(item) {
      this.dialog = false;
    },
    autoCompleteChange(name, item) {
    },
    debounceInput: _.debounce(function (item) {
      this.$set(this.localItem, item.items, [])
      this.$set(this.localItem, item.loading, true)
      this.getAutoCompleteSearch(item);
    }, 1000),
    autoCompleteKeyUp(item) {
      this.debounceInput(item);
    },
    getAutoCompleteSearch (item) {
      let val = this.localItem[item.search]
      let paginate = `page=1&per_page=-1`;
      let payload = {
        domain: item.domain,
        search: val,
        ordering: item.ordering,
        q: item.q,
      }

      item.service.filters(payload, paginate).then((res) => {
        const { total, data } = res.data;
          this.count = total;
          let items = data;
          let autoItems = [];

          items.forEach((item) => {
            autoItems.push({id: item.id, text: item.name})
          });

          this.localItem[item.items] = autoItems;
          this.$set(this.localItem, item.items, autoItems)
          this.$set(this.localItem, item.loading, false)
      }).catch(() => {
        this.$set(this.localItem, item.loading, false)
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.content-money {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .momey-icon {
    margin-right: 10px;
  }

  .money-content {
    width: 100%;
  }
}
</style>