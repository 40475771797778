<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">
      
      <DynamicListPage
        ref="dynamicListPage"
        :schema="schema"
        :service="service"
        @moreActions="moreActions">

        <template slot="custom-header">
          <v-row class="mb-3">
            <v-col>
              <v-btn color="primary" small @click="dialogOpenExitProduct = true">
                Entrada/Saída Produto
              </v-btn>
            </v-col>
          </v-row>
        </template>

      </DynamicListPage>
    </SimpleCard>

    <DialogViewStock
      :dialog="dialogView"
      :items="stocks"
      @closeDialog="closeDialog" />

    <DialogAddStock
      :dialog="dialogAdd"
      :productName="productSelected.name"
      :loading="loading"
      @saveStock="saveStock"
      @closeDialog="closeDialog" />

    <DialogViewVariations
      :dialog="dialogViewVariations"
      :product-id="productSelected.id"
      @closeDialog="closeDialog"
      @addVariation="addVariation" />

    <!-- <DialogAddVariations
      :dialog="dialogAddVariations"
      :product-selected="productSelected"
      @closeDialog="closeDialog" /> -->

    <DialogAddStockWithBarCode
      :dialog="dialogOpenExitProduct"
      @closeDialog="closeDialog" />
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import ProductSchema from '../schemas/ProductSchema';
import ProductService from '../services/ProductService';
import DialogViewStock from '../components/DialogViewStock';
import DialogAddStock from '../components/DialogAddStock';
import DialogAddStockWithBarCode from '../components/DialogAddStockWithBarCode';
import DialogViewVariations from '../components/DialogViewVariations';
// import DialogAddVariations from '../components/DialogAddVariations';
import { ProductsCommands } from '../services/ProductService';
import { messageErrors } from '@/utils';

export default {
  name: 'Products',
  components: {
    SimpleCard,
    DynamicListPage,
    DialogViewStock,
    DialogAddStock,
    DialogViewVariations,
    // DialogAddVariations,
    DialogAddStockWithBarCode,
  },
  data () {
    return {
      schema: ProductSchema,
      service: ProductService,
      serviceProduct: ProductsCommands(),
      dialogView: false,
      dialogAdd: false,
      dialogViewVariations: false,
      dialogAddVariations: false,
      loading: false,
      stocks: [],
      variations: [],
      productSelected: {},
      dialogOpenExitProduct: false
    }
  },
  mounted() {
  },
  methods: {
    moreActions(value) {
      this[value.action](value.item);
    },
    viewStock(item){
      this.stocks = item.variations[0].variation_stocks;
      this.dialogView = true;
    },
    addStock(item){
      this.productSelected = item;
      this.dialogAdd = true;
    },
    viewVariations(item){
      this.productSelected = item;
      this.variations = item.variations_formatted;
      this.dialogViewVariations = true;
    },
    closeDialog(dialog) {
      this[dialog] = false;
      this.$refs.dynamicListPage.getDataFromApi();
    },
    saveStock(form) {
      this.loading = true;
      form.product_variation_id = this.productSelected.variations[0].id;
      this.serviceProduct.storeProductVariationStock(form).then(() => {
        this.$noty.success('Registro salvo com sucesso!');
        this.dialogAdd = false;
        this.loading = false;
        this.$refs.dynamicListPage.getDataFromApi();
      }).catch((err) => {
        this.$noty.error(messageErrors(err));
        this.dialogAdd = false;
        this.loading = false;
      });
    },
    addVariation() {
      this.dialogViewVariations = false;
      this.dialogAddVariations = true;
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
