import DynamicService from '../../../../service/DynamicService';
import { formatCurrency } from '@/utils';

const formatResponse = (item) => {
  item.tax_formatted = formatCurrency(item.tax, 'percent');
}

const PaymentMethodSettingsService = DynamicService('payment-method-settings', {
  formatResponse
});

export default PaymentMethodSettingsService;