import { dataRegisters, address } from '@/constants/icons';

export default {
  domain: 'registers',
  title: 'Registros',
  description: 'Nesta tela você pode visualizar: Clientes, Funcionários, Fornecedores...',
  singleSelect: true,
  routes: {
    list: {
      name: 'registers',
      path: '/registros',
    },
    create: {
      name: 'register-create',
      path: '/registros/novo',
    },
    show: {
      name: 'register-show',
      path: '/registros/:id',
    }
  },
  show: true,
  destroy: true,
  create: true,
  noSearch: false,
  noBetween: true,
  noEquals: false,
  searchCol: 4,
  equalsCol: 3,
  filtersParams: {
    ordering: {
      column: "name",
      value: "asc"
    },
    equals: { 
      type: 'equals',
      column: 'type_register'
    }
  },
  fields: [
    {
      icon: dataRegisters,
      title: 'Dados Cadastrais',
      items: [
        {
          type: 'select',
          name: 'type_register',
          formattedName: 'type_register_formatted',
          label: 'Tipo Registro',
          md: '3',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'select',
          name: 'type_person_id',
          label: 'Tipo Pessoa',
          md: '3',
          required: true,
          noFilter: false,
        },
        {
          type: 'text',
          name: 'name',
          label: 'Nome',
          align: '',
          md: '3',
          required: true,
          noFilter: false,
          list: true,
        },
        {
          type: 'email',
          name: 'email',
          label: 'E-mail',
          md: '3',
          list: true,
        },
        {
          type: 'text',
          name: 'phone',
          formattedName: 'phone_formatted',
          label: 'Telefone',
          mask: '(##)####-####',
          md: '3',
          list: true,
        },
        {
          type: 'text',
          name: 'cell_phone',
          formattedName: 'cell_phone_formatted',
          label: 'Celular',
          mask: '(##)#####-####',
          md: '3',
          required: true,
          list: true,
        },
        {
          type: 'text',
          name: 'cpf',
          label: 'CPF',
          mask: '###.###.###-##',
          md: '2',
        },
        {
          type: 'text',
          name: 'rg',
          label: 'RG',
          md: '2',
        },
        {
          type: 'date',
          name: 'birth',
          label: 'Nascimento',
          md: '2',
        },
        {
          type: 'text',
          name: 'site',
          label: 'Site',
          md: '3',
        },
        {
          type: 'text',
          name: 'cnpj',
          label: 'CNPJ',
          mask: '##.###.###/####-##',
          md: '3',
        },
        {
          type: 'text',
          name: 'ie',
          label: 'Insc. Estadual',
          md: '2',
        },
        {
          type: 'text',
          name: 'company_name',
          label: 'Razão Social',
          md: '4',
        },
      ]
    },
    {
      icon: address,
      title: 'Dados de Endereço',
      items: [
        {
          type: 'text',
          name: 'address.zip_code',
          label: 'CEP',
          md: '2',
          input: 'zip_code'
        },
        {
          type: 'text',
          name: 'address.state',
          label: 'UF',
          md: '2',
        },
        {
          type: 'text',
          name: 'address.city',
          label: 'Cidade',
          md: '4',
        },
        {
          type: 'text',
          name: 'address.neighborhood',
          label: 'Bairro',
          md: '4',
        },
        {
          type: 'text',
          name: 'address.street',
          label: 'Endereço',
          md: '4',
        },
        {
          type: 'text',
          name: 'address.number',
          label: 'Nº',
          md: '2',
        },
        {
          type: 'text',
          name: 'address.complement',
          label: 'Complemento',
          md: '6',
        },
        {
          type: 'text',
          name: 'address.reference',
          label: 'Ponto de Referência',
          md: '12',
        },
      ]
    }
  ]
}