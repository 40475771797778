/* eslint-disable */
import { formatCurrency } from '@/utils';

export default {
  beforeList: (formValue) => {
    let total_value = 0;

    formValue.data.forEach((item) => {
      total_value += parseFloat(item.vNF);

      if(item.cStat === '100'){
        item.disableDelete = true ;
        item.disableItemMoreActions = {
          sendNFe: true
        }
      }

      if(!item.cStat){
        item.disableItemMoreActions = {
          letter: true,
          cancel: true,
          clone: true,
          download: true,
          sendEmail: true,
        }
      }

      if(item.cStat == '101' || item.cStat == '135'){
        item.disableDelete = true ;
        item.disableItemMoreActions = {
          cancelNFe: false,
          sendNFe: true,
          letter: true,
        }
      }
    });

    formValue.total_value = formatCurrency(total_value);
  },
  afterShow: ({ formValue }) => {
    if(formValue.cStat === '100'){
      formValue.readOnlyGlobal = true;
    }
  }
}