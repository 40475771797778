<template>
  <div>
    <SimpleCard 
      :title="schema.title" 
      :schema="schema">

      <DynamicDetailsPage
        :schema="schema"
        :service="service"
        :type-page="typePage"
        :items-selects="{ default: yes_no }"
        @save="save" />

    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicDetailsPage from '@/components/DynamicDetailsPage'
import BilletSchema from '../schemas/BilletSchema';
import BilletService from '../services/BilletService';
import AllTypeService from '@/pages/Registers/services/AllTypeService';
import { yes_no } from '@/constants/options';
import RegisterService from '@/pages/Registers/services/RegisterService';
import { users } from '@/constants/icons';

export default {
  name: 'ServiceForm',
  components: {
    SimpleCard,
    DynamicDetailsPage,
  },
  data () {
    return {
      icons: {
        users: users,
      },
      schema: BilletSchema,
      service: BilletService,
      allTypeService: AllTypeService,
      typePage: this.$route.name === 'billet-create' ? 'create' : 'update',
      yes_no: yes_no,
      dialogSearch: false,
      serviceRegister: RegisterService,
      itemEqualSelected: [],
      customerSelected: '',
      itemClicked: 'customers',
      descriptionLimit: 60,
    }
  },
  mounted() {
    this.getCategories();
    this.getSubCategories();
  },
  computed: {

  },
  watch: {
  },
  methods: {
    getCategories() {
      const { payload, paginate } = this.mountParamsFilter('categories');
      this.allTypeService.filters(payload, paginate).then((res) => {
        this.categories = res.data.data;
      }).catch((err) => {
        console.log(err)
      });
    },
    getSubCategories() {
      const { payload, paginate } = this.mountParamsFilter('subcategories');
      this.allTypeService.filters(payload, paginate).then((res) => {
        this.subcategories = res.data.data;
      }).catch((err) => {
        console.log(err)
      });
    },
    getProductStock() {
      this.loadingStock = true;
      this.productStocks = [];
      this.productCommandsService.getProductStocksByProductId(this.selectedProduct.id).then((res) => {
        res.data.data.forEach((item) => {
          this.productStocks.push({
            id: item.stock_id,
            text: item.stock_description
          });
        })
        this.loadingStock = false;
      }).catch(() => {
        this.loadingStock = false;
      })
    },
    mountParamsFilter(value) {
      const payload = {
        domain: 'all_types',
        search: '',
        ordering: {
          column: "description",
          value: "asc"
        },
        q: [{ 
          type: 'equals',
          column: 'type',
          value,
        }]
      }
      const paginate = 'page=1&per_page=-1';

      return {
        payload,
        paginate,
      }
    },
    save() {
      this.$noty.success('Registro salvo com sucesso!');
      this.$router.push({name: this.schema.routes.list.name});
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
