<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">

      <DynamicListPage
        :schema="schema"
        :service="service" />
    </SimpleCard>
  </div>
</template>

<script>
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import BankSchema from '../schemas/BankSchema';
import BankService from '../services/BankService';

export default {
  name: 'Banks',
  components: {
    SimpleCard,
    DynamicListPage,
  },
  data () {
    return {
      schema: BankSchema,
      service: BankService,
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
