<template>
  <div>
    <SimpleCard 
      no-back
      :schema="schema" 
      :route-create="schema.routes.create.name">

      <DynamicListPage
        no-initial-between
        :schema="schema"
        :service="service"
        :items-equals="itemsEquals"
        @moreActions="moreActions">
      </DynamicListPage>
    </SimpleCard>

    <DialogSimpleQuestion 
      message="Deseja Realmente Gerar a Nota Fiscal?" 
      :dialog="dialog" 
      :loading="loading" 
      @eventBtn="eventBtn" />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import SimpleCard from '@/components/SimpleCard'
import DynamicListPage from '@/components/DynamicListPage'
import SaleOrderSchema from '../schemas/SaleOrderSchema';
import SaleOrderService from '../services/SaleOrderService';
import { sale_order_status, type_sale } from '@/constants/options';
import ParamsEquals from '@/mixins/ParamsEquals'
import DialogSimpleQuestion from '@/components/DialogSimpleQuestion';
import { SaleOrderCommands } from '../services/SaleOrderService';
import { formatDate, formatCurrency } from '@/utils';

export default {
  name: 'SaleOrders',
  components: {
    SimpleCard,
    DynamicListPage,
    DialogSimpleQuestion,
  },
  data () {
    return {
      schema: SaleOrderSchema,
      service: SaleOrderService,
      serviceCommands: SaleOrderCommands(),
      itemsEquals: [],
      itemSelected: {},
      dialog: false,
      loading: false,
    }
  },
  mounted() {
    this.mountItemsEquals();
  },
  mixins: [ParamsEquals],
  methods: {
    mountItemsEquals() {
      this.itemsEquals.push({
        name: 'sale_order_status',
        label: 'Status',
        items: this.paramsEquals(sale_order_status, 'text', 'value'),
      });

      this.itemsEquals.push({
        name: 'type_sale',
        label: 'Tipo de Venda',
        items: this.paramsEquals(type_sale, 'text', 'value'),
      });
    },
    moreActions(value) {
      this[value.action](value.item);
    },
    makeNFe(item){
      this.itemSelected = item;
      this.dialog = true;
    },
    eventBtn(value) {
      value ? this.confirmMakeNFe(this.itemSelected) : this.dialog = false;
    },
    confirmMakeNFe(item) {
      this.loading = true;
      
      const payload = {
        model: 55,
        sale_order_id: item.id,
        na_op: 'VENDA'
      }

      this.serviceCommands.makeInvoice(payload).then(() => {
        this.$noty.success('Nota Gerada com Sucesso');
        this.dialog = false;
        this.loading = false;
      }).catch((err) => {
        this.$noty.error(err.data.errors.base[0]);
        this.dialog = false;
        this.loading = false;
      });
    },
    printSaleOrder(value) {
      console.log(value)
      const doc = new jsPDF();

      this.mountHeader(doc);
      this.mountCompany(doc);
      this.mountCustomer(doc);
      this.mountItems(doc);
      this.mountTotalizers(doc);

      doc.save("a4.pdf");
    },
    mountHeader(doc) {
      doc.setFont("helvetica");
      doc.setFontSize(8);
      doc.text("Usuário Logado: Renan Reis", 5, 10);

      doc.setFont("helvetica", "bold");
      doc.text("Pedido de Vendas", 95, 10);

      doc.setFont("helvetica", "normal");
      doc.text("Data: 22/02/2022 18:50", 175, 10);
      doc.text("Página: 1", 175, 14);
      doc.line(5, 16, 205, 16);
    },
    mountCompany(doc) {
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text("EMPRESA: LA DELUXE | CNPJ: 11.578.224/0001-00", 5, 20);

      doc.setFont("helvetica", "normal");
      doc.setFontSize(8);
      doc.text("End. Av. Tomaz Landim, 777 - Igapó - Natal/RN", 5, 24);
      doc.text("CEP: 59.075-740 | Fone: (84)98848-1941", 5, 28);
      doc.text("Fone: (84) 98848-1941", 5, 32);
    },
    mountCustomer(doc) {
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text("CLIENTE: LA DELUXE | CNPJ: 11.578.224/0001-00", 204, 20, {align: 'right'});

      doc.setFont("helvetica", "normal");
      doc.setFontSize(8);
      doc.text("End. Av. Tomaz Landim, 777 - Igapó - Natal/RN", 204, 24, {align: 'right'});
      doc.text("CEP: 59.075-740 | Fone: (84)98848-1941", 204, 28, {align: 'right'});
      doc.text("Fone: (84) 98848-1941", 204, 32, {align: 'right'});
    },
    mountItems(doc) {
      const data = [];

      for (var i = 0; i<= 13; i += 1) {
        data.push(['1', '32123', 'Cueca P Azul 3 em 1 - Diamantes LA Deluxe', '29,90', '2', '59,80', '9,80', '50,00'])
      }

      doc.autoTable({
        tableWidth: 200,
        margin: { left: 5 },
        styles: { fillColor: [229, 229, 229], textColor: [0, 0, 0], fontSize: 7 },
        headStyles: { fillColor: [192, 192, 192] },
        footStyles: { fillColor: [192, 192, 192] },
        startY: 35,
        head: [['Ítem', 'Ref.', 'Produto', 'Vlr. Uni.', 'Qtd', 'Sub Total', 'Desconto', 'Total']],
        body: data,
      });
    },
    mountTotalizers(doc) {
      doc.setFont("helvetica", "bold");
      doc.setFontSize(9,);

      doc.setDrawColor(0, 0, 0);
      doc.line(130, doc.lastAutoTable.finalY + 2, 205, doc.lastAutoTable.finalY + 2);

      doc.text("Totalizadores", 156, doc.lastAutoTable.finalY + 6)
      doc.text("Itens:", 133, doc.lastAutoTable.finalY + 10)
      doc.text("8", 202, doc.lastAutoTable.finalY + 10, {align: 'right'})
      doc.text("Volumes:", 133, doc.lastAutoTable.finalY + 14)
      doc.text("88888", 202, doc.lastAutoTable.finalY + 14, {align: 'right'})
      doc.text("Sub Total", 133, doc.lastAutoTable.finalY + 18)
      doc.text("888.888,88", 202, doc.lastAutoTable.finalY + 18, {align: 'right'})
      doc.text("Desconto Total", 133, doc.lastAutoTable.finalY + 22)
      doc.text("19,80", 202, doc.lastAutoTable.finalY + 22, {align: 'right'})
      doc.text("Valor Final:", 133, doc.lastAutoTable.finalY + 26)
      doc.text("130,00", 202, doc.lastAutoTable.finalY + 26, {align: 'right'})

      doc.setDrawColor(211, 211, 211);
      doc.line(133, doc.lastAutoTable.finalY + 30, 202, doc.lastAutoTable.finalY + 30);
      doc.text("Pagamentos", 158, doc.lastAutoTable.finalY + 35);
      
      let marginY = 40;
      this.payments().forEach((item) => {
        doc.text(item.payment_method.description, 133, doc.lastAutoTable.finalY + marginY)
        doc.text(formatDate(item.payment_date), 168, doc.lastAutoTable.finalY + marginY)
        doc.text(formatCurrency(item.value_final), 202, doc.lastAutoTable.finalY + marginY, {align: 'right'})
        
        marginY += 4;
      });
      doc.setDrawColor(0, 0, 0);
      doc.setTextColor(255,0,0);
      doc.text("Falta Pagar:", 133, doc.lastAutoTable.finalY + marginY)
      doc.text("R$30,00", 202, doc.lastAutoTable.finalY + marginY, {align: 'right'})
      doc.line(130, doc.lastAutoTable.finalY + 2, 130, doc.lastAutoTable.finalY + marginY + 4);
      doc.line(205, doc.lastAutoTable.finalY + 2, 205, doc.lastAutoTable.finalY + marginY + 4);
      doc.line(130, doc.lastAutoTable.finalY + marginY + 4, 205, doc.lastAutoTable.finalY + marginY + 4);

      doc.setFont("helvetica", "normal");
      doc.setTextColor(0,0,0);
      doc.setFontSize(8);
      doc.text("Observações:", 5, doc.lastAutoTable.finalY + marginY + 10);
      doc.text("TROCAS ACEITAS APENAS COM A ETIQUETA DO PRODUTO E COM NO MAXIMO DE 30 DIAS APOS A DATA DA COMPRA.\nNÃO ACEITAMOS DEVOLUÇÕES.", 23, doc.lastAutoTable.finalY + marginY + 10);
      doc.line(65, doc.lastAutoTable.finalY + marginY + 50, 150, doc.lastAutoTable.finalY + marginY + 50);
      doc.text("Assinatura do Cliente", 95, doc.lastAutoTable.finalY + marginY + 54);
      doc.text("01/01/2021", 101, doc.lastAutoTable.finalY + marginY + 58);
    },
    payments() {
      return [
        {
          "id": 3,
          "company_id": 1,
          "sale_order_id": 11,
          "payment_method_id": 1,
          "flag_id": null,
          "code_payment_method": null,
          "payment_method": {
            "id": 1,
            "company_id": 1,
            "type": "payment-methods",
            "description": "Dinheiro",
            "value": "01",
            "global": "yes",
            "situation": "active",
            "created_by": 1,
            "updated_by": 1,
            "deleted_at": null,
            "created_at": "2022-02-12T15:56:14.000000Z",
            "updated_at": "2022-02-12T15:56:14.000000Z"
          },
          "code_flag": null,
          "flag": null,
          "cod_aut": null,
          "payment_date": "2022-02-19 00:00:00",
          "value_total": "60.00",
          "value_discount": "0.00",
          "value_final": "60.00",
          "situation": "active",
          "created_by": 1,
          "updated_by": 1,
          "deleted_at": null,
          "created_at": "2022-02-19T22:09:03.000000Z",
          "updated_at": "2022-02-19T22:09:03.000000Z"
        },
        {
          "id": 3,
          "company_id": 1,
          "sale_order_id": 11,
          "payment_method_id": 1,
          "flag_id": null,
          "code_payment_method": null,
          "payment_method": {
            "id": 1,
            "company_id": 1,
            "type": "payment-methods",
            "description": "Cartão de Crédito",
            "value": "01",
            "global": "yes",
            "situation": "active",
            "created_by": 1,
            "updated_by": 1,
            "deleted_at": null,
            "created_at": "2022-02-12T15:56:14.000000Z",
            "updated_at": "2022-02-12T15:56:14.000000Z"
          },
          "code_flag": null,
          "flag": null,
          "cod_aut": null,
          "payment_date": "2022-02-19 00:00:00",
          "value_total": "60.00",
          "value_discount": "0.00",
          "value_final": "60.00",
          "situation": "active",
          "created_by": 1,
          "updated_by": 1,
          "deleted_at": null,
          "created_at": "2022-02-19T22:09:03.000000Z",
          "updated_at": "2022-02-19T22:09:03.000000Z"
        },
        {
          "id": 3,
          "company_id": 1,
          "sale_order_id": 11,
          "payment_method_id": 1,
          "flag_id": null,
          "code_payment_method": null,
          "payment_method": {
            "id": 1,
            "company_id": 1,
            "type": "payment-methods",
            "description": "Cartão de Crédito",
            "value": "01",
            "global": "yes",
            "situation": "active",
            "created_by": 1,
            "updated_by": 1,
            "deleted_at": null,
            "created_at": "2022-02-12T15:56:14.000000Z",
            "updated_at": "2022-02-12T15:56:14.000000Z"
          },
          "code_flag": null,
          "flag": null,
          "cod_aut": null,
          "payment_date": "2022-02-19 00:00:00",
          "value_total": "60.00",
          "value_discount": "0.00",
          "value_final": "60.00",
          "situation": "active",
          "created_by": 1,
          "updated_by": 1,
          "deleted_at": null,
          "created_at": "2022-02-19T22:09:03.000000Z",
          "updated_at": "2022-02-19T22:09:03.000000Z"
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../style.scss'
</style>
