<template>
  <div class="auth">
    <div class="auth-card">
      <div class="left">
        <img src="@/assets/images/Logo-MGE.png" width="250" alt="">

        <div class="form">
          <v-form
            ref="form"
            lazy-validation
            v-model="valid"
            v-on:submit.prevent="login()"
          >

            <div class="form-control">
              <div class="text-icon">
                <v-icon color="white" class="icon-side-bar">{{ icons.icoEmail }}</v-icon>
              </div>
              <input type="text" v-model="user.email" class="input-text" placeholder="digite seu e-mail" />
            </div>

            <div class="form-control">
              <div class="text-icon">
                <v-icon color="white" class="icon-side-bar">{{ icons.icoPassword }}</v-icon>
              </div>
              <input type="password" v-model="user.password" class="input-text" 
                placeholder="digite sua senha" v-on:keyup.enter="login()" />
            </div>

            <div class="form-footer">
              <v-btn
                color="orange"
                class="mr-4"
                :disabled="!valid"
                :loading="loading"
                @click="login()"
              >
                Entrar
              </v-btn>

              <router-link :to="{name: 'register'}">Esqueci minha senha</router-link>
            </div>
          </v-form>
        </div>

        <div class="auth-footer mt-15">
          <p>Ainda não possui cadastro?</p>
          <router-link :to="{name: 'register'}">Cadastre-se agora</router-link>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
import { email as icoEmail, lockOutline } from '@/constants/icons';
import AuthService from './services/AuthService';

export default {
  name: 'Login',
  components: {
  },
  data () {
    return {
      icons: {
        icoEmail: icoEmail,
        icoPassword: lockOutline,
      },
      loading: false,
      user: {
        email: '',
        password: ''
      },
    }
  },
  computed: {
    valid: {
      get(){
        return this.user.email && this.user.password ? true : false;
      },
      set(){
        return true;
      } 
    }
  },
  methods: {
    login() {
      if(this.valid){
        this.loading = true;
        AuthService.login(this.user).then((res) => {
          this.loading = false;
          const arr_url = process.env.VUE_APP_URL.split('//')
          let urlRoot = `${arr_url[0]}//${res.data.company.sub_domain}.${arr_url[1]}`;

          if(process.env.VUE_APP_ENV === 'development')
            urlRoot = process.env.VUE_APP_URL

          window.location = `${urlRoot}`;
        }).catch(() => {
          this.loading = false;
          this.$noty.error('Erro no Login!');
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss'
</style>
