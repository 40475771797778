import HomeSchema from './schemas/HomeSchema';
import Home from './pages/Home';

export default [
  {
    path: HomeSchema.routes.list.path,
    name: HomeSchema.routes.list.name,
    component: Home,
    meta:{
      requiresAuth: true,
    }
  },
]