import BilletSchema from './schemas/BilletSchema';
import Billets from './pages/Billets';
import BilletForm from './pages/BilletForm';

export default [
  {
    path: BilletSchema.routes.list.path,
    name: BilletSchema.routes.list.name,
    component: Billets,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: BilletSchema.routes.create.path,
    name: BilletSchema.routes.create.name,
    component: BilletForm,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: BilletSchema.routes.show.path,
    name: BilletSchema.routes.show.name,
    component: BilletForm,
    meta:{
      requiresAuth: true,
    }
  },
]