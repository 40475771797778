import axios from 'axios';
import DynamicService from '../../../../service/DynamicService';
import { formatCurrency, formatDate, getText } from '@/utils';
import { type_amb, nfe_status } from '@/constants/options';

const formatResponse = (item) => {
  item.created_at_formatted = formatDate(item.created_at);
  item.dhEmi_formatted = item.dhEmi ? formatDate(item.dhEmi) : '-';
  item.tpAmb_formatted = getText(type_amb, item.tpAmb);
  item.xNome_formatted = item.recipient ? item.recipient.xNome : '-';
  item.vNF_formatted = item.vNF ? formatCurrency(item.vNF) : '-';
  item.nNF_formatted = item.nNF ? item.nNF : '-';
  item.chNFe_formatted = item.chNFe ? item.chNFe : '-';
  item.cStat_formatted = item.cStat ? item.cStat : '-';
  item.xMotivo_formatted = getText(nfe_status, item.cStat);
}

const InvoiceService = DynamicService('invoices', {
  formatResponse
});

const InvoicesCommands = () => ({
  async sendNFe(params) {
    return new Promise((resolve, reject) => {
      const payload = {
        'model': 55,
        'invoice_id': params.id,
        'na_op': 'VENDA',
        'cfop': '5102',
      }
      
      axios.post(`nfe`, payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    })
  },
  async disableNumberNFe(params) {
    return new Promise((resolve, reject) => {
      axios.post(`disable-numbers`, params).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    })
  },
  async cancelNFe(params) {
    return new Promise((resolve, reject) => {
      axios.post(`cancel-nfe`, params).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    })
  },
  async correctionLetter(params) {
    return new Promise((resolve, reject) => {
      axios.post(`correction-letter`, params).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    })
  },
  async getBarCode(params) {
    return new Promise((resolve, reject) => {
      axios.post(`get-bar-code`, params).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    })
  },
  async downloadNFe(params) {
    return new Promise((resolve, reject) => {
      axios.post(`download-xml-by-month`, params).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    })
  },
});

export default InvoiceService;
export { InvoiceService, InvoicesCommands };